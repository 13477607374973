import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import {
    BiGridAlt,
    BiSpreadsheet,
    BiBookContent,
    BiUserCircle,
    BiBookmarkAlt,
    BiServer,
    BiShieldQuarter,
    BiCaretRightCircle,
    BiCodeBlock,
} from 'react-icons/bi';
import { FiSettings } from 'react-icons/fi';

const size = '20px';

export const MenuIcon: { [key: string]: EmotionJSX.Element } = {
    commonManagement: <BiGridAlt size={size} />,
    siteManagement: <BiSpreadsheet size={size} />,
    setting: <FiSettings size={size} />,
    contents: <BiBookContent size={size} />,
    user: <BiUserCircle size={size} />,
    marketing: <BiBookmarkAlt size={size} />,
    maintenance: <BiServer size={size} />,
    policy: <BiShieldQuarter size={size} />,
    appAds: <BiCaretRightCircle size={size} />,
    development: <BiCodeBlock size={size} />,
};
