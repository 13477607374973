import { css } from '@emotion/react';
import { noResultIcon, unauthorizedImage, warningImage } from 'icons';

type Status = 'unauthorized' | 'noList' | 'warning';

interface NoResultProps {
    description: string;
    status: Status;
}
const iconImage: { [key: string]: string } = {
    unauthorized: unauthorizedImage,
    noList: noResultIcon,
    warning: warningImage,
};

export default function NoResult({ description, status }: NoResultProps) {
    return (
        <div css={wrap}>
            <img css={icon} src={iconImage[status]} />
            <span css={text}>{description}</span>
        </div>
    );
}

const text = css`
    font-size: 18px;
    padding-top: 30px;
    color: #515783;
    width: 70%;
    text-align: center;
    word-break: keep-all;
`;

const icon = css`
    width: 80px;
    height: auto;
`;

const wrap = css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 80%;
    min-height: 30vh;
    white-space: pre-line;
`;
