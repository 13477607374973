import {
    ProjectCreateRequest,
    ProjectDeleteRequest,
    ProjectDetailDto,
    ProjectUpdateRequest,
} from '@gamebase-web-ops/project';
import { MY_PROJECT_LIST } from 'constants/queryKeys';
import useMyProjectList from 'hooks/project/useMyProjectList';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ProjectRepository } from 'repository';
import { notificationPush } from 'utils/notificationUtils';

export default function useProjectList() {
    const queryClient = useQueryClient();
    const [projectDetail, setProjectDetail] = useState<ProjectDetailDto | null>(null);
    const { myProjectList, isProjectListLoading, isProjectListError } = useMyProjectList();
    const createProjectMutation = useMutation(
        async (payload: ProjectCreateRequest) => {
            await ProjectRepository.create(new ProjectCreateRequest(payload));
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(MY_PROJECT_LIST);
                notificationPush({
                    status: 'success',
                    message: '생성이 완료 되었습니다.',
                });
            },
            onError: () => {
                queryClient.invalidateQueries(MY_PROJECT_LIST);
                notificationPush({
                    status: 'error',
                    message: `생성에 실패했습니다.`,
                });
            },
        },
    );
    const updateProjectMutation = useMutation(
        async (payload: ProjectUpdateRequest) => {
            await ProjectRepository.update(new ProjectUpdateRequest(payload));
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(MY_PROJECT_LIST);
                notificationPush({
                    status: 'success',
                    message: '수정이 완료 되었습니다.',
                });
            },
            onError: () => {
                queryClient.invalidateQueries(MY_PROJECT_LIST);
                notificationPush({
                    status: 'error',
                    message: `수정에 실패했습니다.`,
                });
            },
        },
    );
    const deleteProjectMutation = useMutation(
        async (payload: ProjectDeleteRequest) => {
            await ProjectRepository.delete(new ProjectDeleteRequest(payload));
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(MY_PROJECT_LIST);
                notificationPush({
                    status: 'success',
                    message: '수정이 완료 되었습니다.',
                });
            },
            onError: () => {
                queryClient.invalidateQueries(MY_PROJECT_LIST);
                notificationPush({
                    status: 'error',
                    message: `수정에 실패했습니다.`,
                });
            },
        },
    );
    const { mutateAsync: createProject, isLoading: isCreateing } = createProjectMutation;
    const { mutateAsync: updateProject, isLoading: isUpdating } = updateProjectMutation;
    const { mutateAsync: deleteProject, isLoading: isDeleting } = deleteProjectMutation;

    return {
        myProjectList,
        isProjectListError,
        isProjectListLoading,
        projectDetail,
        setProjectDetail,
        createProject,
        updateProject,
        deleteProject,
        isCreateing,
        isUpdating,
        isDeleting,
    };
}
