import { useState } from 'react';
import { Authorization2Repository, Role2Repository } from 'repository';
import { RoleListRequest } from '@gamebase-web-ops/authorization/lib/v2';
import { RoleCreateRequest, RoleUpdateRequest, RoleDeleteRequest } from '@gamebase-web-ops/role/lib/v2';
import { useMutation, useQuery } from 'react-query';
import { roleListKey } from 'constants/queryKeys';
import { notificationPush } from 'utils/notificationUtils';
import { PAGE_SIZE, DEFAULT_PAGE } from 'utils/pagination';

export default function useMyRole2() {
    const [totalCount, setTotalCount] = useState(0);
    const [filter, setFilter] = useState<RoleListRequest>({
        page: DEFAULT_PAGE,
        limit: PAGE_SIZE,
    });

    const { data: roles } = useQuery(roleListKey({ ...filter }), async () => {
        const { result } = await Authorization2Repository.roleList(new RoleListRequest({ ...filter }));
        setTotalCount(result.totalCount);
        return result.data;
    });

    const createRoleMutation = useMutation(
        async (payload: RoleCreateRequest) => {
            const res = await Role2Repository.create(new RoleCreateRequest(payload));
            return res;
        },
        {
            onSuccess: () => {
                notificationPush({ status: 'success', message: '역할 생성이 완료 되었습니다.' });
            },
            onError: () => {
                notificationPush({ status: 'error', message: '역할 생성에 실패했습니다.' });
            },
        },
    );

    const updateRoleMutation = useMutation(
        async (payload: RoleUpdateRequest) => {
            const res = await Role2Repository.update(new RoleUpdateRequest(payload));
            return res;
        },
        {
            onSuccess: () => {
                notificationPush({ status: 'success', message: '변경 사항이 모두 적용 되었습니다.' });
            },
            onError: () => {
                notificationPush({ status: 'error', message: '역할 수정에 실패했습니다.' });
            },
        },
    );

    const deleteRoleMutation = useMutation(
        async (payload: RoleDeleteRequest) => {
            const res = await Role2Repository.delete(new RoleDeleteRequest(payload));
            return res;
        },
        {
            onSuccess: () => {
                notificationPush({ status: 'success', message: '정상적으로 삭제 되었습니다.' });
            },
            onError: () => {
                notificationPush({ status: 'error', message: '삭제에 실패했습니다.' });
            },
        },
    );

    const { mutateAsync: createMyRole } = createRoleMutation;
    const { mutateAsync: updateMyRole } = updateRoleMutation;
    const { mutateAsync: deleteMyRole } = deleteRoleMutation;
    return {
        roles,
        filter,
        createMyRole,
        updateMyRole,
        deleteMyRole,
        totalCount,
        setFilter,
    };
}
