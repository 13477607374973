import { css } from '@emotion/react';
import { failIcon } from 'icons';
import React from 'react';

interface IProp extends React.HTMLAttributes<HTMLDivElement> {
    children: any;
}

export default function Badge(props: IProp) {
    return (
        <div css={wrapper} {...props}>
            {props.children}
            <div css={iconWrapper}>
                <img css={icon} src={failIcon} />
            </div>
        </div>
    );
}

const wrapper = css`
    cursor: pointer;
    background-color: #e5b7f1;
    display: inline-block;
    border-radius: 12px;
    padding: 5px 10px;
    color: #555555;
    margin-bottom: 5px;
    position: relative;
    & + & {
        margin-left: 16px;
    }
`;

const iconWrapper = css`
    position: absolute;
    top: -15px;
    right: -12px;

    border-radius: 100%;
    z-index: 1;
    padding: 0 5px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const icon = css`
    width: 100%;
    height: auto;
`;
