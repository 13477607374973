import { useEffect, useRef } from 'react';

export default function useDraggableWrapper() {
    const dragStart = useRef<boolean>(false);
    const bodyRef = useRef<HTMLDivElement>(null);
    const bodyHeight = useRef<number>(340);
    useEffect(() => {
        const mouseMoveEventListener = (event: any) => {
            if (!dragStart.current) return;
            if (!bodyRef.current) return;

            if (bodyHeight.current - event.movementY * 3 < window.innerHeight)
                bodyHeight.current -= event.movementY * 3;
            bodyRef.current.style.height = bodyHeight.current + 'px';
            if (event.movementY < 0) window.scroll(window.scrollX, window.scrollY - event.movementY);
        };

        const mouseUpEventListener = () => {
            dragStart.current = false;
        };

        window.addEventListener('mousemove', mouseMoveEventListener);
        window.addEventListener('mouseup', mouseUpEventListener);

        return () => {
            window.removeEventListener('mousemove', mouseMoveEventListener);
            window.removeEventListener('mouseup', mouseUpEventListener);
        };
    }, []);

    return {
        bodyRef,
        dragStart,
        bodyHeight,
    };
}
