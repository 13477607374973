import { useEffect, useState } from 'react';

function useWebviewRender(url?: string, isNotRequest?: boolean) {
    const [contents, setContents] = useState<string>();
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (isNotRequest) return;
        if (url) {
            fetch(url)
                .then((res) => {
                    if (res.status === 200) {
                        return res.text();
                    } else {
                        throw new Error('Request Fail');
                    }
                })
                .then((value) => {
                    setContents(value);
                })
                .catch(() => {
                    setIsError(true);
                });
        }
    }, [url, isNotRequest]);

    return { contents, isError };
}

export default useWebviewRender;
