import { Button, Modal, Spin, Table, TableProps } from 'antd';
import { ModalWrapper } from 'components';
import React, { useState, useEffect } from 'react';
import { ProjectDetailDto } from '@gamebase-web-ops/project';
import { css } from '@emotion/react';
import { useGoogleLogin } from '@react-oauth/google';
import useSheet from 'hooks/sheet/use-sheet';
import { OPS_ERROR } from '@gamebase-web/common';
import useGameSheet from '../../hooks/game/use-game-sheet';
import { GameSheetDto } from '@gamebase-web-ops/game';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';

interface IProp extends ModalWrapperProps {
    onSubmit: (args: string) => void | Promise<void>;
    gameId: number;
    projectDetail?: ProjectDetailDto;
}

const GameSheetModal: React.FC<IProp> = ({ visible, onCancel, gameId, projectDetail, onSubmit }) => {
    const [isModalLoading, setIsModalLoading] = useState(false);
    const { data, refetch: gameSheetRefetch, deleteGameSheet, isDeleteLoading, setGameId, isLoading } = useGameSheet();

    useEffect(() => setGameId(gameId), [setGameId, gameId]);

    const { copyTemplate } = useSheet();

    const copy = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            setIsModalLoading(true);
            try {
                const response = await copyTemplate({
                    projectId: String(projectDetail?.id ?? 'COMMON'),
                    gameId,
                    authCode: codeResponse.access_token,
                });
                if (response.code !== OPS_ERROR.SUCCESS) return;

                await gameSheetRefetch();

                setIsModalLoading(false);
            } catch (e) {
                setIsModalLoading(false);
                throw e;
            }
        },
        flow: 'implicit',
    });

    const columns: ColumnType<GameSheetDto>[] = [
        {
            title: '시트 ID',
            dataIndex: 'sheetId',
        },
        {
            title: '생성자',
            dataIndex: 'createdAt',
            render(_, dto) {
                return (
                    <span>
                        {dto.createdBy}
                        <br />
                        {moment(dto.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                    </span>
                );
            },
        },
        {
            title: '',
            dataIndex: 'id',
            render(_, dto) {
                return (
                    <>
                        <Button
                            onClick={() =>
                                window.open(`https://docs.google.com/spreadsheets/d/${dto.sheetId}`, '_blank')
                            }
                        >
                            바로가기
                        </Button>
                        <Button
                            danger
                            type="primary"
                            onClick={async () => {
                                Modal.confirm({
                                    title: '확인',
                                    content: (
                                        <div>
                                            파일은 삭제되지 않고 목록에서만 삭제합니다.
                                            <br />
                                            삭제하시겠습니까?
                                        </div>
                                    ),
                                    onOk: async () => {
                                        await deleteGameSheet({ gameId, id: dto.id });
                                    },
                                });
                            }}
                        >
                            삭제
                        </Button>
                    </>
                );
            },
        },
    ];

    const [selected, setSelected] = useState<GameSheetDto>();

    const rowSelection: TableProps<GameSheetDto>['rowSelection'] = {
        onSelect: (record) => {
            setSelected(record);
        },
        type: 'radio',
    };

    return (
        <ModalWrapper title="시트 생성" onCancel={onCancel} visible={visible} width={'1000px'}>
            <Spin spinning={isModalLoading || isDeleteLoading || isLoading} size="large" tip="잠시 기다려주세요...">
                <Button onClick={() => copy()}>시트 생성</Button>
                <Table
                    dataSource={data}
                    columns={columns}
                    rowSelection={rowSelection}
                    rowKey="sheetId"
                    pagination={false}
                />
                <div css={footer}>
                    <Button onClick={onCancel}>취소</Button>
                    <Button
                        type="primary"
                        onClick={async () => {
                            if (!selected?.sheetId) {
                                Modal.info({
                                    title: '확인',
                                    content: '시트를 선택해주세요.',
                                });

                                return;
                            }

                            await onSubmit(selected.sheetId);
                        }}
                    >
                        변경
                    </Button>
                </div>
            </Spin>
        </ModalWrapper>
    );
};

export default GameSheetModal;

const footer = css`
    margin-top: 16px;
    text-align: right;
    > button {
        margin-left: 10px;
    }
`;
