import { useProjectCategory, useQueries } from 'hooks';
import SideBar from './SideBar';
import styles from './Layout.module.scss';
import classNames from 'classnames/bind';
import { useLocation, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
const cx = classNames.bind(styles);

const ProjectCategoryLayout: React.FC = ({ children }) => {
    const { projectId } = useQueries();
    const { categories, projectMenuList } = useProjectCategory(projectId);
    const { pathname } = useLocation();
    const history = useHistory();

    const linkConfirm = categories
        .map(({ subMenu }) => subMenu)
        .reduce((prev, cur) => {
            prev.push(...cur);
            return prev;
        }, [])
        .filter(({ link }) => pathname.search(link.replace(/(\?)(?<=\?).*/g, '')) !== -1);

    useEffect(() => {
        if (pathname !== '/project/dashboard' && projectMenuList.length && linkConfirm.length === 0) {
            history.push(`/project/dashboard?projectId=${projectId}`);
        }
    }, [linkConfirm, history, projectMenuList, pathname, projectId]);

    return (
        <section className={cx('mainWrapper')}>
            <SideBar sideBarMenu={categories} />
            <section className={cx('contentMain')}>{children}</section>
        </section>
    );
};

export default ProjectCategoryLayout;
