export const findMailTextCanInput = new RegExp(/\{[0-9]+\}/);
const numberRegExp = new RegExp(/[0-9]/);

export const extractionNumber = (strList: RegExpMatchArray | null) => {
    if (strList === null) return '';
    const str = strList[0];
    let flag = false;
    let tmp = '';
    for (let i = 0; i < str.length; i++) {
        if (str[i] === '{') {
            flag = true;
            tmp = '';
        }
        if (flag && numberRegExp.test(str[i])) {
            tmp += str[i];
        }

        if (str[i] === '}' && flag) {
            return tmp;
        }
    }

    return '';
};

export const ipRegExp = new RegExp(
    /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
);
