export const ScreenLock = {
    Lock: '@screen/lock',
    Unlock: '@screen/unlock',
    Message: '@screen/message',
};

export const screenLockAction = () => ({
    type: ScreenLock.Lock,
});

export const screenUnLockAction = () => ({
    type: ScreenLock.Unlock,
});

export const setMessageAction = (message: string) => ({
    type: ScreenLock.Message,
    payload: message,
});
