import { ListByDeviceIdRequest } from '@gamebase-ops/interfaces/gameUser';

import { useQuery } from 'react-query';

import { gameUserRepository } from 'repository/User.repository';

export default function useGetGuidUserList(param: ListByDeviceIdRequest) {
    return useQuery([param.guid + 'guidUserList'], () =>
        param.guid ? gameUserRepository.listByDeviceId({ query: param }) : undefined,
    );
}
