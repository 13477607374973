import { LogRequestDto } from 'dto/log.dto';
import { useCallback, useEffect, useState } from 'react';
import { LogRepository } from 'repository';

export default function useLog() {
    const [logResponse, setLogResponse] = useState<LogListResponse>();

    const [params, setParams] = useState<LogRequestDto>();

    const requestLogs = useCallback(async (param: LogRequestDto) => {
        const result = await LogRepository.find(param);

        setLogResponse(result);
    }, []);

    useEffect(() => {
        if (params) {
            requestLogs(params);
        }
    }, [params, requestLogs]);

    return { logResponse, setParams, params };
}
