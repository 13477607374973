import { Modal } from 'antd';

const ModalWrapper: React.FC<ModalWrapperProps> = ({
    title,
    visible,
    children,
    onCancel,
    onClick,
    width,
    closeWhenOutsideClicked = true,
    closeWhenEscPressed = true,
}) => {
    return (
        <Modal
            title={title}
            open={visible}
            onCancel={onCancel}
            footer={false}
            zIndex={998}
            width={width}
            maskClosable={closeWhenOutsideClicked}
            keyboard={closeWhenEscPressed}
        >
            <div onClick={onClick}>{children}</div>
        </Modal>
    );
};

export default ModalWrapper;
