import useProjectDetail from 'hooks/project/useProjectDetail';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLang, setProjectLangAction } from 'store/modules/lang/action';

function useLang(projectId?: string) {
    const dispatch = useDispatch();

    const { lang, projectLang } = useSelector<RootStore, LangStore>(({ lang }) => lang);
    const { projectDetail } = useProjectDetail(projectId);

    const langChange = useCallback(
        (payload: string) => {
            const root = document.querySelector('#root') as HTMLBodyElement;
            const classList = [...root.classList.values()];
            classList.map((className) => root.classList.remove(className));
            root.classList.add(payload.toUpperCase());

            const html = document.querySelector('html') as HTMLElement;
            html.lang = payload;

            dispatch(setLang(payload));
        },
        [dispatch],
    );

    const setProjectLang = useCallback(
        (payload: string) => {
            dispatch(setProjectLangAction(payload));
        },
        [dispatch],
    );

    useEffect(() => {
        if (projectDetail) {
            const defaultKoreanLanguage = projectDetail.languages.find((it) => it === 'ko' || it === 'kr');
            setProjectLang(defaultKoreanLanguage || '');
        }
    }, [projectDetail, setProjectLang]);

    return { lang, langChange, projectLang, setProjectLang, projectLangList: projectDetail?.languages };
}

export default useLang;
