import { Input, Form, Button } from 'antd';
import { useState, useCallback, useRef, useEffect } from 'react';
import { useAccounts } from 'hooks';
import { EnterIgnoreForm, ModalWrapper } from 'components';
import classNames from 'classnames/bind';
import AccountDropDown from './AccountDropDown';

import styles from './RoleEditModal.module.scss';
import UserBadge from './UserBadge';
import { AccountBasicDto } from '@gamebase-web-ops/account';
import useAllProjectList from 'hooks/project/model/useAllProjectList';
import RoleTreeTransfer from './RoleTreeTransfer';
import useRoleDetail2 from 'hooks/role/useRoleDetail2';

const cx = classNames.bind(styles);

interface IProp extends ModalWrapperProps {
    roleId?: string;
    onSubmit?: (arg: CreateUpdateRoleDto) => void;
    onDelete?: (roleId: string) => void;
}

const { Search } = Input;

const RoleEditModal: React.FC<IProp> = ({ onSubmit, roleId, onCancel, visible, title, onDelete }) => {
    const [dropDownVisible, setDropDownVisible] = useState(false);
    const {
        accounts: searchResultAccounts,
        requestAccountsByKeyword,
        setSearchAccounts,
        searchAccounts: roleAccounts,
        addAccounts,
        removeAccounts,
    } = useAccounts();
    const searchRef = useRef<any>();

    const {
        permissions: rolePermissions,
        setPermissions: setRolePermissions,
        roleNameRef,
        descriptionRef,
        role,
    } = useRoleDetail2(roleId);

    useEffect(() => {
        if (role) {
            setRolePermissions(role.permissions);
            setSearchAccounts(role.accounts);
        }
    }, [role, setRolePermissions, setSearchAccounts]);

    const { data: projectAllResponse } = useAllProjectList();

    const onSearch = useCallback(
        (keyword: string) => {
            setDropDownVisible(true);
            requestAccountsByKeyword(keyword);
        },
        [requestAccountsByKeyword],
    );

    const onAccountItemClick = useCallback(
        (param: AccountBasicDto) => {
            setDropDownVisible(false);
            addAccounts(param);
        },
        [addAccounts],
    );

    const removeClickListener = useCallback(async () => {
        if (
            roleId &&
            window.confirm('정말로 삭제 하시겠습니까? \r\n삭제하시면 역할을 가지고 있는 모든 유저로부터 삭제 됩니다.')
        ) {
            onDelete && onDelete(roleId);
        }
    }, [onDelete, roleId]);

    const submitClickListener = useCallback(() => {
        if (onSubmit && window.confirm('정말로 제출 하시겠습니까?')) {
            if (roleNameRef.current && descriptionRef.current) {
                if (roleAccounts && rolePermissions) {
                    onSubmit({
                        id: roleId,
                        name: roleNameRef.current.input.value,
                        description: descriptionRef.current.input.value,
                        accounts: roleAccounts.map(({ id }) => id),
                        permissions: rolePermissions,
                    });
                }
            }
        }
    }, [onSubmit, roleNameRef, descriptionRef, roleAccounts, rolePermissions, roleId]);
    if (roleId && !role) return null;
    return (
        <ModalWrapper
            visible={visible}
            title={title}
            onCancel={onCancel}
            onClick={() => setDropDownVisible(false)}
            width="800px"
        >
            <EnterIgnoreForm labelCol={{ span: 2 }}>
                <Form.Item label="역할명">
                    <Input ref={roleNameRef as never} placeholder="역할명" defaultValue={role?.name} />
                </Form.Item>
                <Form.Item label="역할 설명">
                    <Input ref={descriptionRef as never} placeholder="설명" defaultValue={role?.description} />
                </Form.Item>
                <Form.Item label="계정">
                    <Search
                        placeholder="아이디나 별명을 입력해주세요"
                        onSearch={onSearch}
                        onFocusCapture={() => setDropDownVisible(false)}
                        ref={searchRef as never}
                    />
                    {dropDownVisible && (
                        <AccountDropDown accounts={searchResultAccounts} onClick={onAccountItemClick} />
                    )}
                    <div className={cx('userBadgeWrapper')}>
                        {roleAccounts?.map((account) => (
                            <UserBadge key={account.id} account={account} onClick={removeAccounts} />
                        ))}
                    </div>
                </Form.Item>

                <div className={cx('section', 'permission')}>
                    <RoleTreeTransfer
                        rolePermissions={rolePermissions}
                        setRolePermissions={setRolePermissions}
                        projectList={projectAllResponse?.result}
                    />
                </div>
                <footer className={cx('footer')}>
                    <Button type="primary" onClick={submitClickListener}>
                        저장
                    </Button>

                    {roleId && (
                        <Button type="primary" danger onClick={removeClickListener}>
                            삭제
                        </Button>
                    )}
                </footer>
            </EnterIgnoreForm>
        </ModalWrapper>
    );
};

export default RoleEditModal;
