import {
    AttendanceScheduleListRequest,
    AttendanceScheduleListResponse,
    AttendanceRewardListRequest,
    AttendanceRewardListResponse,
} from '@gamebase-ops/interfaces/gameAttendance';
import { IGameAttendanceRepository } from '@gamebase-ops/interfaces/gameAttendance/interface';
import { Query } from '@gamebase-ops/interfaces/utils';
import { get } from 'utils/api/client';

const baseUrl = '/api/v1/gameattendances';

class ProjectAttendanceRepository implements IGameAttendanceRepository {
    schedules(request: Query<AttendanceScheduleListRequest>): Promise<AttendanceScheduleListResponse> {
        return get(baseUrl + '/schedules', new AttendanceScheduleListRequest(request.query));
    }
    rewards(request: Query<AttendanceRewardListRequest>): Promise<AttendanceRewardListResponse> {
        return get(baseUrl + '/rewards', new AttendanceRewardListRequest(request.query));
    }
}

export default new ProjectAttendanceRepository();
