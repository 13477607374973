import { useState } from 'react';
import { useQuery } from 'react-query';
import { SeasonScheduleRepository } from 'repository';

export default function useSeasonScheduleModel(projectId: string, defaultgroupId: number) {
    const [rewardGroupId, setRewardGroupId] = useState<number>(defaultgroupId);

    const scheduleData = useQuery(['seasonSchedule', projectId], () =>
        SeasonScheduleRepository.schedules({ query: { projectId } }),
    );

    const rewardData = useQuery(['seasonReward', rewardGroupId], () =>
        rewardGroupId !== undefined
            ? SeasonScheduleRepository.rewards({ query: { rewardGroupId: rewardGroupId, projectId } })
            : null,
    );

    return {
        seasonSchedule: scheduleData.data?.result,
        rewardData: rewardData.data?.result,
        setRewardGroupId,
        rewardGroupId,
    };
}
