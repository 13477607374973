import { css } from '@emotion/react';
import { DatePicker, Form, Button } from 'antd';
import { useFormatMessage } from 'hooks';
import { useState } from 'react';
import { Common } from 'styles/common';

interface IFilter {
    handleSearch: (args: INoticeFilter) => void;
    openStep: () => void;
    type: string;
    projectId: string;
    hasWritePermission: boolean;
}

const GUIDE_LINK = {
    ingame: 'https://www.notion.so/d27dad013d444d819f716e29af99335c',
    emergency: 'https://www.notion.so/0814fe51e6b544efa0d22f7db2218f22',
};

function NoticeFilter({ handleSearch, openStep, type, projectId, hasWritePermission }: IFilter) {
    const { messageFormatting } = useFormatMessage();
    const [filterData, setFilterData] = useState<INoticeFilter>({
        startTime: undefined,
        endTime: undefined,
    });
    return (
        <div>
            {hasWritePermission && (
                <div css={filterBtnWrapper}>
                    <div css={filterBtn}>
                        <Button type="primary" onClick={() => openStep()}>
                            {messageFormatting('CREATE')}
                        </Button>
                        {(type === 'ingame' || type === 'emergency') && (
                            <Button
                                style={{ backgroundColor: '#7d8cfa', border: '#7d8cfa', marginLeft: '10px' }}
                                type="primary"
                            >
                                <a href={GUIDE_LINK[type]} target="_blank" rel="noreferrer">
                                    가이드
                                </a>
                            </Button>
                        )}
                    </div>
                </div>
            )}
            <section css={filterWrapper}>
                <Form css={searchWrapper}>
                    <div>
                        <DatePicker
                            placeholder={messageFormatting('START_AT')}
                            onChange={(e) => {
                                const date = e?.toDate();
                                date?.setHours(0);
                                date?.setMinutes(0);
                                date?.setSeconds(0);
                                date?.setMilliseconds(0);
                                setFilterData({ ...filterData, startTime: date });
                            }}
                            name="startDate"
                        />
                        <span
                            css={css`
                                margin: 0 7px;
                            `}
                        >
                            ~
                        </span>
                        <DatePicker
                            placeholder={messageFormatting('END_AT')}
                            onChange={(e) => {
                                const date = e?.toDate();
                                date?.setHours(23);
                                date?.setMinutes(59);
                                date?.setSeconds(59);
                                date?.setMilliseconds(999);
                                setFilterData({ ...filterData, endTime: date });
                            }}
                            name="endDate"
                        />
                    </div>
                    <div css={searchBar}>
                        <Button type="primary" onClick={() => handleSearch(filterData)}>
                            {messageFormatting('SEARCH')}
                        </Button>
                    </div>
                </Form>
            </section>
        </div>
    );
}

export default NoticeFilter;

const filterWrapper = css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    ${Common.searchWrapper};
`;

const searchWrapper = css`
    display: flex;
    margin-right: 32px;
    margin-bottom: 10px;
`;

const searchBar = css`
    display: flex;
    margin-left: 10px;
    input {
        margin-right: 5px;
    }
`;

const filterBtnWrapper = css`
    position: relative;
`;

const filterBtn = css`
    position: absolute;
    right: 0;
    top: -70px;
`;
