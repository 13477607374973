import { css } from '@emotion/react';
import { Button, Modal } from 'antd';
import { useModal } from 'hooks';
import { useCallback, useRef, useState } from 'react';

interface IProp extends React.InputHTMLAttributes<any> {
    name: string;
    defaultImageUrl?: string;
    onFileChange?: (file: File) => void;
}

export default function ImageInput({ defaultImageUrl, onFileChange, ...props }: IProp) {
    const [url, setUrl] = useState(defaultImageUrl);
    const [tmpUrl, setTmpUrl] = useState<string>();

    const [file, setFile] = useState<File>();
    const [tmpFile, setTmpFile] = useState<File>();
    const { visible, openModal, closeModal } = useModal();
    const ref = useRef<HTMLInputElement>(null);

    const uploadButtonClickListener = useCallback(() => {
        openModal();
        setTmpUrl(url);
    }, [openModal, url]);

    const inputChangeEvent = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setTmpFile(e.target.files[0]);
            setTmpUrl(() => {
                if (e.target.files) {
                    const url = URL.createObjectURL(e.target.files[0]);
                    return url;
                }
            });
        }
    }, []);

    const modalOkListener = useCallback(() => {
        if (tmpFile) onFileChange && onFileChange(tmpFile);
        setUrl(tmpUrl);
        closeModal();
        setFile(tmpFile);
    }, [closeModal, tmpFile, onFileChange, tmpUrl]);

    const modalCancelListener = useCallback(() => {
        closeModal();
        setTmpUrl(undefined);
        setTmpFile(undefined);
        if (ref.current) ref.current.value = '';
    }, [closeModal]);

    return (
        <section>
            <button type="button" css={uploadButton} onClick={uploadButtonClickListener}>
                업로드
            </button>
            <span>{file?.name || url}</span>
            <input {...props} ref={ref} type="file" style={{ display: 'none' }} onChange={inputChangeEvent} />
            {visible && (
                <Modal
                    title="이미지 업로드"
                    onCancel={modalCancelListener}
                    open={visible}
                    width="440px"
                    onOk={modalOkListener}
                >
                    <Button
                        onClick={() => {
                            if (ref.current) {
                                ref.current.click();
                            }
                        }}
                        type="primary"
                    >
                        업로드
                    </Button>
                    <section>{tmpUrl && <img src={tmpUrl} css={imgStyle} />}</section>
                </Modal>
            )}
        </section>
    );
}

const uploadButton = css`
    margin-right: 8px;
    width: 170px;
    border: 1px solid #efefef;
    border-radius: 8px;
    padding: 4px 0;
    cursor: pointer;
`;

const imgStyle = css`
    max-width: 100%;
`;
