import useGetProjectByProjectId from './model/useGetProjectByProjectId';

function useProjectDetail(projectId?: string) {
    const { data, refetch, isError } = useGetProjectByProjectId(projectId);
    const projectManagers = data?.result?.managers.map(({ name }) => name);

    return { projectDetail: data?.result, refetch, isError, projectManagers };
}

export default useProjectDetail;
