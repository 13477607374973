export const Common = {
    colors: {
        android: '#3ddc84',
        ios: '#999999',
    },
    fontSize: {
        android: '28px',
        ios: '28px',
    },
    searchWrapper: {
        background: 'white',
        borderRadius: '4px',
        marginTop: '1rem',
        boxShadow: '0px 0px 15px #ebebeb',
        padding: '1.2rem 1.5rem calc(1.2rem - 10px)',
    },
    searchItem: {
        minWidth: '280px',
        marginRight: '15px',
    },
    searchBtn: {
        backgroundColor: '#322fcc',
        color: '#ffffff',
        padding: '4px 15px',
        textShadow: '0 -1px 0 rgb(0 0 0 / 12%)',
        boxShadow: '0 2px 0 rgb(0 0 0 / 5%)',
        lineHeight: '1.5715',
        display: 'inline-block',
        fontWeight: '400',
        height: '32px',
        borderRadius: '2px',
        cursor: 'pointer',
        transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
    },
    searchBtnHover: {
        backgroundColor: '#777bff',
    },
};

export const EnvColor: { [key: string]: string } = {
    dev: '#000000',
    qa: '#007ef5',
    prod: '#e23a07',
    common: '#7d8cfa',
};

export const EnvHeaderColor: { [key: string]: string } = {
    dev: '#dcdcdc',
    qa: '#bedfff',
    prod: '#ffcdc4',
    common: '#ffffff',
};

export const Status = {
    success: '#303431',
    request: '#dfdf86',
    fail: '#ff6a6c',
    pending: '#000000',
};

export const ReviewContentStyle = {
    backgroundColor: '#f1f6ff',
    borderRadius: '10px',
    marginBottom: '10px',
    padding: '15px 20px',
    whiteSpace: 'pre-wrap',
};
