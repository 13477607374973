import * as csvParsing from 'csv-string';
import { css } from '@emotion/react';
import { useMemo } from 'react';

interface IProp {
    text: string;
}

const TableHead = ({ contents }: { contents: string[] }) => (
    <thead>
        <tr>
            {contents.map((content, index) => (
                <th css={thStyle} key={index}>
                    <div>{content}</div>
                </th>
            ))}
        </tr>
    </thead>
);

const TableRow = ({ contents }: { contents: string[] }) => (
    <tr>
        {contents.map((content, index) => (
            <td key={index} css={tdStyle}>
                <div>{content}</div>
            </td>
        ))}
    </tr>
);

export default function CSVTable({ text }: IProp) {
    const [headText, ...bodyText] = useMemo(() => csvParsing.parse(text), [text]);
    return (
        <table css={tableStyle}>
            <TableHead contents={headText} />
            <tbody>
                {bodyText.map((contents, index) => (
                    <TableRow contents={contents} key={index} />
                ))}
            </tbody>
        </table>
    );
}

const tableStyle = css`
    width: 100%;
`;

const thStyle = css`
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    position: relative;
    text-align: left;
    padding: 16px;
    &:not(:last-child):before {
        background-color: rgba(0, 0, 0, 0.06);
        content: '';
        height: 1.6em;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: background-color 0.3s;
        width: 1px;
    }
    div {
    }
`;

const tdStyle = css`
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    position: relative;
    text-align: left;
    padding: 16px;
`;
