import { useCallback, useState } from 'react';

export default function useModal() {
    const [visible, setVisible] = useState(false);

    const closeModal = useCallback(() => {
        setVisible(false);
    }, []);

    const openModal = useCallback(() => {
        setVisible(true);
    }, []);

    return { visible, openModal, closeModal };
}
