import { useState } from 'react';
import { GameRepository } from 'repository';
import { GameIdRequest, DeleteGameSheetRequest, GameSheetDto } from '@gamebase-web-ops/game';
import { useMutation, useQuery } from 'react-query';
import { notificationPush } from '../../utils/notificationUtils';

export default function useGameSheet() {
    const [gameId, setGameId] = useState<null | number>(null);
    const { data, refetch, isLoading } = useQuery(['game-sheets', gameId], async () => {
        if (gameId !== null) {
            return await GameRepository.allGameSheets(new GameIdRequest({ gameId: gameId }));
        } else {
            return null;
        }
    });

    const deleteMutation = useMutation(
        (args: DeleteGameSheetRequest) => {
            return GameRepository.deleteGameSheet(args);
        },
        {
            async onSuccess() {
                notificationPush({
                    status: 'success',
                    title: '삭제하였습니다.',
                });
                await refetch();
            },
            onError(e: any) {
                notificationPush({
                    status: 'error',
                    title: '삭제에 실패하였습니다.',
                    message: e.message ?? '삭제에 실패하였습니다.',
                });
            },
        },
    );

    const { mutateAsync: deleteGameSheet, isLoading: isDeleteLoading } = deleteMutation;

    return { data: data?.result ?? [], refetch, deleteGameSheet, isDeleteLoading, setGameId, gameId, isLoading };
}
