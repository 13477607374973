import { Header } from 'components';
import { Tabs, TabsProps } from 'antd';
import { useMemo, useState } from 'react';
import { HeaderDesc } from 'components/utils/Header';
import { useProjectDetail, useQueries } from 'hooks';
import CommunityList from 'components/community/CommunityList';

export default function Community() {
    const { projectId } = useQueries();
    const { projectDetail } = useProjectDetail(projectId);
    const projectLanguages = useMemo(() => {
        return projectDetail?.languages;
    }, [projectDetail]);

    const [language, setLanguage] = useState(projectLanguages ? projectLanguages[0] : 'ko');
    const reviewItems: TabsProps['items'] = projectLanguages?.map((o) => ({
        key: o,
        label: o,
        children: <CommunityList language={language} />,
    }));

    return (
        <>
            <Header title="커뮤니티 관리"></Header>
            <HeaderDesc desc="게임의 커뮤니티를 관리합니다." />
            <Tabs
                items={reviewItems}
                onChange={(e) => {
                    setLanguage(e);
                }}
            />
        </>
    );
}
