import { AndroidFilled, AppleFilled } from '@ant-design/icons';
import { SerializedStyles } from '@emotion/react';
import { OS } from 'constants/constants';

interface IProp {
    os: OS;
    style?: React.CSSProperties;
    css?: SerializedStyles;
}

const OsIcon: React.FC<IProp> = ({ os, style, css }) => {
    if (os === OS.iOS) return <AppleFilled css={css} style={style} />;
    if (os === OS.Android) return <AndroidFilled css={css} style={style} />;
    return null;
};

export default OsIcon;
