import { Link } from 'react-router-dom';
import { logoImg } from 'icons';
import { css } from '@emotion/react';
import MenuItem from './MenuItem';

interface IProp {
    sideBarMenu?: {
        mainMenu: string;
        subMenu: {
            key: string;
            desc: unknown;
            link: string;
        }[];
    }[];
    menuName?: string;
}

export default function SideBar({ sideBarMenu, menuName }: IProp) {
    return (
        <div css={sideBarWrap}>
            <div css={fixedSection}>
                {sideBarMenu ? (
                    sideBarMenu.map(({ mainMenu, subMenu }) => {
                        return <MenuItem mainMenu={mainMenu} subMenu={subMenu} key={mainMenu} />;
                    })
                ) : (
                    <>{menuName}</>
                )}
            </div>
        </div>
    );
}

const sideBarWrap = css`
    width: 240px;
    flex-shrink: 0;
`;

const fixedSection = css`
    position: fixed;
    height: calc(100vh - 80px);
    z-index: 1;
    width: 240px;
    background-color: #fefeff;
    padding: 20px;
    margin-top: 80px;
    border-right: 1px solid #f5f5f5;
    overflow-y: auto;
    ::-webkit-scrollbar {
        width: 7px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #d2d3eb;
        border-radius: 4px;
    }
    ::-webkit-scrollbar-track {
        background-color: #f0eff5;
    }
`;

const logo = css`
    flex-shrink: 0;
    width: 90%;
    height: auto;
    padding: 0 16px;
    img {
        width: 150px;
        padding: 3% 3% 2% 4%;
        filter: invert(42%) sepia(6%) saturate(1023%) hue-rotate(194deg) brightness(99%) contrast(86%);
    }
`;
