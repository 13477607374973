export type Rule = {
    [key: string]: any;
    message: string;
};
export type RuleRecord = Map<string, Rule[]>;
interface SignInForm {
    setRules: (key: string, rules: Rule[]) => void;
    addRule: (key: string, rule: Rule) => void;
    getRules: (key: string) => Rule[];
}

class FormValidator implements SignInForm {
    private readonly _rules: RuleRecord;

    constructor() {
        this._rules = new Map<string, Rule[]>();
    }

    setRules = (key: string, rules: Rule[]): void => {
        this._rules.set(key, rules);
    };

    addRule = (key: string, rule: Rule): void => {
        const newRules = [...this.getRules(key), rule];
        this._rules.set(key, newRules);
    };

    getRules = (key: string): Rule[] => {
        return this._rules.get(key) || [];
    };
}

export default FormValidator;
