import { IGameSeasonRepository } from '@gamebase-ops/interfaces/gameSeason/interface';

import { get } from 'utils/api/client';
import {
    SeasonScheduleListRequest,
    SeasonScheduleListResponse,
    SeasonPointRewardListRequest,
    SeasonPointRewardListResponse,
} from '@gamebase-ops/interfaces/gameSeason';
import { Query } from '@gamebase-ops/interfaces/utils';

const baseUrl = '/api/v1/gameseasons';

class SeasonScheduleRepository implements IGameSeasonRepository {
    schedules(request: Query<SeasonScheduleListRequest>): Promise<SeasonScheduleListResponse> {
        return get(baseUrl + '/schedules', new SeasonScheduleListRequest(request.query));
    }
    rewards(request: Query<SeasonPointRewardListRequest>): Promise<SeasonPointRewardListResponse> {
        return get(baseUrl + '/rewards', new SeasonPointRewardListRequest(request.query));
    }
}

export default new SeasonScheduleRepository();
