import { CommunityCreateRequest, CommunityDeleteRequest, CommunityListRequest } from '@gamebase-web-ops/community';
import { communityListKey, videoListKey } from 'constants/queryKeys';
import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { CommunityRepository } from 'repository';
import { resizeImage } from 'utils/image';
import { notificationPush } from 'utils/notificationUtils';
import { v4 as uuid } from 'uuid';
import imageCompression from 'browser-image-compression';

interface ReportInfoProps {
    projectId: string;
    language: string;
}

const compressionOptions = {
    maxSizeMB: Number.POSITIVE_INFINITY,
    useWebWorker: true,
    initialQuality: 0.5,
} as const;

export default function useCommunity({ projectId, language }: ReportInfoProps) {
    const queryClient = useQueryClient();

    const { data, isError, isLoading, refetch } = useQuery(
        communityListKey({ projectId, language }),
        ({ queryKey }) => {
            const [_, payload] = queryKey;
            return CommunityRepository.list(new CommunityListRequest(payload));
        },
        { enabled: !!language },
    );

    const createMutation = useMutation(
        async (payload: CommunityCreateRequest) => {
            await CommunityRepository.create(new CommunityCreateRequest(payload));
        },
        {
            onSuccess: async () => {
                queryClient.invalidateQueries({ queryKey: communityListKey({ projectId, language }) });
                await notificationPush({ status: 'success', message: '커뮤니티 데이터가 추가되었습니다.' });
            },
        },
    );

    const uploadImage = useCallback(
        async (file: File) => {
            const formData = new FormData();
            const image = await resizeImage(file);
            const compressImage = await imageCompression(new File([image], uuid(), { type: file.type }), {
                ...compressionOptions,
                fileType: file.type,
            });
            formData.append('file', new File([compressImage], uuid(), { type: file.type }));
            const { result } = await CommunityRepository.uploadImage({ projectId, formData });
            return result;
        },
        [projectId],
    );

    // const updateMutation = useMutation(
    //     async (payload: CommunityUpdateRequest) => {
    //         await CommunityRepository.update(new CommunityUpdateRequest(payload));
    //     },
    //     {
    //         onSuccess: async () => {
    //             queryClient.invalidateQueries({ queryKey: videoListKey({ projectId, language }) });
    //             await notificationPush({ status: 'success', message: '커뮤니티 데이터가 수정되었습니다.' });
    //         },
    //     },
    // );

    const deleteMutation = useMutation(
        async (payload: CommunityDeleteRequest) => {
            await CommunityRepository.delete(new CommunityDeleteRequest(payload));
        },
        {
            onSuccess: async () => {
                queryClient.invalidateQueries({ queryKey: communityListKey({ projectId, language }) });
                await notificationPush({ status: 'success', message: '커뮤니티 데이터가 삭제되었습니다.' });
            },
        },
    );

    const { mutate: createMutate, isSuccess: createdSuccess, isLoading: createdLoading } = createMutation;
    // const { mutate: updateMutate, isSuccess: updatedSuccess, isLoading: updatedLoading } = updateMutation;
    const { mutate: deleteMutate } = deleteMutation;

    return {
        communityList: data?.result,
        isLoading,
        isError,
        refetch,
        createMutate,
        createdSuccess,
        createdLoading,
        uploadImage,
        // updateMutate,
        // updatedSuccess,
        // updatedLoading,
        deleteMutate,
    };
}
