import { ItemDto } from '@gamebase-web-ops/item';
import { Table } from 'antd';
import { useFormatMessage } from 'hooks';
import { useMemo } from 'react';
import { getItemName } from 'utils/item';

interface ItemTableProps {
    itemList: ItemDto[];
    page: number;
    pageSize: number;
    total: number;
    requestByPage: (targetPage: number) => Promise<void>;
    handleItemClick?: (item: ItemDto) => void;
}
export default function ItemTable({ itemList, page, pageSize, total, requestByPage, handleItemClick }: ItemTableProps) {
    const { messageFormatting } = useFormatMessage();
    const columns = useMemo(() => {
        return [
            {
                title: messageFormatting('ITEM_ID'),
                dataIndex: 'itemId',
            },
            {
                title: messageFormatting('ITEM_NAME'),
                dataIndex: 'itemName',
                render(_: any, item: ItemDto) {
                    return getItemName(item);
                },
            },
        ];
    }, [messageFormatting]);

    return (
        <Table
            dataSource={itemList}
            columns={columns}
            pagination={{
                position: ['bottomCenter'],
                onChange: requestByPage,
                pageSize,
                total,
                current: page,
            }}
            tableLayout="fixed"
            onRow={(item) => {
                return {
                    onClick: () => {
                        handleItemClick?.(item);
                    },
                };
            }}
        />
    );
}
