import { Input } from 'antd';
import { css } from '@emotion/react';
import { SearchOutlined } from '@ant-design/icons';

interface GameSearchProps {
    onChangeSearch: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

export default function GameSearch({ onChangeSearch }: GameSearchProps) {
    return (
        <div css={gameSearchWrap}>
            <Input
                onChange={onChangeSearch}
                style={{ width: '50%' }}
                addonBefore={<SearchOutlined />}
                placeholder="게임명 검색"
            />
        </div>
    );
}

const gameSearchWrap = css`
    margin-bottom: 20px;
    display: flex;
    justify-content: start;
    .ant-input-group-addon {
        border-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #ececec;
        border-right: 0;
    }
    .ant-input {
        border-radius: 5px;
        border: 1px solid #ececec;
    }
`;
