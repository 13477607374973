export const Lang = {
    set: '@lang/set',
};

export const ProjectLang = {
    set: '@projectLang/set',
};

export const setLang = (payload: string) => {
    return {
        type: Lang.set,
        payload,
    };
};

export const setProjectLangAction = (payload: string) => {
    return {
        type: ProjectLang.set,
        payload,
    };
};
