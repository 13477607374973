import { findMailTextCanInput } from 'utils/regExp';
import { useCallback, useMemo } from 'react';
import useMailTemplateModel from './model/useMailTemplate.model';
import { MailTemplateDto } from '@gamebase-web-ops/mail-template';

export default function useMailTemplate(projectId?: string) {
    const { setProjectId, data } = useMailTemplateModel(projectId);

    const mailTemplateHashByTableId = useMemo<Record<string, MailTemplateDto>>(() => {
        if (!data) return {};
        return data.result.reduce<Record<string, MailTemplateDto>>((acc, data) => {
            acc[data.templateId] = data;
            return acc;
        }, {});
    }, [data]);

    const findGlobalTextCanInput = useCallback((obj: IGlobalText) => {
        const keys = Object.keys(obj) as SmallCountires[];
        return keys.reduce((acc, key) => {
            if (!obj[key]) return acc;
            if (findMailTextCanInput.test(obj[key] || '')) {
                acc[key] = obj[key];
            }
            return acc;
        }, {} as IGlobalText);
    }, []);

    const getNeedInputTextList = useCallback(
        (mailTemplate: IMailTemplate | null) => {
            if (!mailTemplate) return {};

            return {
                textTitle: findGlobalTextCanInput(mailTemplate.mailtitlestringid),
                textSender: findGlobalTextCanInput(mailTemplate.mailsenderstringid),
                textContent: findGlobalTextCanInput(mailTemplate.mailtextstringid),
            };
        },
        [findGlobalTextCanInput],
    );

    const getTitle = useCallback((lang: string, mailTemplate?: MailTemplateDto) => {
        return mailTemplate?.titles?.[lang];
    }, []);

    const getText = useCallback((lang: string, mailTemplate?: MailTemplateDto) => {
        return mailTemplate?.texts?.[lang];
    }, []);

    const getSender = useCallback((lang: string, mailTemplate?: MailTemplateDto) => {
        return mailTemplate?.texts?.[lang];
    }, []);

    return {
        getMailTemplateList: setProjectId,
        mailTemplateList: data?.result || [],
        getNeedInputTextList,
        mailTemplateHashByTableId,
        getTitle,
        getText,
        getSender,
    };
}
