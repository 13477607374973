import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { onSubmitCapture } from 'utils/form';
import { css } from '@emotion/react';
import { useCallback, useState } from 'react';
import { Button, Input } from 'antd';

interface IFormSubmit {
    value: string;
}

interface IProp {
    onFilter: (param: IFormSubmit) => void;
}

export default function FilterSearch({ onFilter }: IProp) {
    const [isShow, setIsShow] = useState<boolean>(false);
    const [value, setValue] = useState<string>();

    const submitListener = useCallback(
        (params: IFormSubmit) => {
            setValue(params.value);
            onFilter(params);
            setIsShow(false);
        },
        [onFilter],
    );

    const resetButtonListener = useCallback(() => {
        setValue(undefined);
        setIsShow(false);
        onFilter({ value: '' });
    }, [onFilter]);

    return (
        <section css={wrapper}>
            <i css={iconWrapper} onClick={() => setIsShow((prev) => !prev)}>
                {value ? <FilterFilled /> : <FilterOutlined />}
            </i>
            <form css={[formWrapper, isShow ? show : hide]} onSubmit={onSubmitCapture(submitListener)}>
                <Input name="value" style={{ width: '180px' }} />
                <footer css={formFooterWrapper}>
                    <Button htmlType="submit" type="primary">
                        Submit
                    </Button>
                    {value && (
                        <Button style={{ marginLeft: '8px' }} onClick={resetButtonListener}>
                            Reset
                        </Button>
                    )}
                    <Button style={{ marginLeft: '8px' }} onClick={() => setIsShow(false)}>
                        닫기
                    </Button>
                </footer>
            </form>
        </section>
    );
}

const iconWrapper = css`
    cursor: pointer;
`;

const wrapper = css`
    position: relative;
    overflow: visible;
`;

const formWrapper = css`
    padding: 8px;
    background: white;
    position: absolute;
    top: 100%;
    left: 0;
`;

const show = css`
    display: block;
    z-index: 990;
`;

const hide = css`
    display: none;
`;

const formFooterWrapper = css`
    display: flex;
    margin-top: 24px;
`;
