import LoadingSpin from './LoadingSpin';

interface IProp {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    condition: any;
}

const ConditinalWrapper: React.FC<IProp> = ({ children, condition }) => {
    return <>{condition ? children : <LoadingSpin />}</>;
};

export default ConditinalWrapper;
