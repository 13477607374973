import {
    GameUserInfoRequest,
    GameUserInfoResponse,
    ListByNicknameRequest,
    ListByNicknameResponse,
    ListByDeviceIdRequest,
    ListByDeviceIdResponse,
    ListEventLogRequest,
    DownloadEventLogRequest,
    BanDeviceRequest,
    BanAccountRequest,
    UnbanAccountRequest,
    RestoreAccountRequest,
    ChangeNicknameRequest,
    ChangeTrophyRequest,
    ChangeAccountLevelRequest,
    ChangeItemsRequest,
    ChangeCharacterRequest,
    ChangeAttendanceInfoRequest,
    ChangeClassPassRequest,
    ChangeSeasonPassRequest,
    ChangeShopInfoRequest,
    RemoveMailRequest,
    DeleteFriendRequest,
    WithdrawalInfoResponse,
} from '@gamebase-ops/interfaces/gameUser';
import {
    WithdrawalInfoRequest,
    CancelWithdrawalRequest,
    UnbanDeviceRequest,
    UnbanUserRequest,
    BanUserRequest,
    ChangeCharactersRequest,
    ChangeSubscribedItemsRequest,
} from '@gamebase-ops/interfaces/gameUser/request';
import { IEventLog, IGameUserInterface } from '@gamebase-ops/interfaces/gameUser/interface';
import { Query, Auth, Body, BaseResponse } from '@gamebase-ops/interfaces/utils';

import QueryString from 'qs';

import { patch, get } from 'utils/api/client';
import AuthenticationRepository from './Authentication.repository';
import RequestAccess from 'utils/api/ReqeustAccess';
import { API_URL } from 'constants/constants';

const baseUrl = '/api/v1/gameusers';

class GameUserRepository implements IGameUserInterface {
    cancelWithdrawal(param: Query<Auth> & Body<CancelWithdrawalRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/cancel/withdrawal', new CancelWithdrawalRequest(param.body), new Auth(param.query));
    }
    withdrawalInfo(param: Query<WithdrawalInfoRequest>): Promise<WithdrawalInfoResponse> {
        return get(baseUrl + '/info/withdrawal', new WithdrawalInfoRequest(param.query));
    }

    info(param: Query<GameUserInfoRequest>): Promise<GameUserInfoResponse> {
        return get(baseUrl + '/info', param.query);
    }
    infoJson(param: Query<GameUserInfoRequest>): Promise<any> {
        return get(baseUrl + '/infoJson', param.query);
    }
    listByNickname(param: Query<ListByNicknameRequest>): Promise<ListByNicknameResponse> {
        return get(baseUrl + '/list/users', new ListByNicknameRequest(param.query));
    }
    listByDeviceId(param: Query<ListByDeviceIdRequest>): Promise<ListByDeviceIdResponse> {
        return get(baseUrl + '/list/accounts', new ListByDeviceIdRequest(param.query));
    }
    listEventLog(param: Query<ListEventLogRequest>): Promise<IEventLog[]> {
        return get(baseUrl + '/logs', param.query);
    }
    async downloadEventLog(param: Query<DownloadEventLogRequest>): Promise<any> {
        const dto = new DownloadEventLogRequest(param.query);
        if (!dto.userId) throw new Error('userId is not empty');
        await AuthenticationRepository.check();
        const accessToken = RequestAccess.getInstance().getAccessToken();

        const queryString = QueryString.stringify({ ...dto, token: accessToken }, { addQueryPrefix: true });
        const link = `${API_URL}/api/v1/gameusers/logs/download${queryString}`;
        const a = document.createElement('a');
        a.href = link;
        a.click();
        a.remove();
    }
    banDevice(param: Query<Auth> & Body<BanDeviceRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/ban/device', new BanDeviceRequest(param.body), new Auth(param.query));
    }
    banAccount(param: Query<Auth> & Body<BanAccountRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/ban/account', new BanAccountRequest(param.body), new Auth(param.query));
    }
    unban(param: Query<Auth> & Body<UnbanAccountRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/unban/account', new UnbanAccountRequest(param.body), new Auth(param.query));
    }
    unbanDevice(param: Query<Auth> & Body<UnbanDeviceRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/unban/device', new UnbanDeviceRequest(param.body), new Auth(param.query));
    }
    banUser(param: Query<Auth> & Body<BanUserRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/ban/user', new BanUserRequest(param.body), new Auth(param.query));
    }
    unbanUser(param: Query<Auth> & Body<UnbanUserRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/unban/user', new UnbanUserRequest(param.body), new Auth(param.query));
    }
    restoreAccount(param: Query<Auth> & Body<RestoreAccountRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/restore', new RestoreAccountRequest(param.body), new Auth(param.query));
    }
    changeNickname(param: Query<Auth> & Body<ChangeNicknameRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/nickname', new ChangeNicknameRequest(param.body), new Auth(param.query));
    }
    changeTrophy(param: Query<Auth> & Body<ChangeTrophyRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/trophy', new ChangeTrophyRequest(param.body), new Auth(param.query));
    }
    changeAccountLevel(param: Query<Auth> & Body<ChangeAccountLevelRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/account-level', new ChangeAccountLevelRequest(param.body), new Auth(param.query));
    }
    changeItems(param: Query<Auth> & Body<ChangeItemsRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/items', new ChangeItemsRequest(param.body), new Auth(param.query));
    }
    changeCharacter(param: Query<Auth> & Body<ChangeCharacterRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/character', new ChangeCharacterRequest(param.body), new Auth(param.query));
    }
    changeCharacters(param: Query<Auth> & Body<ChangeCharactersRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/characters', new ChangeCharactersRequest(param.body), new Auth(param.query));
    }
    changeSubscribedItems(param: Query<Auth> & Body<ChangeSubscribedItemsRequest>): Promise<BaseResponse> {
        return patch(
            baseUrl + '/subscribed-items',
            new ChangeSubscribedItemsRequest(param.body),
            new Auth(param.query),
        );
    }
    changeAttendanceInfo(param: Query<Auth> & Body<ChangeAttendanceInfoRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/attendance-info', new ChangeAttendanceInfoRequest(param.body), new Auth(param.query));
    }
    changeClassPass(param: Query<Auth> & Body<ChangeClassPassRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/class-pass', new ChangeClassPassRequest(param.body), new Auth(param.query));
    }
    changeSeasonPass(param: Query<Auth> & Body<ChangeSeasonPassRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/season-pass', new ChangeSeasonPassRequest(param.body), new Auth(param.query));
    }
    changeShopInfo(param: Query<Auth> & Body<ChangeShopInfoRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/shop-info', new ChangeShopInfoRequest(param.body), new Auth(param.query));
    }
    removeMail(param: Query<Auth> & Body<RemoveMailRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/mail/remove', new RemoveMailRequest(param.body), new Auth(param.query));
    }
    deleteFriend(param: Query<Auth> & Body<DeleteFriendRequest>): Promise<BaseResponse> {
        return patch(baseUrl + '/friend/remove', new DeleteFriendRequest(param.body), new Auth(param.query));
    }
}

export const gameUserRepository = new GameUserRepository();
