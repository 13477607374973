import { css } from '@emotion/react';
import { Descriptions, Form, Tabs, Tag } from 'antd';
import { ENoticePlatform, ENoticePlatform_TEXT } from 'constants/constants';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import { useState } from 'react';
interface ISecondStep {
    stepData: any;
    stepType: string;
    type: string;
}
function SecondStep({ stepData, stepType, type }: ISecondStep) {
    const { description, messages, startTime, endTime, defaultLanguage, titles, platform, versions, repeatNum } =
        stepData;
    const languageKeys = Object.keys(messages);
    const [selectedLanguage, setSelectedLanguage] = useState<string>(defaultLanguage || languageKeys[0]);
    const { messageFormatting } = useFormatMessage();
    return (
        <Form>
            <div css={noticePreview}>
                <Descriptions column={1} bordered size="small" labelStyle={{ width: '200px' }}>
                    <Descriptions.Item label={messageFormatting('TITLE')}>
                        <Form.Item labelCol={{ span: 2, offset: 0 }}>{description}</Form.Item>
                    </Descriptions.Item>
                    {type === 'ingame' && (
                        <>
                            <Descriptions.Item label={messageFormatting('APP_VERSION')}>
                                <Form.Item labelCol={{ span: 2, offset: 0 }}>
                                    {versions
                                        ? versions.map((it: string, idx: number) => <Tag key={idx}>{it}</Tag>)
                                        : ''}
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label={messageFormatting('REPEAT_NUM')}>
                                <Form.Item labelCol={{ span: 2, offset: 0 }}>{repeatNum}</Form.Item>
                            </Descriptions.Item>
                        </>
                    )}
                    <Descriptions.Item label={messageFormatting('EXPOSURE_PERIOD')}>
                        <Form.Item labelCol={{ span: 2, offset: 0 }}>
                            {moment(startTime).format('YYYY-MM-DD HH:mm:ss')} ~{' '}
                            {moment(endTime).format('YYYY-MM-DD HH:mm:ss')}
                        </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item label={messageFormatting('NOTICE_FIRST_LANGUAGE')}>
                        <Form.Item labelCol={{ span: 2, offset: 0 }}>{defaultLanguage || '없음'}</Form.Item>
                    </Descriptions.Item>
                    {platform !== undefined ? (
                        <Descriptions.Item label="플랫폼">
                            <Form.Item>
                                <div>{ENoticePlatform_TEXT[platform as ENoticePlatform]}</div>
                            </Form.Item>
                        </Descriptions.Item>
                    ) : (
                        <></>
                    )}
                    <Descriptions.Item label="본문">
                        <Tabs
                            type="card"
                            defaultValue={selectedLanguage}
                            onChange={(value) => setSelectedLanguage(value)}
                            items={languageKeys.map((it) => {
                                return {
                                    label: it,
                                    key: it,
                                    children: (
                                        <>
                                            <Descriptions column={1} bordered size="small" labelStyle={{ width: 100 }}>
                                                {Object.keys(titles ?? {}).length !== 0 && (
                                                    <Descriptions.Item label="제목">
                                                        <pre css={preStyle}>
                                                            {titles[selectedLanguage] || titles[defaultLanguage] || ''}
                                                        </pre>
                                                    </Descriptions.Item>
                                                )}
                                                {Object.keys(messages ?? {}).length !== 0 && (
                                                    <Descriptions.Item label="내용">
                                                        <pre css={preStyle}>
                                                            {messages[selectedLanguage] ||
                                                                messages[defaultLanguage] ||
                                                                ''}
                                                        </pre>
                                                    </Descriptions.Item>
                                                )}
                                            </Descriptions>
                                        </>
                                    ),
                                };
                            })}
                        ></Tabs>
                    </Descriptions.Item>
                </Descriptions>
            </div>
            {stepType !== 'detail' ? <div>{messageFormatting('NOTICE_CONFIRM')}</div> : <></>}
        </Form>
    );
}

export default SecondStep;

const noticePreview = css`
    border: 1px solid #ddd;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 12px;
`;

const preStyle = css`
    width: 100%;
    padding-left: 1%;
    white-space: pre-wrap;
`;
