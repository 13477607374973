import { handleActions } from 'redux-actions';
import { ScreenLock } from './action';

const initState: ScreenLockStore = {
    isLock: false,
    message: undefined,
};

export default handleActions(
    {
        [ScreenLock.Lock]: (state) => ({
            ...state,
            isLock: true,
        }),
        [ScreenLock.Unlock]: () => ({
            isLock: false,
            message: undefined,
        }),
        [ScreenLock.Message]: (state, { payload }: { payload: string }) => ({
            ...state,
            message: payload,
        }),
    },
    initState,
);
