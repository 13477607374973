/* eslint-disable @typescript-eslint/no-explicit-any */
export function add(setter: (value: React.SetStateAction<any[]>) => void, item: any): void {
    setter((prev) => {
        const map = new Map();
        prev.map((prevItem) => map.set(prevItem.id, prevItem));
        map.set(item.id, item);
        return [...map.values()];
    });
}

export function remove(setter: (value: React.SetStateAction<any[]>) => void, item: any): void {
    setter((prev) => prev.filter((prevItem) => prevItem.id !== item.id));
}

export const arrayToCsvDownload = (param: Array<{ [key: string]: any }>, name?: string) => {
    const csvString = param.reduce<string>((acc, row, index) => {
        const keys = Object.keys(row);
        if (index === 0) {
            acc += `${keys.join(',')}\n`;
        }

        const stringArray = keys.map((key) => {
            return `"${row[key].toString().replace(/"/g, "'")}"`;
        });
        return `${acc + stringArray.join(',')}\n`;
    }, '');

    const csvBlob = new Blob(['\ufeff' + csvString], { type: 'text/csv;charset=utf8' });
    const csvFile = new File([csvBlob], name || `${new Date().toString()}.csv`, {
        type: 'text/csv;charset=utf8',
    });
    const csvURL = URL.createObjectURL(csvFile);
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none');
    a.setAttribute('target', '_blank');
    a.setAttribute('href', csvURL);
    a.setAttribute('download', name || `${new Date().toString()}.csv`);
    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(csvURL);
};
