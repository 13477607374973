export function resizeImage(file: File, width = 360): Promise<Blob> {
    return new Promise((resolve, reject) => {
        const mockUrl = URL.createObjectURL(file);
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width < width ? img.width : width;
            canvas.height = canvas.width * (img.height / img.width);
            const ctx = canvas.getContext('2d');
            ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
            canvas.toBlob((blob) => {
                URL.revokeObjectURL(mockUrl);
                if (blob) {
                    resolve(blob);
                } else {
                    reject('file convert error');
                }
            }, file.type);
        };
        img.src = mockUrl;
    });
}
