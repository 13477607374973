import useGetLanguage from 'hooks/setting/useGetLanguage';
import useLang from 'hooks/lang/useLang';
import { useEffect } from 'react';

import { IntlProvider } from 'react-intl';

/**
 * 국제화 provider
 */
export default function WithIntlProvider(Component: React.FC) {
    return function Intl() {
        const { lang, langChange } = useLang();
        const { data: localeMap } = useGetLanguage(lang);

        useEffect(() => {
            langChange(window.navigator.language.split('-')[0]);
        }, [langChange]);

        return (
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            <IntlProvider locale={window.navigator.language} messages={localeMap || {}} onError={() => {}}>
                <Component />
            </IntlProvider>
        );
    };
}
