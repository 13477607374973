/* eslint-disable @typescript-eslint/no-explicit-any */
export const assocPath = (path: string[], val: any, obj: any) => {
    if (path.length === 0) return val;
    const index = path[0];
    if (path.length > 1) {
        const nextObj = obj && obj[index] ? obj[index] : {};
        path.shift();
        val = assocPath(path, val, nextObj);
    }
    const numIndex = Number(index);

    if (!isNaN(numIndex)) {
        const arr: Array<any> = [].concat(obj);
        arr[numIndex] = val;
        return arr;
    } else {
        obj[index] = val;
    }
    return obj;
};

export const getObjectByDot = (entry: string[]) => {
    const splitKey = entry[0].split('.');
    return assocPath(splitKey, entry[1], {});
};
