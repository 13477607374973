import SideBar from './SideBar';
import classNames from 'classnames/bind';
import styles from './Layout.module.scss';
import { COMMON_MENU_LIST } from 'constants/constants';

const cx = classNames.bind(styles);

const CommonLayout: React.FC = ({ children }) => {
    return (
        <section className={cx('mainWrapper')}>
            <SideBar sideBarMenu={COMMON_MENU_LIST} />
            <section className={cx('adminMain')}>{children}</section>
        </section>
    );
};

export default CommonLayout;
