import { put, takeLatest, call, delay } from 'redux-saga/effects';
import AuthenticationRepository from 'repository/Authentication.repository';

import { MyData } from './action';
interface IGetAccount {
    type: typeof MyData.Request;
    payload: IStoreRequest;
}

function* accountProfile() {
    for (let i = 0; i < 2; i++) {
        try {
            const { result } = yield call([AuthenticationRepository, AuthenticationRepository.profile]);
            return { result };
        } catch (err) {
            if (i < 2) {
                yield delay(500);
            }
        }
    }
}

function* getAccountSaga({ payload }: IGetAccount) {
    try {
        const { result } = yield call(accountProfile);
        yield put({ type: MyData.Success, payload: { account: result } });
        if (payload.callback) payload.callback();
    } catch (e) {
        yield put({ type: MyData.Failure });
        if (payload.fallback) payload.fallback();
    }
}

export default function* accountSaga() {
    yield takeLatest(MyData.Request, getAccountSaga);
}
