import { Form, Input, Button, Select, Radio, Descriptions, Divider, Tag, Tooltip } from 'antd';
import { ModalWrapper } from 'components';
import { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './ProjectModal.module.scss';
import EnterIgnoreForm from 'components/utils/EnterIgnoreForm';
import { ProjectCreateRequest, ProjectDetailDto, ProjectUpdateRequest } from '@gamebase-web-ops/project';
import useProjectDetail from 'hooks/project/useProjectDetail';
import { useFormRules, useAccounts } from 'hooks';
import useGameManage from 'hooks/game/useGameManage';
import { EProjectEnvironment } from '@gamebase-web-ops/project';
import { notificationPush } from 'utils/notificationUtils';
import { css } from '@emotion/react';

const cx = classNames.bind(styles);

interface IProp extends ModalWrapperProps {
    onSubmit?: (arg: ProjectCreateRequest | ProjectUpdateRequest) => void;
    projectValues: ProjectDetailDto | null;
}

export default function ProjectDetail({ visible, onCancel, onSubmit, title, projectValues }: IProp) {
    const isProd = process.env.REACT_APP_ENV === 'production';
    const { setSearchAccounts, searchAccounts: projectAccounts } = useAccounts();

    const [languages, setLanguages] = useState<string[]>(projectValues?.languages ?? []);
    const { projectDetail, refetch } = useProjectDetail(projectValues?.id);
    const { allGameList } = useGameManage();
    const { formValidator } = useFormRules();
    const [hasSheetId, setHasSheetId] = useState<boolean | undefined>(
        projectDetail ? (projectDetail?.driveUrl ? true : false) : undefined,
    );

    useEffect(() => {
        if (projectDetail?.managers) {
            setSearchAccounts(projectDetail?.managers);
        }
    }, [setSearchAccounts, projectDetail]);

    useEffect(() => {
        setHasSheetId(projectDetail ? !!projectDetail?.driveUrl : undefined);
    }, [projectDetail]);

    const onFinish = useCallback(
        async (arg) => {
            if (window.confirm('정말로 제출 하시겠습니까?')) {
                if (onSubmit) {
                    if (projectAccounts.length === 0 && projectValues) {
                        return notificationPush({ status: 'error', message: '담당자를 한 명 이상 추가해주세요.' });
                    }
                    const globalId = parseInt(arg.globalId, 10);
                    const name = `${allGameList?.find((gameInfo) => gameInfo.gameId === globalId)?.gameName}(${
                        arg.environment
                    })`;
                    const submitData = {
                        ...arg,
                        languages: languages ?? undefined,
                        name,
                        globalId,
                        id: projectValues?.id,
                        managerIds: projectAccounts.map(({ id }) => id),
                    };
                    await onSubmit(submitData);
                    refetch();
                }
            }
        },
        [onSubmit, projectAccounts, projectValues, allGameList, languages, refetch],
    );
    if (projectValues && !projectDetail) return null;

    return (
        <ModalWrapper title={title} onCancel={onCancel} visible={visible} width={'750px'}>
            <section className={projectDetail && cx('wrapper')}>
                <EnterIgnoreForm
                    enabledenter
                    className={projectDetail && cx('formWrapper')}
                    onFinish={onFinish}
                    labelCol={{ span: 3 }}
                    initialValues={{
                        ...projectDetail,
                    }}
                >
                    <Descriptions column={1} bordered>
                        <Descriptions.Item label="게임명">
                            <Form.Item name="globalId" rules={formValidator.getRules('create_project_game_id')}>
                                <Select
                                    disabled
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string)
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    optionFilterProp="children"
                                    data-test_id="gameId"
                                >
                                    {allGameList?.map((it) => (
                                        <Select.Option
                                            key={it.id}
                                            value={it.gameId}
                                            data-test_id={`gameId_${it.gameId}`}
                                        >
                                            {it.gameName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="환경">
                            <Form.Item name="environment" rules={formValidator.getRules('create_project_env')}>
                                <Radio.Group defaultValue={projectValues?.environment} disabled>
                                    {isProd ? (
                                        <Radio value={EProjectEnvironment.PRODUCTION}>
                                            {EProjectEnvironment.PRODUCTION.toLocaleUpperCase()}
                                        </Radio>
                                    ) : (
                                        <>
                                            <Radio value={EProjectEnvironment.QA}>
                                                {EProjectEnvironment.QA.toLocaleUpperCase()}
                                            </Radio>
                                            <Radio value={EProjectEnvironment.DEVELOPMENT}>
                                                {EProjectEnvironment.DEVELOPMENT.toLocaleUpperCase()}
                                            </Radio>
                                        </>
                                    )}
                                </Radio.Group>
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="시트 ID 사용">
                            <Form.Item
                                initialValue={!!projectDetail?.driveUrl}
                                name="hasSheetId"
                                rules={formValidator.getRules('sheet_id')}
                            >
                                <Radio.Group
                                    value={hasSheetId}
                                    onChange={(e) => {
                                        setHasSheetId(e.target.value);
                                    }}
                                    disabled
                                >
                                    <Radio value={true}>시트 ID 사용</Radio>
                                    <Radio value={false}>시트 ID 사용 X (A+B)</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Descriptions.Item>

                        {hasSheetId !== undefined &&
                            (hasSheetId ? (
                                <>
                                    <Descriptions.Item label="시트 ID">
                                        <Form.Item name="driveUrl">
                                            <Input disabled />
                                        </Form.Item>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="서버 URL">
                                        <Form.Item name="serverUrl">
                                            <Input disabled />
                                        </Form.Item>
                                    </Descriptions.Item>
                                </>
                            ) : (
                                <>
                                    <Descriptions.Item label="언어 추가">
                                        {languages?.map((lang) => (
                                            <Tag key={lang}>{lang}</Tag>
                                        ))}
                                    </Descriptions.Item>
                                </>
                            ))}

                        {projectValues && (
                            <Descriptions.Item label="담당자">
                                <Form.Item name="managers">
                                    <div className={cx('userBadgeWrapper')}>
                                        {projectAccounts?.map((account) => (
                                            <Tag key={account.id}>{account.name}</Tag>
                                        ))}
                                    </div>
                                </Form.Item>
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                    <div css={footer}>
                        <Button type="primary" onClick={onCancel}>
                            확인
                        </Button>
                    </div>
                </EnterIgnoreForm>
            </section>
        </ModalWrapper>
    );
}

const footer = css`
    margin-top: 16px;
    text-align: right;
    > button {
        margin-left: 10px;
    }
`;
