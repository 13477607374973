import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { NoticeRepository } from 'repository';
import { WebviewNoticeListRequest } from '@gamebase-web-ops/notice';

export default function useWebviewNoticeListModel(projectId: string, limit = 15) {
    const [filter, setFilter] = useState<WebviewNoticeListRequest>();
    const queries = useQuery([{ ...filter, projectId }, 'webviewNotice'], () =>
        filter ? NoticeRepository.listWebviewNotice(new WebviewNoticeListRequest({ ...filter, projectId })) : null,
    );

    const setPage = useCallback(
        (page: number) => {
            setFilter((prev) => {
                if (prev) {
                    return { ...prev, page };
                }
                return {
                    projectId,
                    limit,
                    page,
                };
            });
        },
        [projectId, limit],
    );

    const setFirstPageOrRefetch = useCallback(() => {
        setFilter((prev) => {
            if (prev?.page === 1) {
                queries.refetch();
            }

            return { projectId, limit, page: 1 };
        });
    }, [limit, projectId, queries]);

    const findByNoticeTitle = useCallback(
        (title: string) => {
            setFilter({
                limit,
                page: 1,
                projectId,
                searchWord: title,
            });
        },
        [projectId, limit],
    );

    return {
        ...queries,
        setPage,
        setFirstPageOrRefetch,
        filter,
        findByNoticeTitle,
    };
}
