import { css } from '@emotion/react';
import { notImage } from 'constants/constants';
import { useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import DraggableWrapper from '../../utils/DraggableWrapper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { WebviewNoticeDto } from '@gamebase-web-ops/notice';

import { useLang, useQueries } from 'hooks';

interface IProp {
    webviewDatas: WebviewNoticeDto[];
    onDrop: (first: number, second: number) => void;
    hasWritePermission: boolean;
}

const PreviewCard = ({ noticeData }: { noticeData: WebviewNoticeDto }) => {
    const { projectId } = useQueries();
    const { projectLang } = useLang(projectId);
    const imageOnError = useCallback((e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = notImage;
    }, []);
    return (
        <section css={wrapper}>
            <div css={imageWrapper}>
                <img
                    css={imageContents}
                    src={noticeData.mainImages ? noticeData.mainImages[projectLang] : notImage}
                    onError={imageOnError}
                />
            </div>
            <div css={titleText}>{noticeData.titles ? noticeData.titles[projectLang] : ''}</div>
        </section>
    );
};

export default function WebviewPreviewList({ webviewDatas, onDrop, hasWritePermission }: IProp) {
    if (hasWritePermission)
        return (
            <DndProvider backend={HTML5Backend}>
                {webviewDatas.map((noticeData, index) => (
                    <DraggableWrapper move={onDrop} index={index} type="notice-image" key={noticeData.id}>
                        <PreviewCard noticeData={noticeData} />
                    </DraggableWrapper>
                ))}
            </DndProvider>
        );

    return (
        <div>
            {webviewDatas.map((noticeData) => (
                <section key={noticeData.id}>
                    <PreviewCard noticeData={noticeData} />
                </section>
            ))}
        </div>
    );
}

const imageWrapper = css`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 30%;
    overflow: hidden;
    background-color: #efefef;
`;

const imageContents = css`
    position: absolute;
    height: 100%;
    width: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const titleText = css`
    font-size: 24px;
    padding: 8px;
`;

const wrapper = css`
    border: 1px solid black;
`;
