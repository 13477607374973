import { MyProjectDto } from '@gamebase-web-ops/authorization/lib/v2';
import { useMemo } from 'react';
import { Authorization2Repository } from 'repository';
import { useQuery } from 'react-query';
import { PROJECT_LIST_KEY } from 'constants/queryKeys';
import { EGameState } from '@gamebase-web-ops/game';
import { ENV_TYPE } from 'components/projectList/constants';

interface ProjectListProps {
    gameState?: EGameState | 'all';
    gameSearch: string;
    environment: ENV_TYPE;
}

export default function useProjectList({ gameState, gameSearch, environment }: ProjectListProps) {
    const { data: projectList, isLoading } = useQuery(PROJECT_LIST_KEY, async () => {
        const res = await Authorization2Repository.projectList();
        return res.result;
    });

    const stateCodeProjectList =
        gameState === 'all'
            ? projectList
            : projectList?.filter((project) => project.game?.platforms.find((item) => item.stateCode === gameState));

    const groupByEnvProjectList = useMemo(() => {
        const groupMap: Record<string, MyProjectDto[]> = {
            prod: [],
            qa: [],
            dev: [],
        };

        stateCodeProjectList?.map((project) => {
            if (project.environment) {
                groupMap[project.environment].push(project);
            }
        });
        return groupMap;
    }, [stateCodeProjectList]);

    const searchProjectList = groupByEnvProjectList[environment]?.filter(({ name }) => {
        return name.toLowerCase().includes(gameSearch.toLowerCase());
    });

    return {
        stateCodeProjectList,
        projectList,
        groupByEnvProjectList,
        searchProjectList,
        isLoading,
    };
}
