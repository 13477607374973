import { add, remove } from 'utils/arrayUtils';
import useAccountListModel from './model/useAccountList.model';
import { AccountBasicDto } from '@gamebase-web-ops/account';
import { useState, useCallback } from 'react';

export default function useAccounts() {
    const PAGE_SIZE = 15;
    const [searchAccounts, setSearchAccounts] = useState<Partial<AccountBasicDto>[]>([]);
    const { data, setFirstPageOrRefetch, setPage, query, requestAccountsByKeyword } = useAccountListModel(PAGE_SIZE);
    const addAccounts = useCallback(async (param: Partial<AccountBasicDto>) => {
        add(setSearchAccounts, param);
    }, []);

    const removeAccounts = useCallback(async (param: Partial<AccountBasicDto>) => {
        remove(setSearchAccounts, param);
    }, []);

    return {
        accounts: data?.result.data || [],
        page: query?.page,
        setPage,
        totalCount: data?.result.totalCount,
        setFirstPageOrRefetch,
        requestAccountsByKeyword,
        PAGE_SIZE,
        searchAccounts,
        addAccounts,
        setSearchAccounts,
        removeAccounts,
    };
}
