import { EMailType } from '@gamebase-web-ops/mail';
import { PushListRequest } from '@gamebase-web-ops/push';
import { AppAdsListFilter } from 'hooks/appAds/useAppAds';
import { MailTemplateFilter } from 'hooks/mail/useMailTemplateOps';
import { SystemMailFilter } from 'hooks/mail/useSystemMail';
import { TargetMailFilter } from 'hooks/mail/useTargetMail';
import { PlatformListRequest } from '@gamebase-web-ops/platform';
import { GetRankerRequest, InfoRankRequest, ListRankRequest } from '@gamebase-web-ops/rank';
import { EPlatformServerState } from '@gamebase-web-ops/app-version';
import { AppReviewInfoRequest, AppReviewListRequest } from '@gamebase-web-ops/app-review';
import { EAccessType } from '@gamebase-web-ops/chat';
import { ReportInfoRequest } from '@gamebase-web-ops/report';
import { VideoListRequest } from '@gamebase-web-ops/video';
import { CommunityListRequest } from '@gamebase-web-ops/community';
import {
    ListAccountRecordsRequest,
    ListSearchModeRequest,
    SearchModeInfoRequest,
} from '@gamebase-web-ops/player-search';
import { GetBypassPurchasesRequest } from '@gamebase-web-ops/receipt';

interface PolicyTemplateFind {
    projectId: string;
    category: string;
}
interface PolicyAllFind {
    projectId: string;
    policyTemplateId?: string;
}
interface PolicyFind {
    projectId: string;
    id?: string;
}

interface PolicyCategory {
    projectId: string;
}
interface PolicySetting {
    projectId: string;
}

interface NoticeList {
    projectId: string;
    limit: number;
    page: number;
    startTime?: Date;
    endTime?: Date;
}

interface MailList {
    projectId: string;
    mailType: EMailType;
    filter: SystemMailFilter | TargetMailFilter;
}

interface PushList {
    projectId: string;
    filter: PushListRequest;
}

interface RTServerList {
    projectId: string;
}

interface MailTemplateList {
    projectId: string;
    filter: MailTemplateFilter;
}

interface AllMailTemplateList {
    projectId: string;
}

interface RoleList {
    limit: number;
    page: number;
    name?: string;
    description?: string;
}

interface AppVersionList {
    projectId: string;
    appVersion?: string;
    isReview?: string;
    aosServerStatus?: EPlatformServerState;
    iosServerStatus?: EPlatformServerState;
}

interface AppReviewTemplate {
    projectId: string;
    page: number;
}

interface Chatting {
    projectId: string;
}

interface Channel {
    projectId: string;
    accessType: EAccessType;
    key: string;
}

export const PROJECT_LIST_KEY = 'PROJECT_LIST_KEY';

export const POLICY_TEMPLATE_FIND_KEY = 'POLICY_TEMPLATE_FIND';
export const POLICY_ALL_FIND_KEY = 'POLICY_ALL_FIND';
export const POLICY_FIND_KEY = 'POLICY_FIND';
export const POLICY_CATEGORY_KEY = 'POLICY_CATEGORY_KEY';
export const POLICY_SETTING_INFO_KEY = 'POLICY_SETTING_INFO_KEY';
export const POLICY_ALL_DEFAULT_AGREEMENT_KEY = 'POLICY_ALL_DEFAULT_AGREEMENT_KEY';

export const URGENT_NOTICE_LIST_KEY = 'URGENT_NOTICE_LIST_KEY';
export const INGAME_NOTICE_LIST_KEY = 'INGAME_NOTICE_LIST_KEY';
export const NORMAL_NOTICE_LIST_KEY = 'NORMAL_NOTICE_LIST_KEY';

export const MAIL_LIST_KEY = 'mailList';

export const PUSH_LIST_KEY = 'pushList';

export const RT_SERVER_LIST_KEY = 'rtServerListKey';

export const APP_ADS_LIST_KEY = 'APP_ADS_LIST_KEY';

export const MAIL_TEMPLATE_LIST_KEY = 'MAIL_TEMPLATE_LIST_KEY';

export const PLATFORM_LIST_KEY = 'PLATFORM_LIST_KEY';
export const PLATFORM_CHANGE_KEY = 'PLATFORM_CHANGE_KEY';

export const ROLE_LIST_KEY = 'ROLE_LIST_KEY';

export const MY_PROJECT_LIST = 'MY_PROJECT_LIST';

export const APP_VERSION_LIST = 'APP_VERSION_LIST';

export const APP_REVIEW_INFO = 'APP_REVIEW_INFO';
export const APP_REVIEW_LIST = 'APP_REVIEW_LIST';
export const APP_REVIEW_TEMPLATE = 'APP_REVIEW_TEMPLATE';
export const CHATTING_KEY = 'CHATTING';
export const CHANNEL_KEY = 'CHANNEL';
export const REPORT_INTO_KEY = 'REPORT_INTO_KEY';
export const VIDEO_LIST_KEY = 'VIDEO_LIST_KEY';
export const COMMUNITY_LIST_KEY = 'COMMUNITY_LIST_KEY';
export const SEARCH_MODE_LIST_KEY = 'SEARCH_MODE_LIST_KEY';
export const SEARCH_MODE_INFO_KEY = 'SEARCH_MODE_INFO_KEY';
export const MATCH_INFO_KEY = 'MATCH_INFO_KEY';
export const ACCOUNT_RECORD_LIST_KEY = 'ACCOUNT_RECORD_LIST_KEY';
export const BYPASS_PURCHASE_LIST_KEY = 'BYPASS_PURCHASE_LIST_KEY';

export const policyTemplateFindQueryKey = ({ projectId, category }: PolicyTemplateFind) =>
    [POLICY_TEMPLATE_FIND_KEY, { projectId, category }] as const;

export const policyAllFindQueryKey = ({ projectId, policyTemplateId }: PolicyAllFind) =>
    [POLICY_ALL_FIND_KEY, { projectId, policyTemplateId }] as const;

export const policyFindQueryKey = ({ projectId, id }: PolicyFind) => [POLICY_FIND_KEY, { projectId, id }] as const;

export const policyCategoryKey = ({ projectId }: PolicyCategory) => [POLICY_CATEGORY_KEY, { projectId }] as const;

export const policySettingInfoKey = ({ projectId }: PolicySetting) => [POLICY_SETTING_INFO_KEY, { projectId }] as const;

export const policyDefaultAgreementSettingKey = () => [POLICY_ALL_DEFAULT_AGREEMENT_KEY] as const;

export const urgentNoticeListQueryKey = ({ projectId, limit, page, startTime, endTime }: NoticeList) =>
    [URGENT_NOTICE_LIST_KEY, { projectId, limit, page, startTime, endTime }] as const;

export const ingameNoticeListQueryKey = ({ projectId, limit, page, startTime, endTime }: NoticeList) =>
    [INGAME_NOTICE_LIST_KEY, { projectId, limit, page, startTime, endTime }] as const;

export const normalNoticeListQueryKey = ({ projectId, limit, page, startTime, endTime }: NoticeList) =>
    [NORMAL_NOTICE_LIST_KEY, { projectId, limit, page, startTime, endTime }] as const;

export const mailListQueryKey = ({ projectId, mailType, filter }: MailList) =>
    [MAIL_LIST_KEY, projectId, mailType, filter] as const;

export const pushListQueryKey = ({ projectId, filter }: PushList) => [PUSH_LIST_KEY, projectId, filter] as const;

export const rtServerListQueryKey = ({ projectId }: RTServerList) => [RT_SERVER_LIST_KEY, { projectId }] as const;

export const gameDataInfoKey = ({ id }: { id: string | undefined }) => ['GAME_DATA_INFO', { id }] as const;
export const appAdsListQueryKey = (filter: AppAdsListFilter) => [APP_ADS_LIST_KEY, filter] as const;

export const mailTemplateListKey = ({ projectId, filter }: MailTemplateList) =>
    [MAIL_TEMPLATE_LIST_KEY, projectId, filter] as const;

export const allMailTemplateKey = ({ projectId }: AllMailTemplateList) => [MAIL_TEMPLATE_LIST_KEY, projectId] as const;

export const platformListKey = ({ accountId, gameId }: PlatformListRequest) =>
    [PLATFORM_LIST_KEY, { accountId, gameId }] as const;

export const rankingListKey = ({ projectId, page, limit, name }: ListRankRequest) =>
    ['RANGKING_LIST_KEY', { projectId, page, limit, name }] as const;
export const rankingInfoKey = ({ id, projectId }: InfoRankRequest) => ['RANGKING_INFO_KEY', { id, projectId }] as const;
export const getRankerKey = ({ id, projectId, accountId }: GetRankerRequest) =>
    ['GET_RANGKER_KEY', { id, projectId, accountId }] as const;
export const roleListKey = ({ limit, page, name, description }: RoleList) => [
    ROLE_LIST_KEY,
    limit,
    page,
    name,
    description,
];

export const appVersionListKey = ({
    projectId,
    appVersion,
    isReview,
    aosServerStatus,
    iosServerStatus,
}: AppVersionList) => [APP_VERSION_LIST, projectId, appVersion, isReview, aosServerStatus, iosServerStatus];

export const appReviewInfoKey = ({ reviewId, projectId, platform }: AppReviewInfoRequest) =>
    [APP_REVIEW_INFO, { reviewId, projectId, platform }] as const;

export const appReviewListKey = ({ projectId, platform, nextPageToken }: AppReviewListRequest) =>
    [APP_REVIEW_LIST, { projectId, platform, nextPageToken }] as const;

export const appReviewTemplateKey = ({ projectId, page }: AppReviewTemplate) =>
    [APP_REVIEW_LIST, projectId, page] as const;

export const chattingKey = ({ projectId }: Chatting) => [CHATTING_KEY, projectId] as const;
export const channelKey = ({ projectId, accessType, key }: Channel) =>
    [CHANNEL_KEY, projectId, accessType, key] as const;

export const reportInfoKey = ({ projectId, userId }: ReportInfoRequest) =>
    [REPORT_INTO_KEY, { projectId, userId }] as const;

export const videoListKey = ({ projectId, language }: VideoListRequest) =>
    [VIDEO_LIST_KEY, { projectId, language }] as const;

export const communityListKey = ({ projectId, language }: CommunityListRequest) =>
    [COMMUNITY_LIST_KEY, { projectId, language }] as const;

export const searchModeListKey = ({ projectId, page, limit }: ListSearchModeRequest) =>
    [SEARCH_MODE_LIST_KEY, { projectId, page, limit }] as const;

export const searchModeInfoKey = ({ projectId, id }: SearchModeInfoRequest) =>
    [SEARCH_MODE_LIST_KEY, { projectId, id }] as const;

export const matchInfoKey = ({ projectId, id }: SearchModeInfoRequest) => [MATCH_INFO_KEY, { projectId, id }] as const;

export const accountRecordListKey = ({
    projectId,
    searchModeId,
    condition,
    page,
    limit,
    orderBy,
    accountId,
    startDt,
    endDt,
}: ListAccountRecordsRequest) =>
    [
        ACCOUNT_RECORD_LIST_KEY,
        { projectId, searchModeId, condition, page, limit, orderBy, accountId, startDt, endDt },
    ] as const;

export const bypassPurchaseListKey = ({ projectId }: GetBypassPurchasesRequest) =>
    [BYPASS_PURCHASE_LIST_KEY, { projectId }] as const;
