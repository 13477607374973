import React, { memo, useEffect, Suspense } from 'react';
import * as qs from 'qs';
import { usePageAuthCheck, useQueries, useProjectDetail } from 'hooks';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useCallback } from 'react';
import { LoadingSpin } from 'components';
import NoResult from 'components/common/NoResult';

/**
 *
 * Route 페이지에 대한 권한을 체크 하는 컴포넌트
 *
 */
const PageAuthViewController: React.FC<IRouteController> = ({
    service,
    projectId,
    level = 1,
    children,
    auths = [],
}) => {
    const { projectId: pid } = useQueries();
    const { projectManagers } = useProjectDetail(pid);

    const { search } = useLocation();

    const { pageAuthCheck, isLoading } = usePageAuthCheck();

    const [successParam, setSuccessParam] = useState<IAuthCheck | null | 'Forbidden' | 'Error'>(null);

    const asyncAuthCheck = useCallback(
        async (payload) => {
            const result = await pageAuthCheck(payload);
            setSuccessParam(result);
        },
        [pageAuthCheck],
    );

    useEffect(() => {
        if (service && level) {
            const { projectId: qsProjectId } = qs.parse(search, { ignoreQueryPrefix: true });
            const payload = { service: service as string, projectId: projectId ?? (qsProjectId as string), level };
            asyncAuthCheck(payload);
        }
    }, [asyncAuthCheck, level, projectId, service, search]);

    if (successParam === 'Forbidden') {
        const managers = projectManagers?.map((manager) => `${manager}님`).join(', ');
        const desc =
            pid && managers
                ? `접근 권한이 없습니다. \n프로젝트 담당자 ${managers}께 문의 주세요.`
                : '접근 권한이 없습니다. \n게임베이스팀 Web파트로 문의 주세요';

        return <NoResult description={desc} status="unauthorized" />;
    }
    if (successParam === 'Error') {
        return <NoResult description="오류가 발생했습니다. 게임베이스팀 Web파트로 문의 주세요" status="unauthorized" />;
    }
    // children으로는 페이지 컴포넌트를 넣어서 모든 권한체크가 되기 전까지는 children을 렌더하지 않습니다.

    if (isLoading || !successParam) return <LoadingSpin />;
    return (
        <Suspense fallback={<LoadingSpin />}>
            {React.Children.map(children, (child) =>
                React.cloneElement(child as any, {
                    auths: successParam ? [...auths, successParam] : auths,
                }),
            )}
        </Suspense>
    );
};

export default memo(PageAuthViewController);
