import { ClassRewardListRequest, ClassRewardListResponse } from '@gamebase-ops/interfaces/gameClass';
import { IGameClassRepository } from '@gamebase-ops/interfaces/gameClass/interface';
import { Query } from '@gamebase-ops/interfaces/utils';
import { get } from 'utils/api/client';

const baseUrl = '/api/v1/gameclasses';

class ClassScheduleRepository implements IGameClassRepository {
    rewards(request: Query<ClassRewardListRequest>): Promise<ClassRewardListResponse> {
        return get(baseUrl + '/rewards', new ClassRewardListRequest(request.query));
    }
}

export default new ClassScheduleRepository();
