import { Table, TableProps } from 'antd';

import classNames from 'classnames/bind';
import styles from './OddFillTable.module.scss';

const cx = classNames.bind(styles);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const OddFillTable = (props: TableProps<any>) => {
    return <Table className={cx('wrapper')} {...props} />;
};

export default OddFillTable;
