import { PackageCreateDto, PackageItem, PackageListQuery, PackageUpdateDto } from 'dto/package.dto';
import { del, get, patch, post } from 'utils/api/client';

const baseURL = '/api/v1/packages';
class PackageRepository {
    getAll(param: PackageListQuery) {
        return get<{ lastPage: number; packages: PackageItem[]; totalCount: number }>(
            baseURL,
            new PackageListQuery(param),
        );
    }

    getDetail(oid: string, projectId: string) {
        return get<PackageItem>(`${baseURL}/${oid}`, { projectId });
    }

    create(param: PackageCreateDto) {
        return post(`${baseURL}`, new PackageCreateDto(param), { projectId: param.projectId });
    }

    delete(oid: string, projectId: string) {
        return del(`${baseURL}/${oid}`, { projectId });
    }

    patch(oid: string, params: PackageUpdateDto, projectId: string) {
        return patch(`${baseURL}/${oid}`, params, { projectId });
    }
}

export default new PackageRepository();
