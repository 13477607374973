import { validateSync } from 'class-validator';

export const classValidate = (obj: object) => {
    const error = validateSync(obj);
    if (error.length === 0) {
        return { isSuccess: true };
    } else {
        if (error[0].constraints) {
            const key = Object.keys(error[0].constraints)[0];

            return { isSuccess: false, message: error[0].constraints[key] };
        }
        return { isSuccess: false, message: '벨리데이션 오류' };
    }
};
