import { notificationPush } from 'utils/notificationUtils';
import { useCallback, useState } from 'react';
import { Authorization2Repository } from 'repository';
import useAuth from './useAuth';
import { CheckRequest } from '@gamebase-web-ops/authorization/lib/v2';

export default function usePageAuthCheck() {
    const [isLoading, setIsLoading] = useState(true);
    const { logoutRequest } = useAuth();

    const pageAuthCheck = useCallback(
        async (param: CheckRequest): Promise<CheckRequest | null | 'Forbidden' | 'Error'> => {
            let level = param.level;
            while (level) {
                try {
                    await Authorization2Repository.check(new CheckRequest({ ...param, level }));
                    setIsLoading(false);
                    return param;
                } catch (e: any) {
                    if (e.message === 'Forbidden') {
                        return e.message;
                    }
                    if (e.code === 401) {
                        logoutRequest();
                        notificationPush({ status: 'error', message: '로그인이 만료되었습니다. 로그아웃합니다.' });
                        return null;
                    }
                    level--;
                    return 'Error';
                }
            }
            setIsLoading(false);
            return null;
        },
        [logoutRequest],
    );

    return {
        isLoading,
        pageAuthCheck,
    };
}
