import { CreateBlockTemplateRequest, UpdateBlockTemplateRequest } from '@gamebase-web-ops/game-user-block';
import { useMutation, useQuery } from 'react-query';
import { BlockUserRepository } from 'repository';
import { notificationPush } from 'utils/notificationUtils';

export default function useBlockTemplateModel({ projectId }: { projectId: string }) {
    const queries = useQuery(['blockTemplate', projectId], () => BlockUserRepository.getBlockTemplates({ projectId }));

    const createMutation = useMutation(
        async (request: CreateBlockTemplateRequest) => {
            await BlockUserRepository.createBlockTemplate(request);
        },
        {
            onSuccess: () => {
                notificationPush({
                    status: 'success',
                    message: '템플릿 등록에 성공했습니다.',
                });
            },
            onError: (err: any) => {
                notificationPush({
                    status: 'error',
                    message: err?.message ?? '템플릿 등록에 실패했습니다.',
                });
            },
        },
    );

    const updateMutation = useMutation(
        async (request: UpdateBlockTemplateRequest) => await BlockUserRepository.updateBlockTemplate(request),
        {
            onSuccess: () => {
                notificationPush({
                    status: 'success',
                    message: '템플릿 수정에 성공했습니다.',
                });
            },
            onError: (err: any) => {
                notificationPush({
                    status: 'error',
                    message: err?.message ?? '템플릿 수정에 실패했습니다.',
                });
            },
        },
    );

    const { mutateAsync: createTemplate, isLoading: isCreateTemplate, isError: createError } = createMutation;
    const { mutateAsync: updateTemplate, isLoading: isUpdateTemplate } = updateMutation;

    return {
        ...queries,
        createTemplate,
        updateTemplate,
        isCreateTemplate,
        isUpdateTemplate,
        createError,
    };
}
