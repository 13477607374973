import { useRef, useState } from 'react';
import { RoleDetailDto } from '@gamebase-web-ops/role/lib/v2';
import useGetRoleDetail2 from './model/useGetRoleDetail2';

export default function useRoleDetail2(id?: string) {
    const [permissions, setPermissions] = useState<RolePermission[]>([]);

    const roleNameRef = useRef<any>();
    const descriptionRef = useRef<any>();

    const { data: role } = useGetRoleDetail2(id);

    return {
        permissions,
        roleNameRef,
        descriptionRef,
        setPermissions,
        role: role?.result,
    };
}
