/**
 * accessToken을 관리하는 싱글톤 객체
 */
class RequestAccess {
    private static instance: RequestAccess;
    private accessToken?: string;
    private pending?: boolean;

    private constructor() {
        this.accessToken = undefined;
    }

    public static getInstance() {
        return this.instance || (this.instance = new this());
    }

    getAccessToken() {
        return this.accessToken;
    }

    getPending() {
        return this.pending;
    }

    setAccessToken(token: string) {
        this.accessToken = token;
    }

    clearAccessToken() {
        this.accessToken = undefined;
    }
}

export default RequestAccess;
