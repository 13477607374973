import { handleActions } from 'redux-actions';
import { Lang, ProjectLang } from './action';

const initState: LangStore = {
    lang: '',
    projectLang: '',
};

export default handleActions(
    {
        [Lang.set]: (prev, { payload }: { payload: string }) => {
            return { ...prev, lang: payload };
        },
        [ProjectLang.set]: (prev, { payload }: { payload: string }) => {
            return {
                ...prev,
                projectLang: payload,
            };
        },
    },
    initState,
);
