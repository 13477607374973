import { IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { IsExcuteTest } from './customDecorators';
import { PaginationQuery } from './utils.dto';

export class PackageListQuery extends PaginationQuery {
    constructor(param: PackageListQuery) {
        super(param);
        this.projectId = param.projectId;
        this.keyword = param.keyword;
    }
    @IsNotEmpty()
    @IsString()
    projectId: string;

    @IsOptional()
    @IsString()
    keyword?: string;
}

class PackageNameDto {
    ko: string;
    ja: string;
}

export type PackageNameDtoKeys = keyof PackageNameDto;

class IPackageItem {
    ItemId: number;
    Value: number;
}

export class PackageCreateDto {
    constructor(param: PackageCreateDto) {
        this.items = param.items;
        this.projectId = param.projectId;
        this.description = param.description;
        this.name = param.name;
        this.productId = param.projectId;
    }
    items: IPackageItem[];

    @IsExcuteTest<PackageCreateDto>(
        (obj) => {
            if (obj.name.ko && obj.name.ja) return true;
            return false;
        },
        {
            message: '패키지명은 필수 입니다.',
        },
    )
    name: PackageNameDto;
    description: string;
    projectId: string;

    @IsNotEmpty()
    @IsString()
    productId: string;
}

export class PackageUpdateDto {
    constructor(param: PackageUpdateDto) {
        this.name = param.name;
        this.items = param.items;
        this.description = param.description;
        this.productId = param.productId;
    }
    name?: PackageNameDto;
    items?: IPackageItem[];
    description?: string;
    productId?: string;
}

interface DetailItem extends IItem {
    ItemId: number;
    Value: number;
}

export class PackageItem {
    _id: string;
    description?: string;
    isDeleted: boolean;
    items?: DetailItem[];
    name: PackageNameDto;
    projectId: number;
    productId: number;
}
