import { ESheetDataActionType, SheetActionRequest } from '@gamebase-web-ops/sheet-data';
import { Modal } from 'antd';
import { useMutation } from 'react-query';
import { SheetDataRepository } from 'repository';
import { notificationPush } from 'utils/notificationUtils';
import { IoMdWarning } from 'react-icons/io';

export default function useSheet() {
    const copyMutation = useMutation(
        async (args: { projectId: string; authCode: string; gameId: number }) => {
            return await SheetDataRepository.exec(
                new SheetActionRequest({
                    ...args,
                    actionType: ESheetDataActionType.COPY_TEMPLATE,
                }),
            );
        },
        {
            async onSuccess(res) {
                if (res.code !== 'SUCCESS') {
                    await notificationPush({
                        status: 'error',
                        title: '템플릿 복사 실패',
                        message: res.message,
                    });
                } else {
                    let copyFlag = true;
                    try {
                        await navigator.clipboard.writeText(res.result);
                    } catch (_) {
                        copyFlag = false;
                    }

                    await notificationPush({
                        status: 'success',
                        title: '템플릿 복사 성공',
                        message: copyFlag ? '클립보드에 시트 아이디가 복사되었습니다.' : '성공',
                    });
                }
            },
        },
    );

    const syncMutation = useMutation(
        async (args: { projectId: string; authCode: string; actionType: ESheetDataActionType }) => {
            return await SheetDataRepository.exec(new SheetActionRequest({ ...args }));
        },
        {
            async onSuccess(res) {
                if (res.code === 'SUCCESS') {
                    await notificationPush({
                        status: 'success',
                        title: '시트동기화 성공',
                    });
                }
            },
        },
    );

    const { mutateAsync: copyTemplate, isLoading: isCopyLoading } = copyMutation;
    const { mutateAsync: syncSheet, isLoading: isSyncLoading } = syncMutation;
    return {
        copyTemplate,
        isCopyLoading,
        syncSheet,
        isSyncLoading,
    };
}
