import { Spin } from 'antd';
import classNames from 'classnames/bind';
import { useFormatMessage, useScreenLock } from 'hooks';
import styles from './ScreenLockPending.module.scss';

const cx = classNames.bind(styles);

const ScreenLockPending = () => {
    const { isLock, message } = useScreenLock();
    const { messageFormatting } = useFormatMessage();
    if (isLock)
        return (
            <div className={cx('wrapper')}>
                <i>
                    <Spin tip={message || messageFormatting('PLEASE_WAIT')} />
                </i>
            </div>
        );

    return null;
};

export default ScreenLockPending;
