import { NOTICE_STATE } from 'constants/constants';

export class WebviewNoticeCreate {
    constructor(param: WebviewNoticeCreate) {
        this.titles = param.titles;
        this.contents = param.contents;
        this.mainImages = param.mainImages;
        this.startTime = param.startTime;
        this.endTime = param.endTime;
        this.defaultLanguage = param.defaultLanguage;
    }
    titles: Record<string, string>;
    contents: Record<string, string>;
    mainImages: Record<string, string>;
    startTime: Date;
    endTime: Date;
    defaultLanguage: string;
}

export interface IWebviewNotice {
    titles?: Record<string, string>;
    contents?: Record<string, string>;
    mainImages?: Record<string, string>;
    state: NOTICE_STATE;
    startTime: string;
    endTime: string;
    projectId: number;
    defaultLanguage: string;
    _id: string;
    sorted: number;
    category?: string;

    updDt: string;
    regDt: string;
    regId: IAccount;
    updId: IAccount;
}

export class WebviewNoticeSortDto {
    constructor(param: WebviewNoticeSortDto) {
        this.notices = param.notices;
    }
    notices: Array<{
        oid: string;
        sorted: number;
    }>;
}
