import { UploadOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Button } from 'antd';
import { InputHTMLAttributes, useCallback, useRef, useState } from 'react';
import { Common } from 'styles/common';

export default function FileUpload(props: Omit<InputHTMLAttributes<HTMLInputElement>, 'style' | 'className' | 'type'>) {
    const inputRef = useRef<HTMLInputElement>(null);
    const [names, setNames] = useState<string[]>([]);

    const buttonClickListener = useCallback(() => {
        inputRef.current?.click();
    }, []);

    return (
        <section css={wrapper}>
            <Button onClick={buttonClickListener} type="primary" ghost icon={<UploadOutlined />}>
                {props.title ?? 'CSV 파일 업로드'}
            </Button>
            <input
                ref={inputRef}
                css={inputHidden}
                style={{ display: 'none' }}
                {...props}
                onChange={(e) => {
                    if (e.target.files) {
                        const files = [...e.target.files];
                        setNames(files.map((file) => file.name));
                        if (props.onChange) props.onChange(e);
                    }
                }}
                type="file"
            />
            <div>{names.length ? names.map((name, index) => <span key={index}>{name}</span>) : ''}</div>
        </section>
    );
}

const fileUploadText = css`
    color: #b7b6b6;
    font-size: 14px;
`;

const inputHidden = css`
    display: none;
`;

const wrapper = css`
    ${Common.searchWrapper}
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    > button {
        margin-right: 10px;
        margin-bottom: 10px;
    }
`;
