import { useCallback, useState } from 'react';
import { GameRepository } from 'repository';
import useGameAllListModel from './model/useGameAllList.model';
import { EGameState, GameDto, GameCreateRequest, GameUpdateRequest } from '@gamebase-web-ops/game';

export default function useGameManage() {
    const { data, refetch } = useGameAllListModel();

    const [selectedGame, setSelectedGame] = useState<GameDto>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const _platformParser = useCallback((param: any) => {
        const platforms =
            param.platforms?.map((it: any) => {
                return { ...it, stateCode: Number(it.stateCode) as EGameState };
            }) || [];
        return {
            ...param,
            platforms,
        };
    }, []);
    const createGame = useCallback(
        async (param: GameCreateRequest) => {
            setIsLoading(true);
            try {
                const result = await GameRepository.create(_platformParser(param));
                refetch();
                return result.result;
            } finally {
                setIsLoading(false);
            }
        },
        [_platformParser, refetch],
    );

    const updateGame = useCallback(
        async (param: GameUpdateRequest) => {
            if (!selectedGame?.id) {
                throw new Error('선택 되어 있는 게임이 존재 하지 않습니다.');
            }
            try {
                setIsLoading(true);
                await GameRepository.update(_platformParser({ ...param, id: selectedGame?.id }));
                refetch();
            } finally {
                setIsLoading(false);
            }
        },
        [_platformParser, refetch, selectedGame?.id],
    );

    return {
        allGameList: data?.result,
        createGame,
        updateGame,
        selectedGame,
        setSelectedGame,
        isLoading,
    };
}
