import { Dropdown, Space } from 'antd';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './ManageMentMenu.module.scss';
import { useAuth } from 'hooks';
import { PageAuthViewController } from 'provider';
import { COMMON_PROJECT_ID } from 'constants/constants';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { EServiceAuthLevel, EServiceAuthName } from '@gamebase-web-ops/authorization/lib/v2';
import { MdLogout, MdMenu, MdOutlineMenuOpen, MdPerson, MdPersonOutline, MdVerifiedUser } from 'react-icons/md';
import { css } from '@emotion/react';
import Icon from '@ant-design/icons';

interface IProp extends IAuthPageProps {
    isAdmin: boolean;
    username: string;
}

const cx = classNames.bind(styles);

const ManageMentMenu: React.FC<IProp> = ({ auths, isAdmin, username }) => {
    const { logoutRequest } = useAuth();

    return (
        <section className={cx('infoWrapper')}>
            <div css={mainMenuWrap}>
                <Link to="/">게임 선택</Link>
                <Link to="/common/commonManagement/game">통합 관리</Link>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.notion.so/e9aaf79bb19446c8b51ebd2070c1bc7e?v=1043cbefe4304f93a84478f4b488e3e7"
                >
                    연동가이드
                </a>
            </div>
            <div css={myPage}>
                <Dropdown
                    placement="bottom"
                    overlayStyle={{ width: '140px' }}
                    menu={{
                        items: [
                            {
                                label: (
                                    <Link to="/myPage" className={cx('settingOption')}>
                                        <div css={logout}>
                                            <MdPersonOutline />
                                            <FormattedMessage id="MYPAGE" />
                                        </div>
                                    </Link>
                                ),
                                key: 'my-page',
                            },
                            {
                                label: (
                                    <div
                                        key="logout"
                                        onClick={logoutRequest}
                                        css={logout}
                                        className={cx('settingOption')}
                                    >
                                        <MdLogout />
                                        <FormattedMessage id="LOGOUT" />
                                    </div>
                                ),
                                key: 'logout',
                            },
                        ],
                    }}
                >
                    <Space>
                        <div css={dropdownWrapper}>
                            <MdMenu
                                style={{
                                    fontSize: '1.2rem',
                                    color: '#6e6e6e',
                                }}
                            />
                            <span>
                                <FormattedMessage
                                    id="HEADER_HELLO"
                                    values={{
                                        name: username,
                                    }}
                                />
                            </span>
                        </div>
                    </Space>
                </Dropdown>
            </div>
        </section>
    );
};

const myPage = css`
    margin-left: 15px;
`;

const dropdownWrapper = css`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3px;
`;

const logout = css`
    display: flex;
    align-items: center;
    color: #6e6e6e;
    margin-left: 10px;
    border-radius: 20px;
    transition: background-color 0.2s ease;
    padding: 5px;
    cursor: pointer;
    text-align: center;
    svg {
        margin-right: 4px;
        font-size: 18px;
    }
    &:hover {
        background-color: #ffffff37;
    }
`;

const mainMenuWrap = css`
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    a {
        color: #5e6278;
        border-radius: 15px;
        padding: 10px 15px;
        &:hover {
            color: #1890ff;
            background-color: #ffffff37;
            transition: background-color color 0.6s ease;
        }
    }
`;

export default memo(ManageMentMenu);
