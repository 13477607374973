import { useDrag, useDrop } from 'react-dnd';

interface IProp {
    index: number;
    move: (drgaIndex: number, hoverIndex: number) => void;
    children: any;
    type: string;
}
export default function DraggableWrapper({ index, move, children, type }: IProp) {
    const [collected, drag, dragPreview] = useDrag<any, any, any>({
        type,
        item: { type, index },
    });

    const [, drop] = useDrop({
        accept: type,
        hover: (item: any) => {
            if (item.index === index) {
                return null;
            }

            move(item.index, index);

            item.index = index; // item is mutable
        },
    });
    return collected.isDragging ? <div ref={dragPreview} /> : <div ref={(node) => drag(drop(node))}>{children}</div>;
}
