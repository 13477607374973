import { handleActions } from 'redux-actions';
import { MyData } from './action';

const initState: AccountStore = {
    account: {
        userId: '',
        username: '',
        _id: '',
        id: '',
        isMaster: false,
        name: '',
    },
};

export default handleActions(
    {
        init: (state) => {
            return state;
        },
        [MyData.Request]: () => {
            return initState;
        },
        [MyData.Success]: (_, { payload }: { payload: AccountStore }) => {
            return payload;
        },
        [MyData.Failure]: () => initState,
    },
    initState,
);
