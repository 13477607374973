import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useMainProjectList } from 'hooks';
import { Link } from 'react-router-dom';
import { ENV_TYPE, TEST_ENV } from './constants';
import NoResult from 'components/common/NoResult';
import LoadingSpin from 'components/utils/LoadingSpin';
import { noImage } from 'icons';
import { EGameState } from '@gamebase-web-ops/game';
import GameSearch from './GameSearch';
import { Tabs, TabsProps } from 'antd';
import { EnvColor } from 'styles/common';
export default function ProjectList() {
    const [environment, setEnvironment] = useState<ENV_TYPE>('qa');
    const [gameState, setGameState] = useState<EGameState | 'all'>('all');
    const [gameSearch, setGameSearch] = useState<string>('');
    const { groupByEnvProjectList, searchProjectList, isLoading } = useMainProjectList({
        gameState,
        gameSearch,
        environment,
    });
    const handleChangeState = (activeKey: string) => {
        setGameState(activeKey === 'all' ? activeKey : Number(activeKey));
    };
    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setGameSearch(value);
    };

    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'production') {
            setEnvironment('prod');
        }
    }, [groupByEnvProjectList]);

    if (isLoading) {
        return <LoadingSpin />;
    }
    const GameList =
        groupByEnvProjectList[environment].length === 0 ? (
            <NoResult
                description="게임 정보가 존재하지 않습니다. 게임베이스 Core팀 Web파트로 문의 주세요."
                status="noList"
            />
        ) : (
            <>
                <GameSearch onChangeSearch={handleChangeSearch} />
                <div css={projectListWrap}>
                    {searchProjectList?.map(({ id, name, game }) => (
                        <Link key={id} css={card} to={`/project/dashboard?projectId=${id}`}>
                            <div css={imgWrap}>
                                {game?.logoImageUrl ? (
                                    <img src={game.logoImageUrl} />
                                ) : (
                                    <img css={noImg} src={noImage} alt={`${name} page`} />
                                )}
                            </div>
                            <div>{name}</div>
                        </Link>
                    ))}
                </div>
            </>
        );

    const gameListItems: TabsProps['items'] = [
        {
            label: '전체',
            key: 'all',
            children: GameList,
        },
        {
            label: '운영중',
            key: String(EGameState.OPERATE),
            children: GameList,
        },
        {
            label: '개발중',
            key: String(EGameState.DEVELOPE),
            children: GameList,
        },
        {
            label: '피벗',
            key: String(EGameState.PIVOT),
            children: GameList,
        },
    ];

    return (
        <div css={wrap}>
            <div css={titleWrap}>
                {environment === 'prod' ? (
                    <p css={title}>{environment.toUpperCase()}</p>
                ) : (
                    <div css={tabWrap}>
                        {TEST_ENV.map((env) => (
                            <button
                                key={env}
                                onClick={() => setEnvironment(env)}
                                css={tabButton(env === environment, env)}
                            >
                                {env.toUpperCase()}
                            </button>
                        ))}
                    </div>
                )}
            </div>
            <Tabs defaultActiveKey="all" items={gameListItems} type="card" onChange={handleChangeState} />
        </div>
    );
}

const noImg = css`
    width: 80%;
    height: auto;
    margin: 0 auto;
`;

const title = css`
    text-align: center;
    color: ${EnvColor.prod};
    font-size: 38px;
    font-weight: 600;
`;

const titleWrap = css`
    width: 500px;
    margin: 0 auto 35px;
`;

const tabButton = (select: boolean, env?: string) => css`
    cursor: pointer;
    width: 40%;
    color: #c9d4ff;
    border: 2px solid #c9d4ff;
    font-size: 30px;
    padding: 5px 0;
    border-radius: 10px;
    text-align: center;
    background-color: #ffffff;
    ${select
        ? css`
              border: none;
              color: #ffffff;
              background-color: ${EnvColor[env || 'common']};
              box-shadow: 8px 10px 28px -9px rgba(97, 123, 181, 0.7);
              -webkit-box-shadow: 8px 10px 28px -9px rgba(97, 123, 181, 0.7);
              -moz-box-shadow: 8px 10px 28px -9px rgba(97, 123, 181, 0.7);
          `
        : css`
              :hover {
                  color: #5176ff;
                  border: 2px solid #5176ff;
              }
          `};
    > span {
        padding: 10px 0;
        display: block;
    }
`;

const tabWrap = css`
    display: flex;
    justify-content: space-between;
`;

const imgWrap = css`
    position: relative;
    width: 15%;
    /* margin: 10px auto 5px; */
    transition: transform 0.3s;
    &:after {
        display: block;
        content: '';
        padding-bottom: 100%;
    }
    img {
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const card = css`
    display: flex;
    align-items: center;
    padding: 10px 20px;
    height: 75px;
    gap: 20px;
    color: #5e6087;
    font-size: 16px;
    border-radius: 10px;
    text-align: center;
    background-color: #ffffff;
    margin-bottom: 20px;
    box-shadow: 8px 10px 12px -9px rgba(111, 140, 204, 0.4);
    border: 3px solid #ffffff;
    transition: border color 0.2s;
    :hover {
        border: 3px solid #abb2ff;
        color: #4c56eb;
        box-shadow: 8px 10px 28px -9px rgba(111, 140, 204, 0.6);
        > div {
            transform: scale(1.1);
        }
    }
    > span {
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        word-break: break-word;
    }
`;

const projectListWrap = css`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px 5%;
`;

const wrap = css`
    width: 70%;
    margin: 0 auto;
    max-width: 1100px;
    min-width: 700px;
    padding: 150px 0 120px;
    min-height: 100vh;
`;
