import { AUTH_CHECK_MENU_LIST } from 'constants/constants';
import { useMemo, useCallback } from 'react';
import { EServiceAuthLevel } from '@gamebase-web-ops/authorization/lib/v2';
import { MenuProps } from 'antd';

export default function useMenuItems(auths?: IAuthCheck[] | undefined) {
    type MenuItem = Required<MenuProps>['items'][number];

    const getItem = (
        label: React.ReactNode,
        key: React.Key,
        children?: MenuItem[],
        type?: 'group' | 'divider',
        disabled?: boolean,
    ): MenuItem => {
        return {
            key,
            children,
            label,
            type,
            disabled,
        } as MenuItem;
    };

    const levelCheck = useMemo(() => {
        return auths?.reduce((acc: string[] | undefined, cur) => {
            if (cur?.level === EServiceAuthLevel.READ && cur?.service) {
                acc?.push(cur?.service);
                return acc;
            }
        }, []);
    }, [auths]);

    const authCheck = useMemo(() => {
        return AUTH_CHECK_MENU_LIST?.filter((authMenu) => !levelCheck?.includes(authMenu));
    }, [levelCheck]);

    const authCheckMenuList = useCallback(
        (subMenu: { key: string; desc: unknown; link: string }[]) => {
            return subMenu?.reduce((acc: { key: string; desc: unknown; link: string }[], cur) => {
                if (!authCheck?.includes(cur.key)) {
                    acc.push({ ...cur, key: cur.key });
                }
                return acc;
            }, []);
        },
        [authCheck],
    );

    return { getItem, authCheckMenuList };
}
