import { useState } from 'react';
import { css } from '@emotion/react';
import { useMainProjectList } from 'hooks';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ENV_TYPE, TEST_ENV_TYPE } from 'components/projectList/constants';
import LoadingSpin from 'components/utils/LoadingSpin';
import { noImage } from 'icons';
import { Dropdown, MenuProps, Space } from 'antd';
import { ProjectDetailDto } from '@gamebase-web-ops/project';
import { DownOutlined } from '@ant-design/icons';
interface IProp {
    projectDetail: ProjectDetailDto;
}
export default function HeaderGameList({ projectDetail }: IProp) {
    const { pathname } = useLocation();
    const env = projectDetail.environment;
    const testEnvironment = env !== TEST_ENV_TYPE.PROD;
    const [environment, setEnvironment] = useState<ENV_TYPE>('qa');
    const [gameSearch, setGameSearch] = useState<string>('');
    const { groupByEnvProjectList, isLoading } = useMainProjectList({
        gameState: 'all',
        gameSearch,
        environment,
    });

    if (isLoading) {
        return <LoadingSpin />;
    }

    const group = (env: ENV_TYPE) => {
        return groupByEnvProjectList[env]?.map(({ name, id, game }) => {
            return {
                label: (
                    <Link key={id} css={card} to={`${pathname}?projectId=${id}`}>
                        <div css={imgWrap}>
                            {game?.logoImageUrl ? (
                                <img src={game.logoImageUrl} />
                            ) : (
                                <img src={noImage} alt={`${name} page`} />
                            )}
                        </div>
                        <div>{name}</div>
                    </Link>
                ),
                key: id,
            };
        });
    };

    const items: MenuProps['items'] = testEnvironment
        ? [
              { key: 'dev', type: 'group', label: 'DEV', children: group('dev') },
              { key: 'qa', type: 'group', label: 'QA', children: group('qa') },
          ]
        : [{ key: 'prod', type: 'group', label: 'PROD', children: group('prod') }];

    const GameList = () => {
        return (
            <Dropdown overlayClassName="gamelist" menu={{ items }} placement="bottom" arrow={{ pointAtCenter: true }}>
                <a css={projectListWrap}>
                    <Space>
                        {projectDetail?.name}
                        <DownOutlined />
                    </Space>
                </a>
            </Dropdown>
        );
    };

    return <GameList />;
}

const imgWrap = css`
    width: 25px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    img {
        width: 100%;
    }
`;

const card = css`
    display: flex;
    align-items: center;
    color: #000000;
`;

const projectListWrap = css`
    color: #464c6a;
    font-size: 18px;
    border: 1px solid #ffffff;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 4px 20px 4px 10px;
    margin-left: 10px;
`;
