import { css } from '@emotion/react';
import { Button } from 'antd';
import { PackageNameDtoKeys } from 'dto/package.dto';
import { useItemPackage, useLang } from 'hooks';
import { useEffect } from 'react';
import ItemPackageSearch from './ItemPackageSearch';

interface IProp {
    projectId: string;
    mailPackageId?: string;
}

export default function ItemPackageFormList({ projectId, mailPackageId }: IProp) {
    const { packageDetail, packageDetailFetch, setPackageDetail } = useItemPackage(projectId);
    const { projectLang } = useLang(projectId);

    useEffect(() => {
        if (mailPackageId) {
            packageDetailFetch(mailPackageId);
        }
    }, [packageDetailFetch, mailPackageId]);
    return (
        <div>
            <ItemPackageSearch projectId={projectId} onAddItem={(pack) => packageDetailFetch(pack._id)} />
            {packageDetail && (
                <section css={packageWrapper}>
                    <section>
                        <div css={packageName}>{packageDetail.name[projectLang as PackageNameDtoKeys]}</div>
                        <div>
                            {packageDetail.items?.map((item) => (
                                <div key={item._id}>
                                    {item[projectLang]} [{item.Value}]
                                </div>
                            ))}
                        </div>
                        <input type="hidden" name="mailPackage" value={packageDetail?._id} />
                    </section>
                    <section css={packageDeleteWrapper}>
                        <Button onClick={() => setPackageDetail(undefined)} type="primary" danger>
                            삭제
                        </Button>
                    </section>
                </section>
            )}
        </div>
    );
}

const packageWrapper = css`
    display: flex;
    margin-top: 16px;
`;

const packageName = css`
    font-size: 14px;
    font-weight: 500;
`;

const packageDeleteWrapper = css`
    margin-left: 16px;
`;
