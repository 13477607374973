import { css } from '@emotion/react';
import { VideoUpdateRequest } from '@gamebase-web-ops/video';
import { Button, Modal, Spin } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { useModal, useQueries } from 'hooks';
import moment from 'moment';
import { useEffect, useState } from 'react';
import useCommunity from 'hooks/community/useCommunity';
import { CommunityDto } from '@gamebase-web-ops/community';
import CommunityEditor from './CommunityEditor';

interface VideoListProps {
    language: string;
}

export default function CommunityList({ language }: VideoListProps) {
    const { projectId } = useQueries();
    const { visible, openModal, closeModal } = useModal();
    const {
        communityList,
        isLoading,
        isError,
        refetch,
        createMutate,
        createdSuccess,
        createdLoading,
        uploadImage,
        // updateMutate,
        // updatedSuccess,
        // updatedLoading,
        deleteMutate,
    } = useCommunity({ projectId, language });
    const { visible: modifyVisible, openModal: modifyOpenModal, closeModal: modifyCloseModal } = useModal();
    const [modifyData, setModifyData] = useState<Omit<VideoUpdateRequest, 'projectId'>>();

    useEffect(() => {
        if (createdSuccess) {
            closeModal();
        }
    }, [createdSuccess, closeModal]);

    // useEffect(() => {
    //     if (updatedSuccess) {
    //         modifyCloseModal();
    //     }
    // }, [updatedSuccess, modifyCloseModal]);

    const handleSubmit = (param: any) => {
        createMutate({ ...param, language, projectId });
    };

    // const handleSubmitUpdate = (param) => {
    //     updateMutate({ ...modifyData, ...param, projectId });
    // };

    const handleClickOpenModal = (param: Omit<VideoUpdateRequest, 'projectId'>) => {
        setModifyData(param);
        modifyOpenModal();
    };

    const handleClickDelete = (id: string) => {
        Modal.confirm({
            title: '리스트에서 등록되어 있는 데이터를 삭제합니다. 정말 삭제 하시겠습니까?',
            onOk: () => deleteMutate({ id, projectId }),
        });
    };

    const columns: ColumnsType<CommunityDto> = [
        {
            title: '제목',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '이미지',
            dataIndex: 'image',
            key: 'image',
            render(_, { image }) {
                return (
                    <div>
                        <img css={imageCss} src={image} />
                    </div>
                );
            },
        },
        {
            title: '링크',
            dataIndex: 'url',
            key: 'url',
        },
        {
            title: '순서',
            dataIndex: 'seq',
            key: 'seq',
        },
        {
            title: '상태',
            dataIndex: 'state',
            key: 'state',
        },
        {
            title: '작성자',
            dataIndex: 'createdBy',
            key: 'createdBy',
        },
        {
            title: '생성일',
            render(_, { createdAt }) {
                return moment(createdAt).format('YYYY-MM-DD HH:mm:ss');
            },
        },
        {
            title: '수정자',
            key: 'updatedBy',
            dataIndex: 'updatedBy',
        },
        {
            title: '수정일',
            key: 'updatedAt',
            render(_, { updatedAt }) {
                return moment(updatedAt).format('YYYY-MM-DD HH:mm:ss');
            },
        },

        {
            title: '관리',
            render(_, record) {
                return (
                    <div css={buttonWrap}>
                        {/* <Button
                            onClick={() => {
                                handleClickOpenModal(record);
                            }}
                        >
                            수정
                        </Button> */}
                        <Button
                            onClick={(o) => {
                                handleClickDelete(record.id);
                            }}
                            type="primary"
                            danger
                        >
                            삭제
                        </Button>
                    </div>
                );
            },
            key: '관리',
        },
    ];

    return (
        <>
            <header>
                <nav css={navWrapper}>
                    <Button onClick={openModal} type="primary">
                        등록
                    </Button>
                </nav>
            </header>
            <Spin css={spinStyle} spinning={false}>
                <CommunityEditor
                    isVisible={visible}
                    onCloseModal={closeModal}
                    onSubmit={handleSubmit}
                    projectId={projectId}
                    isLoading={createdLoading}
                    language={language}
                    uploadImage={uploadImage}
                ></CommunityEditor>
                <Table columns={columns} dataSource={communityList} />
            </Spin>
        </>
    );
}
const navWrapper = css`
    position: relative;
    button {
        position: absolute;
        top: -70px;
    }
    button:first-of-type {
        right: 165px;
    }
    button:last-of-type {
        right: 0;
    }
`;

const buttonWrap = css`
    display: flex;
`;

const spinStyle = css`
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 190px;
    background-color: #ffffff;
    border-radius: 10px;
    font-size: 1.5em;
`;

const imageCss = css`
    max-width: 330px;
`;
