export const emptyDeleteKey = (object: Record<string, any>) => {
    const cloneObject = JSON.parse(JSON.stringify(object));
    const keys = Object.keys(cloneObject);

    keys.map((key) => {
        if (cloneObject[key] === '') delete cloneObject[key];
        if (cloneObject[key] === undefined) delete cloneObject[key];
        if (cloneObject[key] === null) delete cloneObject[key];
    });

    return cloneObject;
};

export const fillDefaultLanguage = (object: Record<string, any>, languages: string[], defaultLang: string) => {
    const cloneObject = JSON.parse(JSON.stringify(object));

    languages.map((it) => {
        if (cloneObject[it]) return;
        cloneObject[it] = cloneObject[defaultLang];
    });

    return cloneObject;
};
