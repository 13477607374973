import { Form, Input, Button } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useAuth, useFormRules } from 'hooks';
import { css } from '@emotion/react';

const LoginForm = () => {
    const { emailLoginRequest } = useAuth();
    const { formValidator } = useFormRules();

    return (
        <Form name="normal_login" css={formWrapper} onFinish={emailLoginRequest} labelCol={{ span: 8 }}>
            <Form.Item name="userId" rules={formValidator.getRules('account_id')} css={item}>
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="User ID" />
            </Form.Item>
            <Form.Item name="password" rules={formValidator.getRules('account_password')} css={item}>
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>
            <Form.Item css={item}>
                <Button type="primary" htmlType="submit" css={loginFromButton}>
                    Log in
                </Button>
            </Form.Item>
        </Form>
    );
};

const item = css`
    margin-bottom: 10px;
    .ant-input-affix-wrapper {
        padding: 6px 11px;
    }
`;

const loginFromButton = css`
    width: 100%;
    height: 40px;
    margin-top: 10px;
`;

const formWrapper = css`
    width: 100%;
`;

export default LoginForm;
