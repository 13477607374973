import { assocPath } from './deepSetObject';

export const onSubmitCapture = (onSubmit: (obj: any) => void) => (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.persist();
    const formData = new FormData(e.currentTarget);

    const entries = formData.entries();

    const obj: any = {};

    if (e.currentTarget.enctype === 'multipart/form-data') {
        onSubmit(formData);
        return;
    }

    for (const item of entries) {
        const splitKey = item[0].split('.');
        if (splitKey.length === 1) {
            obj[item[0]] = item[1];
        } else {
            assocPath(splitKey, item[1], obj);
        }
    }
    if (onSubmit) {
        onSubmit(obj as any);
    }
};
