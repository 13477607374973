import { useCallback, useState } from 'react';
import { CodeRepository } from 'repository';
import { ProjectCodeDto, ProjectCodeAllRequest, EProjectCodeType } from '@gamebase-web-ops/code';

export default function useEventCode(projectId: string) {
    const [eventCodes, setEventCodes] = useState<ProjectCodeDto<string>[]>();

    const requestByProjectCode = useCallback(
        async (codeType: EProjectCodeType) => {
            const result = await CodeRepository.allProjectCodes<string>(
                new ProjectCodeAllRequest({
                    projectId,
                    codeType,
                }),
            );
            setEventCodes(result.result);
        },

        [projectId],
    );

    return {
        eventCodes,
        requestByProjectCode,
    };
}
