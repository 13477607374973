import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import configure from 'store/configure';
import { BrowserRouter } from 'react-router-dom';
import 'antd/dist/antd.css';
import './index.css';
import { errorHandler } from 'hooks/error/useError';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
window.React = React;

const store = configure();

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry(_: number, error: any) {
                errorHandler.errorHandle && errorHandler.errorHandle({ ...error, reason: error });
                return false;
            },
            refetchOnWindowFocus: false,
        },
        mutations: {
            onError: (error: any) => {
                errorHandler.errorHandle && errorHandler.errorHandle({ ...error, reason: error });
            },
        },
    },
});

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />

                <App />
            </QueryClientProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'),
);
