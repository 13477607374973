import qs from 'qs';
import axios, { AxiosError, AxiosResponse, AxiosRequestConfig, AxiosInstance } from 'axios';
import { classValidate } from 'utils/validation';
import { API_URL } from 'constants/constants';
import RequestAccess from './ReqeustAccess';
import { HttpResponse, IHttpClient, IQuery, IConfig, IBody } from '@gamebase-web/common';
import { notificationPush } from 'utils/notificationUtils';
const instance = axios.create({
    baseURL: API_URL,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    withCredentials: true,
});

const interceptorsRequestFulfilled = (config: AxiosRequestConfig) => {
    const token = RequestAccess.getInstance().getAccessToken();

    return {
        ...config,
        headers: {
            Authorization: token && `Bearer ${token}`,
            'x-site-lang': localStorage.getItem('lang') || 'kr',
        },
    };
};

const interceptorsResponseFulfilled = async (res: AxiosResponse) => {
    if (res.status >= 200 && res.status < 300) {
        if (res.headers['x-percent-token']) {
            const token = res.headers['x-percent-token'];
            RequestAccess.getInstance().setAccessToken(token);
            if (res.status === 204) {
                let data = undefined;
                try {
                    if (res.config.data) {
                        if (res.config.data instanceof FormData) {
                            data = res.config.data;
                        } else {
                            data = JSON.parse(res.config.data);
                        }
                    }
                } catch {
                    data = undefined;
                }
                const result = await instance.request({ ...res.config, data });
                return result;
            }
        }
        return res.data;
    }
    return Promise.reject(res.data);
};
const interceptorsResponseRejected = async (error: AxiosError) => {
    if (window.location.pathname === '/login') {
        throw new Error(error.response?.data?.message);
    }
    if (error.response?.status === 401) {
        notificationPush({ status: 'error', message: '로그인이 만료되었습니다. 로그아웃합니다.' });
        window.location.href = '/login';
    }
    throw new Error(error.response?.data?.message);
};

instance.interceptors.request.use(interceptorsRequestFulfilled);
instance.interceptors.response.use(interceptorsResponseFulfilled, interceptorsResponseRejected);

class AxiosHttpClient implements IHttpClient {
    readonly instance: AxiosInstance;
    constructor() {
        this.instance = axios.create({
            baseURL: API_URL,
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
            withCredentials: true,
        });
        this.bindInterceptor();
    }

    getData = (res: AxiosResponse) => {
        if (res.config.data) {
            if (res.config.data instanceof FormData) {
                return res.config.data;
            }
            return JSON.parse(res.config.data);
        }
        return undefined;
    };
    responseRejectedInterceptor = async (error: AxiosError) => {
        if (window.location.pathname === '/login') {
            throw new Error(error.response?.data?.message);
        }
        if (error.response?.status === 401) {
            notificationPush({ status: 'error', message: '로그인이 만료되었습니다. 로그아웃합니다.' });
            window.location.href = '/login';
        }
        throw new Error(error.response?.data?.message);
    };

    requestFulfilledInterceptor = (config: AxiosRequestConfig) => {
        const token = RequestAccess.getInstance().getAccessToken();

        return {
            ...config,
            headers: {
                Authorization: token && `Bearer ${token}`,
                'x-site-lang': localStorage.getItem('lang') || 'kr',
            },
        };
    };
    responseInterceptor = async (res: AxiosResponse) => {
        if (res.status >= 200 && res.status < 300) {
            if (res.headers['x-percent-token']) {
                const token = res.headers['x-percent-token'];
                RequestAccess.getInstance().setAccessToken(token);
                if (res.status === 204) {
                    const data = this.getData(res);
                    const result = await this.instance.request({ ...res.config, data });
                    return result;
                }
            }
            return res;
        }
        return Promise.reject(res.data);
    };

    bindInterceptor() {
        this.instance.interceptors.request.use(this.requestFulfilledInterceptor);
        this.instance.interceptors.response.use(this.responseInterceptor, this.responseRejectedInterceptor);
    }

    get(args: { url: string; query?: IQuery; config?: IConfig }): Promise<HttpResponse> {
        return this.instance.get(args.url, { params: args.query, ...(args.config ?? {}) });
    }

    post(args: {
        url: string;
        query?: IQuery | undefined;
        body?: IBody | undefined;
        config?: IConfig | undefined;
    }): Promise<HttpResponse> {
        return this.instance.post(args.url, args.body, { params: args.query, ...(args.config ?? {}) });
    }

    patch(args: {
        url: string;
        query?: IQuery | undefined;
        body?: IBody | undefined;
        config?: IConfig | undefined;
    }): Promise<HttpResponse> {
        return this.instance.patch(args.url, args.body, { params: args.query, ...(args.config ?? {}) });
    }

    delete(args: { url: string; query?: IQuery | undefined }): Promise<HttpResponse> {
        return this.instance.delete(args.url, { params: args.query });
    }
}

export const axiosClient = new AxiosHttpClient();

function validation(data?: object) {
    if (data) {
        const result = classValidate(data);
        if (!result.isSuccess) {
            throw Promise.reject(result);
        }
        return true;
    }
}

export async function get<T>(url: string, queryParam?: object) {
    if (queryParam) {
        await validation(queryParam);
    }
    const result = await instance.get<T, T>(url, { params: queryParam });

    return result;
}

export async function post<T>(...args: any) {
    const [url, body, params] = args;
    if (body) {
        await validation(body);
    }

    if (params) {
        await validation(params);
    }
    const result = await instance.post<T, T>(url, body, { params });

    return result;
}

export async function put<T>(...args: any) {
    const [url, body, params] = args;

    if (body) {
        await validation(body);
    }

    if (params) {
        await validation(params);
    }
    const result = await instance.put<T, T>(url, body, { params });

    return result;
}

export async function del<T>(...args: any) {
    const [url, params] = args;

    if (params) {
        await validation(params);
    }

    const result = await instance.delete<T, T>(url, { params });

    return result;
}

export async function patch<T>(...args: any) {
    const [url, body, params] = args;
    if (body) {
        await validation(body);
    }

    if (params) {
        await validation(params);
    }
    const result = await instance.patch<T, T>(url, body, { params });

    return result;
}
