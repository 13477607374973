import { CrossPromotionListRequest } from '@gamebase-web-ops/cross-promotion';
import { useQuery } from 'react-query';
import CrossPromotionRepository from 'repository/CrossPromotion.repository';
import { displayNotificationAfterCompareToBefore } from 'utils/crossPromotionState';

export const QUERY_KEY = 'cross-promotion-list';
const usePromotionList = (query: CrossPromotionListRequest) => {
    return useQuery(
        [QUERY_KEY, query],
        () => CrossPromotionRepository.list(new CrossPromotionListRequest({ ...query })),
        {
            isDataEqual: (oldData, newData): boolean => {
                if (oldData) {
                    oldData.result.data.map(({ id, state }) => {
                        const found = newData.result.data.find((newInfo) => newInfo.id === id);
                        if (found && state !== found.state) {
                            displayNotificationAfterCompareToBefore(state, found.state);
                        }
                    });
                }

                return false;
            },
        },
    );
};

export default usePromotionList;
