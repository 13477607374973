import { css } from '@emotion/react';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { NOTICE_STATE_TEXT } from 'constants/constants';
import { useFormatMessage, useLang, useQueries } from 'hooks';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { WebviewNoticeDto, EWebviewNoticeState } from '@gamebase-web-ops/notice';
import { findTargetElementByType } from 'utils/eventListener';
import { noticeStatusColor } from 'utils/color';

interface IProp {
    data: WebviewNoticeDto[];
    totalCount: number;
    currentPage: number;
    setPage: (page: number) => void;
    onAction?: (action: string, id: string) => void;
    hasWritePermission: boolean;
}

export default function WebviewTableView({
    data,
    totalCount,
    currentPage,
    setPage,
    onAction,
    hasWritePermission,
}: IProp) {
    const { projectId } = useQueries();
    const { projectLang } = useLang(projectId);
    const { messageFormatting } = useFormatMessage();

    const columns = useMemo<ColumnsType<WebviewNoticeDto>>(() => {
        return [
            {
                title: 'id',
                dataIndex: 'id',
                render(value) {
                    return <div style={{ maxWidth: '80px' }}>{value}</div>;
                },
            },
            {
                title: '작성자 (생성 날짜)',
                render(_, { createdAt, createdBy }) {
                    return (
                        <div>
                            {createdBy}
                            <br />({moment(createdAt).format('YYYY-MM-DD HH:mm:ss')})
                        </div>
                    );
                },
            },
            {
                title: '노출 기간',
                render(_, { startTime, endTime }) {
                    return (
                        <div>
                            {moment(startTime).format('YYYY-MM-DD HH:mm:ss')}
                            <br />
                            {moment(endTime).format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                    );
                },
            },
            {
                title: '우선 언어',
                dataIndex: 'defaultLanguage',
                render(defaultLanguage) {
                    return defaultLanguage || '없음';
                },
            },
            {
                title: '노출 언어',
                render(_, { titles }) {
                    const keys = Object.keys(titles);

                    return keys.join(' / ');
                },
            },
            {
                title: '상태',
                dataIndex: 'state',
                render(state: EWebviewNoticeState) {
                    return (
                        <div css={stateText} style={{ color: noticeStatusColor[state as EWebviewNoticeState] }}>
                            {NOTICE_STATE_TEXT[state as EWebviewNoticeState]}
                        </div>
                    );
                },
            },
            {
                title: '이미지',
                render(_, { mainImages, titles }) {
                    return (
                        <div>
                            <img css={mainImage} src={mainImages[projectLang]} />
                            <p css={mainTitles}>제목: {titles[projectLang]}</p>
                        </div>
                    );
                },
            },
            {
                title: messageFormatting('MANAGEMENT'),
                render(_, { id, state }) {
                    return (
                        <section css={actionWrapper}>
                            <Button data-type="action" data-value="confirm" data-id={id}>
                                {messageFormatting('DETAIL')}
                            </Button>
                            <Button data-type="action" data-value="copy" data-id={id} disabled={!hasWritePermission}>
                                {messageFormatting('COPY')}
                            </Button>
                            {state === EWebviewNoticeState.WRITE_SUCCESS ? (
                                <Button
                                    data-type="action"
                                    data-value="unpublish"
                                    data-id={id}
                                    danger
                                    type="primary"
                                    disabled={!hasWritePermission}
                                >
                                    중지
                                </Button>
                            ) : (
                                <Button
                                    data-type="action"
                                    data-value="publish"
                                    data-id={id}
                                    type="primary"
                                    disabled={!hasWritePermission}
                                >
                                    배포
                                </Button>
                            )}
                        </section>
                    );
                },
            },
        ];
    }, [projectLang, hasWritePermission]);

    const tableClickListener = useCallback(
        (e) => {
            const target = findTargetElementByType(e, 'action');
            if (target) {
                onAction && onAction(target.dataset.value as string, target.dataset.id as string);
            }
        },
        [onAction],
    );

    return (
        <section onClick={tableClickListener}>
            <Table
                columns={columns}
                dataSource={data.map((data) => ({
                    ...data,
                    key: data.id,
                }))}
                pagination={{ total: totalCount, current: currentPage, onChange: setPage, pageSize: 15 }}
            />
        </section>
    );
}

const stateText = css`
    white-space: nowrap;
`;

const mainTitles = css``;

const mainImage = css`
    max-width: 330px;
`;

const actionWrapper = css`
    display: flex;
    button + button {
        margin-left: 8px;
    }
`;
