import { useQuery } from 'react-query';
import { MaintenanceRepository } from 'repository';
import { MaintenanceScheduleListRequest } from '@gamebase-web-ops/maintenance';

export default function useMaintenanceListModel(projectId: string) {
    const queries = useQuery(['maintenanceList', projectId], () =>
        projectId
            ? MaintenanceRepository.listSchedule(new MaintenanceScheduleListRequest({ projectId, page: 1, limit: 50 }))
            : null,
    );

    return {
        ...queries,
    };
}
