import { ProjectList } from 'components';
import classNames from 'classnames/bind';
import styles from './ProjectServiceAuth.module.scss';
import Header, { HeaderDesc } from 'components/utils/Header';

const cx = classNames.bind(styles);

const ContentsMenu = () => {
    return (
        <div>
            <div className={cx('titleWrapper')}>
                <Header title="콘텐츠 메뉴 설정" customStyle />
            </div>
            <HeaderDesc desc="콘텐츠 메뉴 노출 여부를 설정할 수 있습니다." />
            <section className={cx('section')}>
                <ProjectList isProjectMenuList />
            </section>
        </div>
    );
};

export default ContentsMenu;
