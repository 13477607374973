import { Switch, Route } from 'react-router-dom';
import { PageAuthViewController } from 'provider';
import {
    Project,
    RolePage,
    GameCreatePage,
    CrossPromotionPage,
    AccountDetail,
    Account,
    AppAdsPage,
    DeployEnvPage,
} from 'pages';
import { COMMON_PROJECT_ID } from 'constants/constants';
import { EServiceAuthLevel, EServiceAuthName } from '@gamebase-web-ops/authorization/lib/v2';
import ContentsMenu from 'pages/admin/ContentsMenu';
import { CommonLayout } from 'components';

export default function CommonRoute() {
    return (
        <Switch>
            <CommonLayout>
                <Route
                    path="/common/siteManagement/contentsMenu"
                    render={() => (
                        // <PageAuthViewController
                        //     projectId={COMMON_PROJECT_ID}
                        //     service={EServiceAuthName.PROJECT}
                        //     level={EServiceAuthLevel.WRITE}
                        // >
                        <ContentsMenu />
                        // </PageAuthViewController>
                    )}
                />
                <Route
                    path="/common/siteManagement/project"
                    render={() => (
                        <PageAuthViewController
                            projectId={COMMON_PROJECT_ID}
                            service={EServiceAuthName.PROJECT}
                            level={EServiceAuthLevel.WRITE}
                        >
                            <Project />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/common/setting/account/:id"
                    render={() => (
                        <PageAuthViewController
                            projectId={COMMON_PROJECT_ID}
                            service="account"
                            level={EServiceAuthLevel.WRITE}
                        >
                            <AccountDetail />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    exact
                    path="/common/setting/account"
                    render={() => (
                        <PageAuthViewController
                            projectId={COMMON_PROJECT_ID}
                            service="account"
                            level={EServiceAuthLevel.WRITE}
                        >
                            <Account />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/common/setting/role"
                    render={() => (
                        <PageAuthViewController
                            projectId={COMMON_PROJECT_ID}
                            service={EServiceAuthName.PROJECT}
                            level={EServiceAuthLevel.WRITE}
                        >
                            <RolePage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/common/commonManagement/game"
                    render={() => (
                        <PageAuthViewController
                            projectId={COMMON_PROJECT_ID}
                            service={EServiceAuthName.GAME}
                            level={EServiceAuthLevel.WRITE}
                        >
                            <GameCreatePage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/common/commonManagement/deployEnv"
                    render={() => (
                        <PageAuthViewController
                            projectId={COMMON_PROJECT_ID}
                            service={EServiceAuthName.GAME_BUILD_ENV}
                            level={EServiceAuthLevel.WRITE}
                        >
                            <DeployEnvPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    exact
                    path="/common/commonManagement/cross-promotion"
                    render={() => (
                        <PageAuthViewController
                            projectId={COMMON_PROJECT_ID}
                            level={EServiceAuthLevel.WRITE}
                            service={EServiceAuthName.CROSS_PROMOTION}
                        >
                            <CrossPromotionPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    exact
                    path={['/common/appAds/app-ads', '/common/appAds/app-ads/:publisher']}
                    render={() => (
                        <PageAuthViewController
                            projectId={COMMON_PROJECT_ID}
                            level={EServiceAuthLevel.WRITE}
                            service={EServiceAuthName.APP_ADS}
                        >
                            <AppAdsPage />
                        </PageAuthViewController>
                    )}
                />
            </CommonLayout>
        </Switch>
    );
}
