import { css } from '@emotion/react';
import { Button } from 'antd';
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from 'react-icons/fc';

interface IProps {
    onSuccessGoogleLogin?: (payload: GoogleLoginPayload) => void;
}

const GoogleLoginButton: React.FC<IProps> = ({ onSuccessGoogleLogin }) => {
    const signIn = useGoogleLogin({
        onSuccess: async (res) => {
            await onSuccessGoogleLogin?.({
                accessToken: res.access_token,
                email: '',
            });
        },
        scope: [
            'https://www.googleapis.com/auth/userinfo.profile',
            'https://www.googleapis.com/auth/drive',
            'https://www.googleapis.com/auth/spreadsheets',
        ].join(' '),
    });

    return (
        <Button size="large" css={loginButton} onClick={() => signIn()}>
            <div css={wrapper}>
                <FcGoogle size={26} />
                <span css={spanCss}>Google Login</span>
            </div>
        </Button>
    );
};

const wrapper = css`
    display: flex;
    justify-content: center;
`;

const spanCss = css`
    margin-left: 10px;
`;

const loginButton = css`
    width: 100%;
    text-align: center;
`;

export default GoogleLoginButton;
