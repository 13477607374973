import { Layout } from 'antd';
import HeaderMenu from './HeaderMenu';
import classNames from 'classnames/bind';
import styles from './Layout.module.scss';
import { useProjectDetail, useQueries } from 'hooks';
const cx = classNames.bind(styles);

interface IProp {
    children: any;
}

const MainLayout = ({ children }: IProp) => {
    const { projectId } = useQueries();
    const { projectDetail } = useProjectDetail(projectId);
    return (
        <Layout className={projectDetail && projectDetail.environment} style={{ flexDirection: 'column' }}>
            <HeaderMenu projectDetail={projectDetail} />
            <section className={cx('main')}>{children}</section>
        </Layout>
    );
};

export default MainLayout;
