export const MyData = {
    Request: '@account/mydata/request',
    Success: '@account/mydata/success',
    Failure: '@account/mydata/failure',
};

export const getMyDataRequestAction = (payload: IStoreRequest) => ({
    type: MyData.Request,
    payload,
});
