import QueryString from 'qs';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type IQueryTypes<T extends {}> = T & {
    projectId: string;
};

function useQueries() {
    const { search } = useLocation();

    const queries = QueryString.parse(search, {
        ignoreQueryPrefix: true,
    }) as IQueryTypes<IQueryTypes<any>>;

    const projectIdNumber = queries.promotionProjectId || null;

    return useMemo(
        () => ({
            ...queries,
            promotionProjectId: projectIdNumber || '',
        }),
        [projectIdNumber, queries],
    );
}

export default useQueries;
