import { IEventLog, ListEventLogRequest } from '@gamebase-ops/interfaces/gameUser';
import { useCallback, useRef, useState } from 'react';
import { gameUserRepository } from 'repository/User.repository';

interface IParams {
    projectId: string;
    pid?: string;
}

export default function useUserLogs({ projectId }: IParams) {
    const payload = useRef<ListEventLogRequest>();
    const [logs, setLogs] = useState<Array<IEventLog>>([]);
    const [eventNames, setEventNames] = useState<Array<string>>([]);
    const [pending, setPending] = useState(false);
    const [hasMore, setHasMore] = useState<boolean>();

    const request = useCallback(async (param: ListEventLogRequest) => {
        if (!payload.current) return;
        setPending(true);
        if (param.page === 1) setLogs([]);
        const result = await gameUserRepository.listEventLog({ query: payload.current });
        const eventNames = new Set(result.map((log) => log.event_name));
        setPending(false);
        return {
            data: result,
            eventNames: [...eventNames],
            hasMore: payload.current && result.length >= payload.current.limit,
        };
    }, []);

    const download = useCallback(async () => {
        if (!payload.current) return null;
        const keys = Object.keys(payload.current);
        const param: any = {};
        keys.filter((key) => payload.current && payload.current[key as keyof ListEventLogRequest]).map(
            (key) => (param[key] = payload.current && payload.current[key as keyof ListEventLogRequest]),
        );
        await gameUserRepository.downloadEventLog({ query: payload.current });
    }, []);

    const downloadByFullArgument = useCallback(async (submitResult) => {
        if (submitResult) {
            await gameUserRepository.downloadEventLog({ query: submitResult });
        }
    }, []);

    const search = useCallback(
        async (params: Omit<ListEventLogRequest, 'projectId'>) => {
            payload.current = { ...params, projectId, page: 1 };
            const keys = Object.keys(params);
            const param: any = {};
            keys.filter((key) => params[key as keyof Omit<ListEventLogRequest, 'projectId'>]).map(
                (key) => (param[key] = params[key as keyof Omit<ListEventLogRequest, 'projectId'>]),
            );
            const result = await request(payload.current);
            setEventNames(result?.eventNames || []);
            setLogs(result?.data || []);
            setHasMore(result?.hasMore);
        },
        [request, projectId],
    );

    const nextPageRequest = useCallback(async () => {
        if (pending) return;

        if (payload.current) {
            const nextPayload: any = {
                ...payload.current,
            };
            if (payload.current.page !== undefined) nextPayload.page = payload.current.page + 1;
            payload.current = nextPayload;
            if (!payload.current) return;
            const result = await request(payload.current);
            if (result) {
                setEventNames((prev) => {
                    const sets = new Set([...prev, ...result.eventNames]);
                    return [...sets];
                });
                setLogs((prev) => [...prev, ...result.data]);
                setHasMore(result.hasMore);
            }
        }
    }, [request, pending]);

    return { search, logs, pending, nextPageRequest, download, hasMore, eventNames, downloadByFullArgument };
}
