import { ItemDto, ItemListRequest } from '@gamebase-web-ops/item';
import { useCallback, useState } from 'react';
import { ItemRepository } from 'repository';

export default function useItem({ projectId }: ProjectIdQuery) {
    const limit = 10;
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [itemList, setItemList] = useState<ItemDto[]>([]);
    const [lastPage, setLastPage] = useState(1);
    const [keyword, setKeyword] = useState<string>();

    const requestByKeyword = useCallback(
        async (keyword?: string) => {
            const {
                result: { totalCount, lastPage, data },
            } = await ItemRepository.list(
                new ItemListRequest({
                    page: 1,
                    limit,
                    searchWord: keyword,
                    projectId,
                }),
            );
            setItemList(data);
            setTotalCount(totalCount);
            setPage(1);
            setLastPage(lastPage);
            setKeyword(keyword);
        },
        [projectId, limit],
    );

    const requestByPage = useCallback(
        async (targetPage: number) => {
            const {
                result: { totalCount, lastPage, data },
            } = await ItemRepository.list(
                new ItemListRequest({
                    page: targetPage,
                    limit,
                    projectId,
                    searchWord: keyword,
                }),
            );
            setItemList(data.map((item) => ({ ...item, key: item.itemId })));
            setTotalCount(totalCount);
            setLastPage(lastPage);
            setPage(targetPage);
        },
        [keyword, limit, projectId],
    );

    const requestItemList = useCallback(async () => {
        const {
            result: { totalCount, lastPage, data },
        } = await ItemRepository.list(
            new ItemListRequest({
                page: 1,
                limit,
                projectId,
                searchWord: '',
            }),
        );
        setItemList(data.map((item) => ({ ...item, key: item.itemId })));
        setTotalCount(totalCount);
        setLastPage(lastPage);
    }, [projectId]);

    return {
        requestByKeyword,
        limit,
        page,
        itemList,
        totalCount,
        lastPage,
        keyword,
        requestByPage,
        requestItemList,
    };
}
