import classNames from 'classnames/bind';
import styles from './Select.module.scss';

const cx = classNames.bind(styles);

const Select: React.FC<React.SelectHTMLAttributes<HTMLSelectElement>> = (props) => {
    return (
        <select {...props} className={cx('wrapper')}>
            {props.children}
        </select>
    );
};

export default Select;
