import { useAccount } from 'hooks';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import ManageMentMenu from './ManageMentMenu';
import { noLogoImage } from 'icons';
import { css } from '@emotion/react';
import { logoImg } from 'icons';
import { EnvColor, EnvHeaderColor } from 'styles/common';
import { ProjectDetailDto } from '@gamebase-web-ops/project';
import HeaderGameList from './HeaderGameList';
interface IProp {
    projectDetail?: ProjectDetailDto | undefined;
}

const HeaderMenu = ({ projectDetail }: IProp) => {
    const { account } = useAccount();

    return (
        <header css={header(projectDetail && projectDetail.environment)}>
            <Link to="/" css={logo}>
                <img src={logoImg} alt="111% 로고" />
            </Link>
            <section css={menuWrapper}>
                <div css={headerLeft}>
                    {projectDetail && (
                        <>
                            <span css={headerEnv(projectDetail && projectDetail.environment)}>
                                {projectDetail.environment.toUpperCase()}
                            </span>
                            <span css={logoImage}>
                                {projectDetail.game.logoImageUrl ? (
                                    <img src={projectDetail.game.logoImageUrl} alt="game logo image" />
                                ) : (
                                    <img src={noLogoImage} alt="no logo image" />
                                )}
                            </span>
                            <HeaderGameList projectDetail={projectDetail} />
                        </>
                    )}
                </div>
                <ManageMentMenu username={account.account.name} isAdmin={account.account.isMaster || false} />
            </section>
        </header>
    );
};
const logo = css`
    height: auto;
    padding: 0 20px;
    width: 240px;
    display: block;
    text-align: center;
    img {
        width: 150px;
        padding: 3% 3% 2% 4%;
        filter: invert(42%) sepia(6%) saturate(1023%) hue-rotate(194deg) brightness(99%) contrast(86%);
    }
`;
const logoImage = css`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e9e9e9;
    overflow: hidden;
    margin-right: 5px;
    background-color: #ffffff;
    img {
        width: 100%;
    }
`;

const headerEnv = (env?: string) => css`
    margin-right: 15px;
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    color: ${EnvColor[env || 'common']};
`;

const headerLeft = css`
    display: flex;
    align-items: center;
`;

const menuWrapper = css`
    display: flex;
    justify-content: space-between;
    padding: 18px 32px 17px 28px;
    width: calc(100% - 240px);
    height: 100%;
`;

const header = (env?: string) => css`
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 2;
    background-color: #ffffff;
    border-bottom: 1px solid #f5f5f5;
    width: 100%;
    background-color: ${EnvHeaderColor[env || 'common']};
`;

export default memo(HeaderMenu);
