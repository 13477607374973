import { Route, Switch } from 'react-router-dom';
import { PageAuthViewController } from 'provider';
import {
    ItemPage,
    MailPage,
    ProjectDashBoard,
    UserPage,
    NoticePage,
    // MailDetailPage,
    AccountMerge,
    GameSystemPage,
    LogPage,
    VoidedPurchasePage,
    OrderPage,
    BlockUserPage,
    WebviewEventPage,
    PolicyPage,
    PromotionPage,
    PushPage,
    CouponPage,
    RealTimeServerPage,
    IngameServerPage,
    MetaDataPage,
    LoginServerPage,
    RangkingPage,
    AppVersionPage,
    AppReviewPage,
    ChattingPage,
    ReportPage,
    VideoPage,
    PlayerSearchPage,
} from 'pages';

import { ProjectCategoryLayout } from 'components';
import { EServiceAuthLevel, EServiceAuthName } from '@gamebase-web-ops/authorization/lib/v2';
import Community from 'pages/project/community';

const ProjectRoute = () => {
    return (
        <Switch>
            <ProjectCategoryLayout>
                <Route path="/project/dashboard" render={() => <ProjectDashBoard />} />

                <Route
                    path="/project/log"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.LOG}>
                            <LogPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    exact
                    path={['/project/contents/notice', '/project/contents/notice/:type']}
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.NOTICE} level={EServiceAuthLevel.WRITE}>
                            <NoticePage />
                        </PageAuthViewController>
                    )}
                />

                <Route
                    path={['/project/contents/event/:type', '/project/contents/event']}
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.EVENT} level={EServiceAuthLevel.WRITE}>
                            <WebviewEventPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    exact
                    path={['/project/contents/mail', '/project/contents/mail/:mailType']}
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.MAIL}>
                            <MailPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path={['/project/contents/coupons', '/project/contents/coupon']}
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.COUPON} level={EServiceAuthLevel.WRITE}>
                            <CouponPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/contents/push"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.PUSH} level={EServiceAuthLevel.WRITE}>
                            <PushPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/contents/gameSystem"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.PROJECT}>
                            <GameSystemPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/contents/item"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.ITEM} level={EServiceAuthLevel.WRITE}>
                            <ItemPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path={['/project/contents/rank/:id', '/project/contents/rank']}
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.RANK} level={EServiceAuthLevel.WRITE}>
                            <RangkingPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/contents/app-review"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.APP_REVIEW} level={EServiceAuthLevel.WRITE}>
                            <AppReviewPage />
                        </PageAuthViewController>
                    )}
                />

                <Route
                    path="/project/contents/chat"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.CHAT} level={EServiceAuthLevel.WRITE}>
                            <ChattingPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/contents/video"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.EVENT} level={EServiceAuthLevel.WRITE}>
                            <VideoPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/contents/community"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.EVENT} level={EServiceAuthLevel.WRITE}>
                            <Community />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path={['/project/promotion/:query', '/project/promotion']}
                    render={() => (
                        <PageAuthViewController
                            service={EServiceAuthName.CROSS_PROMOTION}
                            level={EServiceAuthLevel.WRITE}
                        >
                            <PromotionPage />
                        </PageAuthViewController>
                    )}
                />
                {/* <Route
                    path="/project/mail/detail/:id"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.MAIL} level={EServiceAuthLevel.READ}>
                            <MailDetailPage />
                        </PageAuthViewController>
                    )}
                /> */}

                <Route
                    path="/project/user/userInfo/:pid"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.USER} level={EServiceAuthLevel.WRITE}>
                            <UserPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    exact
                    path="/project/user/userInfo"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.USER} level={EServiceAuthLevel.WRITE}>
                            <UserPage />
                        </PageAuthViewController>
                    )}
                ></Route>
                <Route
                    path="/project/user/accountMerge"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.ACCOUNT_MERGE}>
                            <AccountMerge />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/user/order"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.ORDER} level={EServiceAuthLevel.READ}>
                            <OrderPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/user/voidedPurchase"
                    render={() => (
                        <PageAuthViewController
                            service={EServiceAuthName.VOIDED_PURCHASE}
                            level={EServiceAuthLevel.READ}
                        >
                            <VoidedPurchasePage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/user/report"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.USER} level={EServiceAuthLevel.READ}>
                            <ReportPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/user/blockUser"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.BLOCK_USER}>
                            <BlockUserPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/maintenance/ingameMaintenance"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.NOTICE} level={EServiceAuthLevel.WRITE}>
                            <IngameServerPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/maintenance/serverMaintenance"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.PROJECT} level={EServiceAuthLevel.WRITE}>
                            <RealTimeServerPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/maintenance/appsetting"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.APP_SETTING} level={EServiceAuthLevel.WRITE}>
                            <AppVersionPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/marketing/metaData"
                    render={() => (
                        <PageAuthViewController
                            service={EServiceAuthName.GAME_MARKETING_MEATADATA}
                            level={EServiceAuthLevel.WRITE}
                        >
                            <MetaDataPage />
                        </PageAuthViewController>
                    )}
                />

                <Route
                    path="/project/policy/policy"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.POLICY} level={EServiceAuthLevel.WRITE}>
                            <PolicyPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path="/project/development/loginServer"
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.POLICY} level={EServiceAuthLevel.WRITE}>
                            <LoginServerPage />
                        </PageAuthViewController>
                    )}
                />
                <Route
                    path={['/project/contents/player-search/:id', '/project/contents/player-search']}
                    render={() => (
                        <PageAuthViewController service={EServiceAuthName.PLAYER_SEARCH} level={EServiceAuthLevel.READ}>
                            <PlayerSearchPage />
                        </PageAuthViewController>
                    )}
                />
            </ProjectCategoryLayout>
        </Switch>
    );
};

export default ProjectRoute;
