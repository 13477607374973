import { useScreenLock } from 'hooks';
import useGetProjectContentLock from './model/useGetProjectContentLock';
import { useCallback } from 'react';
import { ItemRepository } from 'repository';
import { ItemLockRequest, ItemUnlockRequest } from '@gamebase-web-ops/item';

export default function useProjectContentLock(projectId: string) {
    const { data: contentsList, refetch } = useGetProjectContentLock(projectId);
    const { withScreenLockCall } = useScreenLock();

    const lock = useCallback(
        async (ItemId: number) => {
            await ItemRepository.lock(new ItemLockRequest({ projectId, ItemId }));
        },
        [projectId],
    );

    const unLock = useCallback(
        async (ItemId: number) => {
            await ItemRepository.unlock(new ItemUnlockRequest({ projectId, ItemId }));
        },
        [projectId],
    );

    const lockItem = useCallback(
        async (ItemId: number) => {
            await withScreenLockCall(lock, ItemId);
            refetch();
        },
        [withScreenLockCall, refetch, lock],
    );

    const unLockItem = useCallback(
        async (ItemId: number) => {
            await withScreenLockCall(unLock, ItemId), refetch();
        },
        [unLock, withScreenLockCall, refetch],
    );

    return {
        contentsList: contentsList?.result,
        lockItem,
        unLockItem,
    };
}
