import { AccountBasicDto } from '@gamebase-web-ops/account';
import { Dropdown, Menu } from 'antd';
import classNames from 'classnames/bind';
import styles from './AccountDropDown.module.scss';
import useMenuItems from 'hooks/antd/useMenuItems';
interface IProp {
    accounts: AccountBasicDto[];
    onClick?: (arg: AccountBasicDto) => void;
}

const cx = classNames.bind(styles);

const AccountDropDown: React.FC<IProp> = ({ accounts, onClick }) => {
    const { getItem } = useMenuItems();
    const menuItems = accounts.map((account, index) => {
        return getItem(
            <div
                key={account.id || index}
                onClick={() => {
                    onClick && onClick(account);
                }}
            >
                <div>{account.name}</div>
                <div>{account.userId}</div>
            </div>,
            account.id || index,
        );
    });
    return (
        <Dropdown open overlay={<Menu className={cx('wrapper')} items={menuItems}></Menu>}>
            <div></div>
        </Dropdown>
    );
};

export default AccountDropDown;
