import { ECrossPromotionState } from '@gamebase-web-ops/cross-promotion';
import { RcFile } from 'antd/lib/upload/interface';
import moment from 'moment';
import { notificationPush } from 'utils/notificationUtils';

export const CP_STATUS_BADGE = [
    'default',
    'warning',
    'success',
    'default',
    'warning',
    'default',
    'processing',
] as const;

export const CP_STATUS_MESSAGE = [
    'Paused',
    'Processing',
    'Deployed',
    'Paused', // error
    'Processing',
    'Paused',
    'Running', // error
] as const;

export enum CP_OS {
    'Unknown' = -1,
    'All' = 0,
    'Android OS' = 1,
    'iOS' = 2,
}

export const isRunning = (startDatetime: Date, endDatetime: Date, status: ECrossPromotionState) => {
    const now = new Date();
    if (
        status === ECrossPromotionState.WRITE_SUCCESS &&
        moment(now).isBetween(moment(startDatetime), moment(endDatetime))
    ) {
        return true;
    }
    return false;
};

export const isDeployed = (status: ECrossPromotionState) => {
    return (
        status === ECrossPromotionState.WRITE_SUCCESS ||
        status === ECrossPromotionState.REMOVE_FAIL ||
        status === ECrossPromotionState.REMOVE_REQUEST
    );
};

export const isProcessing = (status: ECrossPromotionState) => {
    return status === ECrossPromotionState.WRITE_REQUEST || status === ECrossPromotionState.REMOVE_REQUEST;
};

export const isError = (status: ECrossPromotionState) => {
    return status === ECrossPromotionState.WRITE_FAIL || status === ECrossPromotionState.REMOVE_FAIL;
};

export const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

export const displayNotificationAfterCompareToBefore = (
    before: ECrossPromotionState,
    after: ECrossPromotionState,
): void => {
    if (
        (before !== ECrossPromotionState.REMOVE_FAIL && after === ECrossPromotionState.REMOVE_FAIL) ||
        (before !== ECrossPromotionState.WRITE_FAIL && after === ECrossPromotionState.WRITE_FAIL)
    ) {
        notificationPush({
            status: 'error',
            title: '요청에 실패하였습니다.',
        });
    }

    if (before !== ECrossPromotionState.WRITE_SUCCESS && after === ECrossPromotionState.WRITE_SUCCESS) {
        notificationPush({
            status: 'success',
            title: '정상적으로 배포되었습니다.',
        });
    }

    if (before !== ECrossPromotionState.REMOVE_SUCCESS && after === ECrossPromotionState.REMOVE_SUCCESS) {
        notificationPush({
            status: 'success',
            title: '정상적으로 중단되었습니다.',
        });
    }
};
export const UploaderTagStyles = {
    style: {
        width: '104px',
    },
    color: '#40A9FF',
};

export const requiredFormAttr = {
    rules: [{ required: true, message: '* 해당 입력란이 비어있습니다.' }],
};

export interface ImagesByContry {
    main: string;
    button: string;
}

export interface Images {
    icon: string;
    imagesByCountry: Record<string, ImagesByContry>;
}

export interface OnChangeImageUrl {
    lng: string;
    key: keyof ImagesByContry;
    url: string;
}

type URL_KEYS = keyof OnChangeImageUrl;
type ValueOf<T> = T[keyof T];
export type ValueOfOnChangeImageUrl<T extends URL_KEYS> = ValueOf<Pick<OnChangeImageUrl, T>>;

export interface ButtonPosition {
    left: string;
    right: string;
    bottom: string;
    top: string;
}
