import { Form, Input, Button } from 'antd';
import { useFormatMessage } from 'hooks';
interface IProp {
    prevUserName: string;
    onFinish: (arg: IUserNameChange) => void;
    onCancel?: () => void;
}

const UserNameChangeForm: React.FC<IProp> = ({ prevUserName, onFinish, onCancel }) => {
    const { messageFormatting } = useFormatMessage();
    return (
        <Form onFinish={onFinish}>
            <Form.Item name="username" label="이름" initialValue={prevUserName}>
                <Input />
            </Form.Item>
            <Button onClick={onCancel} danger>
                {messageFormatting('CANCEL')}
            </Button>
            <Button type="primary" ghost htmlType="submit" style={{ marginLeft: '16px' }}>
                {messageFormatting('CONFIRM')}
            </Button>
        </Form>
    );
};

export default UserNameChangeForm;
