/* eslint-disable @typescript-eslint/no-explicit-any */
import { registerDecorator, ValidationOptions, ValidationArguments } from 'class-validator';

export function IsExcuteTest<T>(property: (obj: T, value: any) => boolean, validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            name: 'isExcuteTest',
            target: object.constructor,
            propertyName: propertyName,
            constraints: [property],
            options: validationOptions,
            validator: {
                validate(value: T, args: ValidationArguments) {
                    const [relatedPropertyName] = args.constraints;
                    const relatedValue = relatedPropertyName(args.object, value);
                    return relatedValue;
                },
            },
        });
    };
}
