import classNames from 'classnames/bind';
import styles from './Header.module.scss';
import { Button, ConfigProvider } from 'antd';

interface IProp {
    title: string;
    customStyle?: boolean;
    link?: string;
    linkBtnTitle?: string;
}

interface DescProp {
    desc: string;
}

const cx = classNames.bind(styles);

const Header: React.FC<IProp> = ({ children, title, customStyle, link, linkBtnTitle }) => {
    return (
        <header className={!customStyle ? cx('header') : ''}>
            {link ? (
                <div className={cx('guide_wrap')}>
                    <h1>{title}</h1>
                    <Button style={{ backgroundColor: '#7d8cfa', border: '#7d8cfa' }} type="primary">
                        <a href={link} target="_blank" rel="noreferrer">
                            {linkBtnTitle || '가이드'}
                        </a>
                    </Button>
                </div>
            ) : (
                <h1 className={!customStyle ? cx('title') : ''}>{title}</h1>
            )}
            {children}
        </header>
    );
};

export const HeaderDesc = ({ desc }: DescProp) => {
    return <div className={cx('desc')}>{desc}</div>;
};

export default Header;
