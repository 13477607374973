import { IsDate, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';
import { LogActType } from 'constants/constants';
import { PaginationQuery } from './utils.dto';

export class LogRequestDto extends PaginationQuery {
    constructor(param: LogRequestDto) {
        super(param);
        this.projectId = param.projectId;
        this.fromDt = param.fromDt;
        this.toDt = param.toDt;
        this.actType = param.actType;
        this.pid = param.pid;
    }

    @IsNotEmpty()
    @IsNumber()
    projectId: number;

    @IsDate()
    @IsNotEmpty()
    fromDt: Date;

    @IsDate()
    @IsNotEmpty()
    toDt: Date;

    @IsEnum(LogActType)
    @IsOptional()
    actType?: LogActType;

    @IsString()
    @IsOptional()
    pid?: string;
}
