import { ListByNicknameRequest } from '@gamebase-ops/interfaces/gameUser';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { gameUserRepository } from 'repository/User.repository';

export default function useGetUserList(projectId: string, userName?: string) {
    const [listQuery, setListQuery] = useState<ListByNicknameRequest>({
        projectId,
        user_name: userName || '',
    });

    const queries = useQuery(['userList', listQuery], () =>
        listQuery.user_name ? gameUserRepository.listByNickname({ query: listQuery }) : undefined,
    );

    const setUserName = useCallback((userName: string) => {
        setListQuery((prev) => ({
            ...prev,
            user_name: userName,
        }));
    }, []);

    return {
        ...queries,
        setListQuery,
        listQuery,
        setUserName,
    };
}
