import useGetGuidUserList from './model/useGetGuidUserList';
import { useState } from 'react';

export default function useGuidUserList(projectId: string) {
    const [guid, setGuid] = useState('');
    const { data: guidUserList, error } = useGetGuidUserList({
        projectId,
        guid,
    });

    return {
        setGuid,
        guidUserList: guidUserList?.result,
        error,
    };
}
