import { useScreenLock } from 'hooks';
import { useCallback, useState } from 'react';
import { ProjectRepository } from 'repository';
import {
    ProjectIpWhiteListRequest,
    ProjectIpWhiteListRemoveRequest,
    ProjectIpWhiteListAddRequest,
} from '@gamebase-web-ops/project';

export default function useIPWhiteList(projectId: string) {
    const [ipWhiteList, setIpWhiteList] = useState<string[]>();
    const { withScreenLockCall } = useScreenLock();

    const getIPwhiteList = useCallback(async () => {
        const result = await ProjectRepository.ipWhiteList(new ProjectIpWhiteListRequest({ projectId }));
        result.result ? setIpWhiteList(result.result) : setIpWhiteList([]);
    }, [projectId]);

    const removeIpWhiteList = useCallback(
        async (IpAddress: string) => {
            ProjectRepository.removeFromIpWhiteList(
                new ProjectIpWhiteListRemoveRequest({
                    projectId,
                    IpAddress,
                }),
            );
        },
        [projectId],
    );

    const addIpWhiteList = useCallback(
        async (IpAddress: string) => {
            ProjectRepository.addToIpWhiteList(
                new ProjectIpWhiteListAddRequest({
                    projectId,
                    IpAddress,
                }),
            );
        },
        [projectId],
    );

    const removeIp = useCallback(
        async (IpAddress: string) => {
            await withScreenLockCall(removeIpWhiteList, IpAddress);
            await getIPwhiteList();
        },
        [withScreenLockCall, removeIpWhiteList, getIPwhiteList],
    );

    const addIp = useCallback(
        async (IpAddress: string) => {
            await withScreenLockCall(addIpWhiteList, IpAddress);
            await getIPwhiteList();
        },
        [withScreenLockCall, addIpWhiteList, getIPwhiteList],
    );

    return {
        ipWhiteList,
        getIPwhiteList,
        removeIp,
        addIp,
    };
}
