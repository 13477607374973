import { css } from '@emotion/react';
import { INoticeProp, NoticeEditor } from '@gamebase-ops/editor';

import { useWebviewRender } from 'hooks';
import { useEffect, useState } from 'react';

interface IProp extends Omit<INoticeProp, 'defaultValue'> {
    defaultValueUrl?: string;
    isLinkMode?: boolean;
    onChange?: (lang: string) => (text: string) => void;
    lang: string;
    name?: string;
    defaultContentsValue?: string;
}

export default function WebviewEditor({
    defaultValueUrl,
    onChange,
    onImageUpload,
    lang,
    name,
    defaultContentsValue,
}: IProp) {
    const [inputValue, setInputValue] = useState<string>('');
    const { contents: defaultValue } = useWebviewRender(defaultValueUrl);
    useEffect(() => {
        if (defaultValue) {
            onChange?.(lang)(defaultValue);
            setInputValue(defaultValue);
        } else {
            onChange?.(lang)('');
            setInputValue(defaultContentsValue || '');
        }
    }, [defaultValue, onChange, lang, defaultContentsValue]);

    if (defaultValueUrl && !defaultValue) return null;

    return (
        <section css={wrapperStyle}>
            <NoticeEditor
                onChange={(e) => {
                    onChange?.(lang)(e);
                    setInputValue(e);
                }}
                onImageUpload={onImageUpload}
                defaultValue={defaultValue || defaultContentsValue}
            />
            <textarea style={{ display: 'none' }} defaultValue={inputValue} name={name} />
        </section>
    );
}

const wrapperStyle = css`
    border: 1px solid #aaa;
    z-index: 1001;
    width: 100%;
    img[alt] {
        border: 2px solid blue;
    }
    .ck-content {
        overflow-y: auto;
        height: 500px;
    }
`;
