import { Authorization2Repository } from 'repository';
import { useQuery } from 'react-query';
import { PROJECT_LIST_KEY } from 'constants/queryKeys';

export default function useProjectList() {
    const { data, isLoading } = useQuery(PROJECT_LIST_KEY, () => {
        return Authorization2Repository.projectList();
    });

    return {
        projectList: data?.result,
        isLoading,
    };
}
