import { useQuery } from 'react-query';
import { NoticeRepository } from 'repository';
import { WebviewNoticeListOfDeployedRequest } from '@gamebase-web-ops/notice';

export default function usePublishedWebviewNoticeListModel(projectId: string) {
    const queries = useQuery(['publishedWebviewNotice', projectId], () =>
        NoticeRepository.listOfDeployedWebviewNotice(new WebviewNoticeListOfDeployedRequest({ projectId })),
    );

    return {
        ...queries,
    };
}
