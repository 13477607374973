/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/display-name */
import { LoadingSpin } from 'components';
import { useCallback, useMemo } from 'react';

interface IOptions {
    auths?: IAuthCheck[];
    isAllConditionCheck?: boolean;
}

interface IProp {
    auths?: IAuthCheck[];
}

/**
 * 페이지에서 최소로 가지고 있어야하는 권한을 체크하는 Hoc(High Order Component)
 */

const PageViewAuthHoc =
    (Component: React.FC<IProp>, options: IOptions) =>
    ({ auths }: IProp) => {
        const authDiffCheck = useCallback((srcAuth, destAuth) => {
            if (srcAuth.service !== destAuth.service) return false;
            if (srcAuth.level < destAuth.level) return false;
            if (destAuth.projectId !== undefined) {
                if (srcAuth.projectId !== destAuth.projectId) return false;
            }
            return true;
        }, []);

        const condition = useMemo(() => {
            if (!options.auths || options.auths.length === 0) return true;
            return options.auths.reduce((acc, auth) => {
                const findResult = auths?.find((canAuth) => authDiffCheck(canAuth, auth)) ? true : false;
                const nextVal = options.isAllConditionCheck ? findResult && acc : findResult || acc;
                return nextVal;
            }, options.isAllConditionCheck || false);
        }, [auths, authDiffCheck]);

        // if (condition) return <Component auths={auths} />;
        if (condition) return <Component auths={auths} />;

        return <LoadingSpin />;
    };

export default PageViewAuthHoc;
