import { css } from '@emotion/react';
import { useWebviewRender } from 'hooks';

interface IProp {
    url: string;
}

export default function WebviewContentRenderer({ url }: IProp) {
    const { isError, contents } = useWebviewRender(url);

    if (isError) return <div>불러오기 실패 했습니다.</div>;

    return (
        <div
            css={renderer}
            dangerouslySetInnerHTML={{
                __html: contents === undefined ? '불러오는 중 입니다.' : contents,
            }}
        ></div>
    );
}

const renderer = css`
    img[alt] {
        border: 2px solid blue;
    }
`;
