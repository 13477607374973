import { useQueries } from 'hooks';
import { LinkProps, Link } from 'react-router-dom';

const QueryLink: React.FC<LinkProps> = ({ to, className, children, ...props }) => {
    const { projectId } = useQueries();

    return (
        <Link to={`${to}${projectId !== null ? `?projectId=${projectId}` : ''}`} className={className} {...props}>
            {children}
        </Link>
    );
};

export default QueryLink;
