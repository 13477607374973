import { AccountListRequest } from '@gamebase-web-ops/account';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { AccountRepository } from 'repository';

export default function useAccountListModel(limit = 15) {
    const [query, setQuery] = useState<AccountListRequest>();
    const queries = useQuery(['accountList', query], () =>
        query ? AccountRepository.list(new AccountListRequest({ ...query })) : null,
    );
    const setPage = useCallback(
        (page: number) => {
            setQuery((prev) => {
                if (prev) {
                    return { ...prev, page };
                }
                return {
                    limit,
                    page,
                    projectId: 'COMMON',
                };
            });
        },
        [limit],
    );

    const setFirstPageOrRefetch = useCallback(() => {
        setQuery((prev) => {
            if (prev?.page === 1) {
                queries.refetch();
            }

            return { projectId: 'COMMON', limit, page: 1 };
        });
    }, [limit, queries]);

    const requestAccountsByKeyword = useCallback(
        (keyword: string) => {
            setQuery((prev) => {
                if (!prev) {
                    return {
                        projectId: 'COMMON',
                        limit,
                        page: 1,
                        name: keyword,
                    };
                }
                return {
                    ...prev,
                    name: keyword,
                };
            });
        },
        [limit],
    );

    return { ...queries, setFirstPageOrRefetch, setPage, query, requestAccountsByKeyword };
}
