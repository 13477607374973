import { css } from '@emotion/react';
import { WebviewNoticeDto } from '@gamebase-web-ops/notice';
import { Button, Tabs, TabsProps } from 'antd';

import { useQueries } from 'hooks';
import useProjectDetail from 'hooks/project/useProjectDetail';
import moment from 'moment';
import WebviewContentRenderer from './WebviewContentRenderer';
import { useMemo } from 'react';

interface IProp {
    noticeWebviewData?: WebviewNoticeDto;
    onEditClick?: () => void;
}
export default function WebviewCheckViewer({ noticeWebviewData, onEditClick }: IProp) {
    const { projectId } = useQueries();
    const { projectDetail } = useProjectDetail(projectId);
    const projectDetailItems: TabsProps['items'] = useMemo(
        () =>
            projectDetail?.languages?.map((lang: string) => ({
                key: lang,
                label: lang,
                forceRender: true,
                children: (
                    <>
                        <div css={contentsWrapper}>
                            <span css={contentsTitle}>배너</span>
                            <section css={contentsRendererWrapper}>
                                <img css={bannerImage} src={noticeWebviewData?.mainImages?.[lang]} />
                            </section>
                        </div>

                        <div css={contentsWrapper}>
                            <span css={contentsTitle}>제목</span>
                            <section>{noticeWebviewData?.titles?.[lang]}</section>
                        </div>

                        <div css={contentsWrapper}>
                            <span css={contentsTitle}>내용</span>
                            <section css={contentsRendererWrapper}>
                                {noticeWebviewData?.contents?.[lang] && (
                                    <WebviewContentRenderer url={noticeWebviewData.contents[lang].url} />
                                )}
                            </section>
                        </div>
                    </>
                ),
            })),
        [noticeWebviewData, projectDetail?.languages],
    );

    return (
        <div>
            <section css={editButtonWrapper}>
                <Button type="primary" onClick={onEditClick}>
                    수정
                </Button>
            </section>
            <div css={contentsWrapper}>
                <span css={contentsTitle}>우선언어</span>
                {noticeWebviewData?.defaultLanguage}
            </div>
            <Tabs type="card" items={projectDetailItems}></Tabs>
            <div css={contentsWrapper}>
                <span css={contentsTitle}>생성 날짜</span>
                <section>{moment(noticeWebviewData?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</section>
            </div>
            <div css={contentsWrapper}>
                <span css={contentsTitle}>노출 기간</span>
                <section>
                    {moment(noticeWebviewData?.startTime).format('YYYY-MM-DD HH:mm:ss')} ~{' '}
                    {moment(noticeWebviewData?.endTime).format('YYYY-MM-DD HH:mm:ss')}
                </section>
            </div>
        </div>
    );
}

const contentsRendererWrapper = css`
    width: 100%;
    margin-top: 10px;
    * {
        max-width: 100%;
    }
`;

const contentsWrapper = css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
`;

const contentsTitle = css`
    width: 80px;
    height: 28px;
    font-size: 16px;
    background: #070440;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-right: 16px;
`;

const bannerImage = css`
    width: 30%;
`;

const editButtonWrapper = css`
    text-align: right;
`;
