/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormProps, Form } from 'antd';
import { assocPath } from 'utils/deepSetObject';
import { useCallback, PropsWithChildren } from 'react';

interface IProp<SubmitArgument> extends FormProps<SubmitArgument> {
    onSubmit?: (arg: SubmitArgument) => void;
    enabledenter?: boolean;
}

function EnterIgnoreForm<SubmitArgument>({ enabledenter, ...props }: PropsWithChildren<IProp<SubmitArgument>>) {
    const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLFormElement>) => {
        e.key.toLowerCase() === 'enter' && e.preventDefault();
    }, []);
    const onSubmitCapture = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            const formData = new FormData(e.currentTarget);

            const entries = formData.entries();

            const obj: any = {};

            for (const item of entries) {
                const splitKey = item[0].split('.');
                if (splitKey.length === 1) {
                    obj[item[0]] = item[1];
                } else {
                    assocPath(splitKey, item[1], obj);
                }
            }
            if (props.onSubmit) {
                props.onSubmit(obj as SubmitArgument);
            }
        },
        [props],
    );

    return (
        <Form
            {...props}
            onKeyDown={enabledenter ? undefined : onKeyDown}
            onSubmitCapture={onSubmitCapture}
            colon={false}
        >
            {props.children}
        </Form>
    );
}

export default EnterIgnoreForm;
