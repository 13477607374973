import useUserDetalModel from './model/useUserDetail.model';
import { useCallback } from 'react';

import useUserListModel from './model/useUserList.model';
import { gameUserRepository } from 'repository/User.repository';

type Target = 'src' | 'dst';

export default function useRestoreAccount(projectId: string) {
    const { data: srcUserData, setUserId: setSrcUserId } = useUserDetalModel(projectId);
    const { data: dstUserData, setUserId: setDstUserId } = useUserDetalModel(projectId);

    const getUserData = useCallback(
        (target: Target) => {
            if (target === 'src') return srcUserData?.result;
            return dstUserData?.result;
        },
        [srcUserData, dstUserData],
    );
    const setUserDetailById = useCallback(
        (target: Target, value: string) => {
            if (target === 'src') {
                setSrcUserId(value);
            } else {
                setDstUserId(value);
            }
        },
        [setDstUserId, setSrcUserId],
    );

    const { data: srcSearchList, setUserName: setSrcUserName } = useUserListModel(projectId);
    const { data: dstSearchList, setUserName: setDstUserName } = useUserListModel(projectId);

    const getNameSearchList = useCallback(
        (target: Target) => {
            if (target === 'src') return srcSearchList?.result;
            return dstSearchList?.result;
        },
        [srcSearchList, dstSearchList],
    );
    const setUserListByName = useCallback(
        (target: Target, value: string) => {
            if (target === 'src') {
                setSrcUserName(value);
            } else {
                setDstUserName(value);
            }
        },
        [setSrcUserName, setDstUserName],
    );

    const restoreAccount = useCallback(async () => {
        if (!srcUserData || !dstUserData) throw new Error('두 유저를 선택해야합니다.');

        await gameUserRepository.restoreAccount({
            query: { projectId },
            body: {
                src_account_id: srcUserData.result.account_id,
                dst_account_id: dstUserData.result.account_id,
                src_user_id: srcUserData.result.user_id,
                dst_user_id: dstUserData.result.user_id,
            },
        });
    }, [srcUserData, dstUserData, projectId]);

    return {
        getUserData,
        restoreAccount,
        setUserDetailById,
        setUserListByName,
        getNameSearchList,
    };
}
