import { CheckRequest } from '@gamebase-web-ops/authorization/lib/v2';
import { GuestRegisterRequest } from '@gamebase-web-ops/account';

import qs from 'qs';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AccountRepository, AuthenticationRepository, Authorization2Repository } from 'repository';
import { emailLoginAction, googleLoginAction, logoutAction } from 'store/modules/auth/action';
import RequestAccess from 'utils/api/ReqeustAccess';

export default function useAuth() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const { redirectUrl, originUrl } = qs.parse(search, { ignoreQueryPrefix: true });

    const { email, isError, isRequest } = useSelector<RootStore, RootStore['auth']>(({ auth }) => auth);

    const signUpRequest = useCallback(async (params: GuestRegisterRequest) => {
        await AccountRepository.registerGuest(new GuestRegisterRequest({ ...params }));
    }, []);

    const logoutCallback = useCallback(
        (url?: string) => {
            const search = window.location.search;
            const { redirectUrl } = qs.parse(search, { ignoreQueryPrefix: true });
            if (!redirectUrl) {
                history.push(`/login${url && `?redirectUrl=${url}`}`);
            }
        },
        [history],
    );

    const logoutRequest = useCallback(() => {
        const token = RequestAccess.getInstance().getAccessToken();
        if (token) dispatch(logoutAction({ callback: logoutCallback, requesterId: token }));
        else logoutCallback(encodeURIComponent(window.location.href));
    }, [dispatch, logoutCallback]);

    const authCheck = useCallback(
        async (param?: CheckRequest) => {
            try {
                if (param === undefined) {
                    const { code } = await AuthenticationRepository.check();
                    if (code !== '200') throw '로그인이 필요합니다.';
                } else {
                    await Authorization2Repository.check(new CheckRequest({ query: param }));
                }
                return true;
            } catch (e) {
                logoutRequest();
                return false;
            }
        },
        [logoutRequest],
    );

    const loginCallback = useCallback(() => {
        if (originUrl) {
            const token = RequestAccess.getInstance().getAccessToken();
            window.opener?.postMessage(token, originUrl);
            window.close();
            return;
        }
        if (redirectUrl) {
            if (String(redirectUrl).indexOf('/login') !== -1) {
                history.push('/');
                window.location.pathname = '/';
                return;
            }
            window.location.href = redirectUrl as string;
            return;
        }
        history.push('/');
    }, [history, redirectUrl, originUrl]);

    const googleLoginRequest = useCallback(
        (params: GoogleLoginPayload) => {
            dispatch(googleLoginAction({ ...params, callback: loginCallback }));
        },
        [dispatch, loginCallback],
    );

    const emailLoginRequest = useCallback(
        (params: EmailLogin) => {
            dispatch(emailLoginAction({ ...params, callback: loginCallback }));
        },
        [dispatch, loginCallback],
    );

    return {
        email,
        logoutCallback,
        logoutRequest,
        signUpRequest,
        googleLoginRequest,
        emailLoginRequest,
        authCheck,
        loginCallback,
        originUrl,
        isError,
        isRequest,
    };
}
