import { Spin } from 'antd';
import classNames from 'classnames/bind';
import styles from './LoadingSpin.module.scss';

const cx = classNames.bind(styles);

const LoadingSpin = () => (
    <div className={cx('wrapper')}>
        <Spin size="large" />
    </div>
);

export default LoadingSpin;
