import ImageInput from 'components/utils/ImageInput';

interface IProp {
    onChange: (image: File) => void;
    titleImageUrl?: string;
}

export default function WebviewTitleImage({ onChange, titleImageUrl }: IProp) {
    return <ImageInput name="file" defaultImageUrl={titleImageUrl} onFileChange={onChange} />;
}
