import { useCallback, useState } from 'react';
import { PurchaseLogDto, PurchaseLogAllRequest } from '@gamebase-web-ops/purchase-log';
import { PurchaseLogRepository } from 'repository';
import moment from 'moment';

export default function useOrder(projectId: string) {
    const [purchaseLogs, setPurchaseLogs] = useState<PurchaseLogDto[] | undefined>([]);

    const requestOrderList = useCallback(
        async (params: Omit<PurchaseLogAllRequest, 'projectId'>) => {
            setPurchaseLogs(undefined);
            try {
                const result = await PurchaseLogRepository.all(
                    new PurchaseLogAllRequest({
                        ...params,
                        fromDt: moment(params.fromDt).startOf('date').toISOString(),
                        toDt: moment(params.toDt).endOf('date').toISOString(),
                        projectId,
                    }),
                );
                setPurchaseLogs(result.result);
            } catch (e) {
                setPurchaseLogs([]);
                throw e;
            }
        },
        [projectId],
    );

    return { requestOrderList, purchaseLogs };
}
