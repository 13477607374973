import { useState } from 'react';
import { useQuery } from 'react-query';
import { gameUserRepository } from 'repository/User.repository';

export default function useGetUserDetail(projectId: string, defaultUserId?: string) {
    const [userId, setUserId] = useState(defaultUserId);
    const queries = useQuery([userId, 'userDetail'], () =>
        userId ? gameUserRepository.info({ query: { projectId, user_id: userId } }) : undefined,
    );

    return {
        ...queries,
        setUserId,
        userId,
    };
}
