import { Form, Tag, Input, Switch } from 'antd';

import styles from './UserFormList.module.scss';
import classNames from 'classnames/bind';
import React, { useCallback, useRef } from 'react';
import { useFormatMessage } from 'hooks';

const cx = classNames.bind(styles);

interface IProp {
    users: string[];
    setUsers: (arg: string[]) => void;
    deleteUser: (arg: string) => void;
    setIsAll: (arg: boolean) => void;
    isAll: boolean;
    canToggle?: boolean;
}

const UserFormList: React.FC<IProp> = ({ users, setUsers, deleteUser, setIsAll, isAll, canToggle }) => {
    const ref = useRef<HTMLTextAreaElement>(null);
    const { messageFormatting } = useFormatMessage();
    const clickEventListner = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            event.preventDefault();
            const users = new Set(ref.current?.value.split('\n').filter((userId) => !!userId));
            if (users?.size) setUsers([...users.values()]);
            else setUsers([]);
        },
        [setUsers],
    );
    return (
        <div>
            {canToggle ? (
                <Form.Item name="isAll" label={messageFormatting('ALL')} initialValue={isAll} colon={false}>
                    <Switch onChange={setIsAll} checked={isAll} />
                </Form.Item>
            ) : (
                <Form.Item name="isAll" initialValue={isAll}>
                    <>
                        <input type="hidden" />
                        <div>{isAll ? messageFormatting('ALL') : messageFormatting('TARGET_USER')}</div>
                    </>
                </Form.Item>
            )}
            {!isAll && (
                <>
                    <div className={cx('userSettingWrapper')}>
                        <textarea
                            placeholder={`user1Id\nuser2Id\nuser3Id\nuser4Id\nuser5Id`}
                            ref={ref}
                            className={cx('textarea')}
                            defaultValue={users.join('\n')}
                            onKeyDown={(e) => e.stopPropagation()}
                        />
                        <button onClick={clickEventListner} className={cx('button')}>
                            {messageFormatting('MAIL_SEVERAL_USER_SET')}
                        </button>
                    </div>
                    <div className={cx('userWrapper')}>
                        {users.map((user, index) => (
                            <div key={index}>
                                <Form.Item style={{ display: 'none' }}>
                                    <Input
                                        type="hidden"
                                        style={{ display: 'none' }}
                                        value={user}
                                        name={`userIds.${index}`}
                                    />
                                </Form.Item>
                                <Tag
                                    style={{ marginBottom: '16px' }}
                                    color="blue"
                                    key={user}
                                    closable
                                    onClose={() => {
                                        deleteUser(user);
                                    }}
                                >
                                    {user}
                                </Tag>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default UserFormList;
