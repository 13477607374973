import { AutoClearSearch } from 'components';
import classNames from 'classnames/bind';
import styles from './ItemSearch.module.scss';
import { useCallback, useEffect, useState } from 'react';
import { useItem, useQueries } from 'hooks';
import { ISendMailItem } from 'dto/mail';
import { ItemDto } from '@gamebase-web-ops/item';
import ItemTable from './ItemTable';

const cx = classNames.bind(styles);

interface IProp {
    addItems: (param: ISendMailItem) => void;
    placeHolder?: string;
}

const ItemSearch: React.FC<IProp> = ({ addItems, placeHolder }) => {
    const [isShowResult, setIsShowResult] = useState(false);
    const { projectId } = useQueries();

    const {
        itemList: searchResultItemList,
        requestByKeyword,
        totalCount,
        page,
        limit,
        requestByPage,
    } = useItem({ projectId });
    const onSearch = useCallback(
        async (keyword: string) => {
            await requestByKeyword(keyword);
            setIsShowResult(true);
        },
        [requestByKeyword],
    );

    const windowClickEvent = useCallback(() => {
        setIsShowResult(false);
    }, []);

    useEffect(() => {
        if (isShowResult) {
            window.addEventListener('click', windowClickEvent);
        } else {
            window.removeEventListener('click', windowClickEvent);
        }

        return () => window.removeEventListener('click', windowClickEvent);
    }, [windowClickEvent, isShowResult]);

    return (
        <div className={cx('formWrapper')} onClick={(e) => e.stopPropagation()}>
            <AutoClearSearch onSearch={onSearch} placeholder={placeHolder} />
            {isShowResult && (
                <div className={cx('searchResultWrapper')}>
                    <ItemTable
                        itemList={searchResultItemList}
                        page={page}
                        total={totalCount}
                        pageSize={limit}
                        requestByPage={requestByPage}
                        handleItemClick={(item: ItemDto) => {
                            addItems({
                                item: item,
                                amount: 0,
                                itemId: item.itemId,
                            });
                            setIsShowResult(false);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default ItemSearch;
