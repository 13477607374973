import { useQuery } from 'react-query';
import { MaintenanceRepository } from 'repository';
import { MaintenanceInfoRequest } from '@gamebase-web-ops/maintenance';

export default function useMaintenanceInfoModel(projectId: string) {
    const queries = useQuery([projectId], () =>
        projectId ? MaintenanceRepository.info(new MaintenanceInfoRequest({ projectId })) : null,
    );
    return { ...queries };
}
