import { useCallback } from 'react';
import { MaintenanceRepository } from 'repository';
import {
    MaintenanceRequest,
    MaintenanceScheduleDeleteRequest,
    MaintenanceScheduleRequest,
} from '@gamebase-web-ops/maintenance';

import useMaintenanceInfoModel from './model/useMaintenanceInfo.model';
import useMaintenanceListModel from './model/useMaintenanceList.model';

export default function useMaintenance(projectId: string) {
    const { data, refetch: maintenanceRefetch } = useMaintenanceListModel(projectId);
    const { data: maintenanceInfo, refetch: maintenanceInfoRefetch } = useMaintenanceInfoModel(projectId);

    const excuteMaintenance = useCallback(
        async (param: MaintenanceRequest) => {
            await MaintenanceRepository.maintenace(new MaintenanceRequest({ ...param, projectId }));

            await maintenanceRefetch();
            await maintenanceInfoRefetch();
        },
        [projectId, maintenanceRefetch, maintenanceInfoRefetch],
    );

    const createReservation = useCallback(
        async (param: MaintenanceScheduleRequest) => {
            await MaintenanceRepository.schedule(new MaintenanceScheduleRequest({ ...param, projectId }));
            await maintenanceRefetch();
            await maintenanceInfoRefetch();
        },
        [maintenanceRefetch, maintenanceInfoRefetch, projectId],
    );

    const deleteReservation = useCallback(
        async (id: string) => {
            await MaintenanceRepository.deleteSchedule(new MaintenanceScheduleDeleteRequest({ id, projectId }));
            await maintenanceRefetch();
            await maintenanceInfoRefetch();
        },
        [maintenanceInfoRefetch, maintenanceRefetch, projectId],
    );

    return {
        maintenance: excuteMaintenance,
        maintanceReservationList: data?.result,
        maintenanceInfo: maintenanceInfo?.result,
        createReservation,
        deleteReservation,
    };
}
