import { LogRequestDto } from 'dto/log.dto';
import { ProjectIdQueryDto } from 'dto/utils.dto';
import { get } from 'utils/api/client';

const baseURL = '/api/v1/logs';

class LogRepository {
    getActTypes(projectId: string) {
        return get<string[]>(`${baseURL}/act-types`, new ProjectIdQueryDto(projectId));
    }

    find(param: LogRequestDto) {
        return get<LogListResponse>(baseURL, new LogRequestDto(param));
    }
}

export default new LogRepository();
