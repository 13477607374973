import { notification } from 'antd';

export const notificationPush = (params: Alert) => {
    notification[params.status]({
        message: params.title,
        description: params.message,
        duration: 4,
        style: {
            whiteSpace: 'pre-line',
            lineHeight: '20px',
        },
    });
};
