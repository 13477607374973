import { MY_PROJECT_LIST } from 'constants/queryKeys';
import { useQuery } from 'react-query';
import { Authorization2Repository } from 'repository';

export default function useMyProjectList() {
    const {
        data: myProjectList,
        isLoading: isProjectListLoading,
        isError: isProjectListError,
    } = useQuery(MY_PROJECT_LIST, async () => {
        const res = await Authorization2Repository.projectList();
        return res.result;
    });
    return {
        myProjectList,
        isProjectListLoading,
        isProjectListError,
    };
}
