import { useCallback, useEffect, useState } from 'react';
import { NoticeRepository } from 'repository';
import usePublishedWebviewNoticeListModel from './model/usePublishedWebviewNoticeList.model';
import { WebviewNoticeSortRequest, WebviewNoticeDto } from '@gamebase-web-ops/notice';

export default function usePublishedWebviewNotice(projectId: string) {
    const [sortedWebviewData, setSortedWebviewData] = useState<WebviewNoticeDto[]>();
    const { data, refetch } = usePublishedWebviewNoticeListModel(projectId);

    const removedAndInsertWebviewData = useCallback((removedIndex: number, addIndex: number) => {
        setSortedWebviewData((prev) => {
            if (removedIndex === addIndex) return prev;
            if (prev) {
                const nextData: WebviewNoticeDto[] = [];
                const tmpData: WebviewNoticeDto = prev[removedIndex];
                for (let i = 0; i < prev.length; i++) {
                    if (removedIndex === i) continue;
                    if (removedIndex < addIndex) {
                        nextData.push(prev[i]);
                        if (addIndex === i) nextData.push(tmpData);
                    }
                    if (removedIndex > addIndex) {
                        if (addIndex === i) nextData.push(tmpData);
                        nextData.push(prev[i]);
                    }
                }
                return nextData;
            }
        });
    }, []);

    const sortedPublishedNotice = useCallback(async () => {
        if (sortedWebviewData) {
            await NoticeRepository.sortWebviewNotice(
                new WebviewNoticeSortRequest({
                    projectId,
                    sortOrders: sortedWebviewData.map((notice, index) => ({ id: notice.id, sortOrder: index })),
                }),
            );

            await refetch();
        }
    }, [projectId, sortedWebviewData, refetch]);

    useEffect(() => {
        setSortedWebviewData(data?.result);
    }, [data]);

    return {
        sortedWebviewData,
        removedAndInsertWebviewData,
        sortedPublishedNotice,
    };
}
