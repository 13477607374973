import { useCallback, useState } from 'react';

import { AccountRepository } from 'repository';
import { AccountInfoRequest, AccountUpdateRequest, AccountDetailDto } from '@gamebase-web-ops/account';

export default function useEditAccount() {
    const [account, setAccount] = useState<AccountDetailDto>();

    const requestAccountDetailById = useCallback(async (id: string) => {
        const { result } = await AccountRepository.info(new AccountInfoRequest({ id, projectId: 'COMMON' }));
        setAccount(result);
    }, []);

    const accountPasswordInitializeById = useCallback(
        async (id: string, password: string) => {
            await AccountRepository.update(new AccountUpdateRequest({ id, password, projectId: '' }));
            requestAccountDetailById(id);
        },
        [requestAccountDetailById],
    );
    const patchAccount = useCallback(
        async (id: string, data: IAccountEdit) => {
            await AccountRepository.update(
                new AccountUpdateRequest({
                    id,
                    activeState: data.isActive,
                    name: data.username,
                    projectId: '',
                }),
            );
            requestAccountDetailById(id);
        },
        [requestAccountDetailById],
    );

    return { account, requestAccountDetailById, accountPasswordInitializeById, patchAccount };
}
