import { ECouponState } from '@gamebase-web-ops/coupon';
import { notificationPush } from 'utils/notificationUtils';

export const notificationAfterCompareToBefore = (before: ECouponState, after: ECouponState) => {
    if (
        (before !== ECouponState.ACTIVATE_FAIL && after === ECouponState.ACTIVATE_FAIL) ||
        (before !== ECouponState.DEACTIVATE_FAIL && after === ECouponState.DEACTIVATE_FAIL)
    ) {
        notificationPush({
            status: 'error',
            title: '요청에 실패하였습니다.',
        });
    }

    if (before !== ECouponState.ACTIVATE_SUCCESS && after === ECouponState.ACTIVATE_SUCCESS) {
        notificationPush({
            status: 'success',
            title: '쿠폰 활성화에 성공하였습니다.',
        });
    }
    if (before !== ECouponState.DEACTIVATE_SUCCESS && after === ECouponState.DEACTIVATE_SUCCESS) {
        notificationPush({
            status: 'success',
            title: '쿠폰 비활성화에 성공하였습니다.',
        });
    }
};
